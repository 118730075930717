import { useEffect, useRef } from 'react'
import { CountUp } from 'use-count-up'
import { makeStyles } from '@mui/styles'

import { TICK_ANIMATION_LENGTH_MS } from '../../../domain/earnings/constants'

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 600,
    overflow: 'hidden',
    fontSize: '3em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '5em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '6em'
    },
  }
}))

type EarningsProps = {
  daily: number
  realtime: number
  slow?: boolean
  immediate?: boolean
}

// 1/2 cent per second
const TWO_DECIMAL_PLACE_DAILY_EARNINGS_THRESHOLD = 0.005 * 60 * 60 * 24

export const Earnings = ({ daily, realtime }: EarningsProps) => {
  const previous = useRef(0)
  const classes = useStyles()

  useEffect(() => {
    previous.current = realtime
  }, [realtime])

  let decimalPlaces = 2

  // only ever show high earners 2dp
  if (daily < TWO_DECIMAL_PLACE_DAILY_EARNINGS_THRESHOLD) {
    if (realtime < 10) {
      decimalPlaces = 7
    } else if (realtime < 100) {
      decimalPlaces = 5
    } else {
      decimalPlaces = 3
    }
  }

  const AmountComponent = ({ value }: { value: number }) => {
    const formatted = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    })

    return (
      <span className={classes.text}>
        {formatted}
      </span>
    )
  }

  return (
    <CountUp
      isCounting
      key={realtime}
      start={previous.current}
      end={realtime}
      duration={TICK_ANIMATION_LENGTH_MS / 1000}
      easing={'easeOutCubic'}
      formatter={(value: any) => <AmountComponent value={value} />} />
  )
}
