import { State } from '@unit-finance/unit-node-sdk'

import { UserTerm } from '../../../domain/types'

export enum ApplicantType {
  Individual,
  Business
}

export type FormProps = {
  applicantType: ApplicantType
  setApplicantType: (applicantType: ApplicantType) => void
  terms: UserTerm[]
}

export type IdentityFormShared = {
  confirmedCashflowAgreement: boolean
  confirmedElectronicDisclosure: boolean
  confirmedTerms: boolean
}

// convenience array of Unit `BusinessVertical`
export const INDUSTRIES = [
  'AdultEntertainmentDatingOrEscortServices',
  'AgricultureForestryFishingOrHunting',
  'ArtsEntertainmentAndRecreation',
  'BusinessSupportOrBuildingServices',
  'Cannabis',
  'Construction',
  'DirectMarketingOrTelemarketing',
  'EducationalServices',
  'FinancialServicesCryptocurrency',
  'FinancialServicesDebitCollectionOrConsolidation',
  'FinancialServicesMoneyServicesBusinessOrCurrencyExchange',
  'FinancialServicesOther',
  'FinancialServicesPaydayLending',
  'GamingOrGambling',
  'HealthCareAndSocialAssistance',
  'HospitalityAccommodationOrFoodServices',
  'LegalAccountingConsultingOrComputerProgramming',
  'Manufacturing',
  'Mining',
  'Nutraceuticals',
  'PersonalCareServices',
  'PublicAdministration',
  'RealEstate',
  'ReligiousCivicAndSocialOrganizations',
  'RepairAndMaintenance',
  'RetailTrade',
  'TechnologyMediaOrTelecom',
  'TransportationOrWarehousing',
  'Utilities'
]

// convenience array of Unit `Occupation`
export const OCCUPATIONS = [
  'ArchitectOrEngineer',
  'BusinessAnalystAccountantOrFinancialAdvisor',
  'CommunityAndSocialServicesWorker',
  'ConstructionMechanicOrMaintenanceWorker',
  'Doctor',
  'Educator',
  'EntertainmentSportsArtsOrMedia',
  'ExecutiveOrManager',
  'FarmerFishermanForester',
  'FoodServiceWorker',
  'GigWorker',
  'HospitalityOfficeOrAdministrativeSupportWorker',
  'HouseholdManager',
  'JanitorHousekeeperLandscaper',
  'Lawyer',
  'ManufacturingOrProductionWorker',
  'MilitaryOrPublicSafety',
  'NurseHealthcareTechnicianOrHealthcareSupport',
  'PersonalCareOrServiceWorker',
  'PilotDriverOperator',
  'SalesRepresentativeBrokerAgent',
  'ScientistOrTechnologist',
  'Student'
]

// convenience array of Unit `EntityType`
export const ENTITY_TYPES = [
  'Corporation',
  'LLC',
  'Partnership',
  'PubliclyTradedCorporation',
  'PrivatelyHeldCorporation',
  'NotForProfitOrganization'
]

export const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI',
  'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI',
  'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC',
  'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'AS', 'DC', 'FM', 'GU', 'MH',
  'MP', 'PW', 'PR', 'VI'
] as const

export const STATE_NAME: Record<State, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

