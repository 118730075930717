import {
  useRef,
  SyntheticEvent,
  useEffect,
  useState,
  Ref,
  HTMLAttributes,
  ReactNode
} from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Autocomplete, { AutocompleteProps, AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import { useTheme } from '@mui/styles'
import Box from '@mui/material/Box'
import { Breakpoint } from '@mui/system/createTheme'

import { useSize } from '../../../global/dimensions'
import { colors } from '../../../global/colors'

const styles = {
  popupIndicator: {
    display: 'none'
  },
  clearIndicator: {
    display: 'flex',
    marginRight: '.5em'
  },
  popper: {
    '& .MuiAutocomplete-noOptions': {
      display: 'none'
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .MuiAutocomplete-listbox': {
      padding: 0
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-disabled="true"]': {
      opacity: 1
    }
  },
  autocomplete: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    },
    '& .MuiOutlinedInput-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-error fieldset': {
        borderColor: 'error.main'
      }
    }
  },
  input: {
    backgroundColor: 'white',
    '& input': {
      textAlign: 'center'
    }
  },
  li: {
    display: 'block',
    padding: 0,
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: colors.grey300
  }
}

interface InlineAutocompleteProps<
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined> extends Partial<AutocompleteProps<T, false, DisableClearable, FreeSolo>> {
  placeholder: string
  error?: boolean
  resultCount: Partial<{ [key in Breakpoint]: number }>
  optionKey: (option: T) => string
  innerRef?: Ref<any>
  typography: 'h4' | 'h6'
  autoFocus: boolean
  endAdornment?: ReactNode
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => ReactNode;
}

export const InlineAutocomplete = <
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined>
  (props: InlineAutocompleteProps<T, DisableClearable, FreeSolo>) => {
  const {
    sx,
    placeholder,
    error,
    optionKey,
    innerRef,
    resultCount,
    typography,
    autoFocus,
    endAdornment = null,
    ListboxProps = {},
    ...autoCompleteProps
  } = props

  const input = useRef<any>(null)
  const stack = useRef(null)
  const theme = useTheme()
  const { height } = useSize(stack)
  const [containerStyle, setContainerStyle] = useState({})
  const [listboxStyle, setListboxStyle] = useState({})

  useEffect(() => {
    const getResponsiveStyle = (property: string) =>
      Object.keys(resultCount).reduce((style, bp) => {
        const count = resultCount[bp as Breakpoint]

        if (!count) return style

        const space = count * height + 'px'

        if (bp === 'xs') {
          return {
            ...style,
            [property]: space
          }
        }

        return {
          ...style,
          [theme.breakpoints.up(bp as Breakpoint)]: {
            [property]: space
          }
        }
      }, {})

    setContainerStyle(getResponsiveStyle('marginBottom'))
    setListboxStyle(getResponsiveStyle('maxHeight'))
  }, [height])

  const handleInputChange = (event: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    if (props.onInputChange) {
      props.onInputChange(event, value, reason)
    }

    // only run on real user events (not on initialization)
    if (event) {
      input.current.scrollIntoView(true)
    }
  }

  const handleFocus = () => {
    setTimeout(() => input.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      ref={input}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onFocus={handleFocus}
      InputProps={{
        ...params.InputProps,
        type: 'search',
        endAdornment: endAdornment ?? params.InputProps.endAdornment,
        sx: {
          ...styles.input,
          typography
        }
      }}
      error={error} />
  )

  const renderOption = (optionProps: any, value: T, state: AutocompleteRenderOptionState) => (
    <li {...optionProps} key={optionKey(value)} style={{ ...styles.li, height }}>
      <div style={styles.divider} />
      {props.renderOption(optionProps, value, state)}
    </li>
  )

  return (
    <Box sx={containerStyle}>
      <Stack spacing={2} ref={stack}>
        <Autocomplete<T, false, DisableClearable, FreeSolo>
          {...autoCompleteProps as AutocompleteProps<T, false, DisableClearable, FreeSolo>}
          ref={innerRef}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ListboxProps={{ sx: listboxStyle, ...ListboxProps }}
          selectOnFocus
          blurOnSelect
          clearOnBlur={false}
          onInputChange={handleInputChange}
          componentsProps={{
            popupIndicator: {
              sx: styles.popupIndicator
            },
            clearIndicator: {
              sx: styles.clearIndicator
            },
            popper: {
              keepMounted: true,
              placement: 'bottom',
              sx: styles.popper,
              popperOptions: {
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false
                  }
                ]
              }
            }
          }}
          sx={{
            ...styles.autocomplete,
            ...sx
          }}
          noOptionsText={null}
          renderInput={renderInput}
          renderOption={renderOption} />
      </Stack>
    </Box>
  )
}
