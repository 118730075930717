import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import {
  useEffect, useRef, useState, useCallback
} from 'react'
import {
  SpotifyArtist,
  calculateEarningsToday,
  calculateEarningsDaily
} from '@rcrdclub/common-front'
import { makeStyles } from '@mui/styles'

import { Earnings } from './Earnings'

import { EARNINGS_RECALCULATION_INTERVAL_MS } from '../../../domain/earnings/constants'
import { FadeInOptional } from '../fade'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '10em',
    transition: 'font-size .2s',
  },
  avatar: {
    fontSize: 'unset',
    border: '0.125em solid black',
    height: '3em',
    width: '3em',
    [theme.breakpoints.up('sm')]: {
      width: '4em',
      height: '4em'
    },
    [theme.breakpoints.up('lg')]: {
      width: '5em',
      height: '5em'
    },
  },
  name: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    // override standard h4 rem dimensions with scalable em units
    fontSize: '1.7em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.9em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.2em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.4em'
    }
  }
}))

type ArtistProps = {
  key?: string
  artist: SpotifyArtist | undefined
  total?: number
  realtime?: number
  loading?: boolean
  indeterminate?: boolean
  model?: string
  fade?: number
  size: 'small' | 'large'
}

export const ArtistStatic = ({
  key = 'artist',
  size,
  artist,
  total = 0,
  realtime = 0,
  fade = 100,
  indeterminate = false,
  model = 'none',
  loading = false
}: ArtistProps) => {
  const classes = useStyles()

  const indeterminateInterval = useRef<NodeJS.Timeout | undefined>(undefined)

  const randomRealtimeEarnings = useCallback(() => {
    const min = total / 2
    const max = total * 1.5
    return Math.random() * (max - min) + min
  }, [total])

  const realtimeEarningsInitial = indeterminate
    ? randomRealtimeEarnings()
    : artist?.id ? calculateEarningsToday(artist) : 0

  const [dailyEarnings, setDailyEarnings] = useState(total)
  const [realtimeEarnings, setRealtimeEarnings] = useState(realtimeEarningsInitial)

  // used to scale entire component
  const scaledFontSize = size === 'small' ? '0.7rem' : '1rem'
  const scaledFontSizeDetails = size === 'small' ? '1em' : '0.8em'
  const scaledMarginBottom = size === 'small' ? '0.5em' : '0em'

  useEffect(() => {
    if (indeterminateInterval.current) {
      clearInterval(indeterminateInterval.current)
    }

    if (indeterminate) {
      indeterminateInterval.current = setInterval(() => {
        const next = randomRealtimeEarnings()
        setRealtimeEarnings(next)
      }, 4000)
    }

    return () => {
      clearInterval(indeterminateInterval.current)
    }
  }, [indeterminate, randomRealtimeEarnings])

  useEffect(() => {
    setDailyEarnings(total)
  }, [total, indeterminate])

  useEffect(() => {
    if (!artist || indeterminate) return
    if (realtime) {
      setRealtimeEarnings(realtime)
      return
    }

    setDailyEarnings(calculateEarningsDaily(artist, new Date()))
    const updateEarnings = () => setRealtimeEarnings(calculateEarningsToday(artist))

    updateEarnings()

    // start the refresh loop
    const intervalId = setInterval(updateEarnings, EARNINGS_RECALCULATION_INTERVAL_MS)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [artist, realtime, indeterminate])

  return (
    <Grid
      key={key}
      container
      fontSize={scaledFontSize}
      flexDirection="column"
      alignItems="center"
      className={classes.container}
      style={{ marginBottom: scaledMarginBottom }}>

      {loading || !artist ? undefined : <Grid item>
        <FadeInOptional delay={fade}>
          <Grid container alignItems="center" spacing={{ xs: 1, sm: 2 }} flexWrap="nowrap">
            <Grid item>
              <Avatar
                className={classes.avatar}
                alt={artist.name}
                src={artist?.images?.length ? artist.images[artist.images.length - 1].url : ''} />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                className={classes.name}
                fontWeight={600}
                textAlign="center">
                {artist.name}
              </Typography>
            </Grid>
          </Grid>
        </FadeInOptional>
      </Grid>
      }

      {loading || !artist ? undefined : <Grid item>
        <FadeInOptional delay={fade ? fade + 100 : 0}>
          <Earnings daily={dailyEarnings} realtime={realtimeEarnings} />
        </FadeInOptional>
      </Grid>
      }

      {loading || !artist ? undefined : <Grid item style={{ paddingBottom: 8 }}>
        <FadeInOptional delay={fade ? fade + 100 : 0}>
          <Typography variant="overline" fontSize={scaledFontSizeDetails}>
            {model === 'streams'
              ? 'Your HIFI Cash Flow daily estimate'
              : 'Today\'s Estimated Cash Flow Payment'}
          </Typography>
        </FadeInOptional>
      </Grid>
      }

    </Grid>
  )
}
