import { gql } from '@apollo/client'

export const MUTATION_VERIFY_IDENTITY_INDIVIDUAL = gql`
  mutation VerifyIdentityIndividual($identity: IdentityIndividual!) {
    verifyIdentityIndividual(identity: $identity) {
      status,
      message
    }
  }
`
export const MUTATION_VERIFY_IDENTITY_BUSINESS = gql`
  mutation VerifyIdentityBusiness($identity: IdentityBusiness!) {
    verifyIdentityBusiness(identity: $identity) {
      status,
      message
    }
  }
`
