
import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import {
  RESEND_CODE_MUTATION,
  saveUserKeys,
  SpotifyArtist, useAuth, useCustomClaims, useValidatePin, useVerify
} from '@rcrdclub/common-front'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { ResendCodeMutation, ResendCodeMutationVariables } from '@rcrdclub/common-front/lib/types'

import { VerifyFormData } from './types'

import { useArtist } from '../../domain/artist/hooks'
import { colors } from '../../global/colors'
import { QUERY_SPOTIFY_ARTIST } from '../../domain/streaming/queries'
import { SpotifyArtistQuery, SpotifyArtistQueryVariables } from '../../domain/types'
import { PinInput } from '../components/inputs/PinInput'
import { ArtistStatic } from '../components/artist/ArtistStatic'
import { ApplicationLayout } from '../components/layouts/ApplicationLayout'

const styles = {
  form: {
    marginBottom: '1rem',
    color: colors.pink500
  },
  info: {
    margin: 'auto'
  },
  thanks: {
    marginBottom: '.5em'
  }
}

export const VerifyPage = () => {
  const [auth, setAuth] = useAuth()
  const [artist, setArtist] = useArtist()
  const [{ verificationId, phoneNumber }, setVerify] = useVerify()
  const [{ mutation: verifyPin, loading: loadingValidate }] = useValidatePin()
  const [{ query: queryCustomClaims, loading: loadingCustomClaims }] = useCustomClaims()

  const [error, setError] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)
  const [searchParams] = useSearchParams()
  const [showResend, setShowResend] = useState(false)

  // TODO: loading indicator
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loading = loadingValidate || loadingCustomClaims

  const {
    handleSubmit,
    trigger,
    getValues,
    setValue,
  } = useForm<VerifyFormData>()

  const [searchArtists] = useLazyQuery<
    SpotifyArtistQuery,
    SpotifyArtistQueryVariables
  >(QUERY_SPOTIFY_ARTIST)

  const [resendCodeMutation] = useMutation<
    ResendCodeMutation,
    ResendCodeMutationVariables
  >(RESEND_CODE_MUTATION)

  useEffect(() => {
    setTimeout(() => {
      setShowResend(true)
    }, 30000) // 30 seconds
  }, [])

  useEffect(() => {
    if (error) {
      setShowResend(true)
    }
  }, [error])

  useEffect(() => {
    const onLoadArtist = async () => {
      const artistId = searchParams.get('artistId')

      if (artistId && !artist?.id) {
        const { data } = await searchArtists({ variables: { id: artistId } })

        if (data) {
          setArtist({ ...data.spotifyArtist } as SpotifyArtist)
        }
      }
    }

    onLoadArtist()
  }, [searchParams])

  const onResendCode = async () => {
    try {
      if (phoneNumber) {
        setVerify({
          phoneNumber: '',
          verificationId: '',
        })
        throw Error('No phone number was found')
      }

      const { errors } = await resendCodeMutation({ variables: { phoneNumber } })

      if (errors && errors.length) {
        console.error({ errors })
        throw Error('Failed to resend code. Please try again soon')
      }

      setError(undefined)
      setSubmitted(false)
    } catch (error: any) {
      setError(error.toString())
    }
  }

  const onVerify = async ({ pin }: VerifyFormData) => {
    try {
      setError(undefined)
      setSubmitted(true)
      setShowResend(false)

      const authResult = await verifyPin(verificationId, pin)

      const { jwt, userId } = authResult

      if (!jwt || !userId) {
        throw Error('Failed to create auth session, please try again')
      }

      await saveUserKeys({ jwt, userId })

      const customClaims = await queryCustomClaims()

      if (!customClaims?.isApplicant) {
        throw Error('You don\'t have access to the onboarding process. We\'ll be in touch soon!')
      }

      setAuth({
        ...auth, jwt, userId, claims: { ...customClaims }
      })
    } catch (error: any) {
      console.error('[onVerify]', error)
      setError(error.toString())
      setSubmitted(false)
    }
  }

  const onChangePin = (value: string) => {
    setValue('pin', value)
    trigger('pin')
    setError(undefined)
  }

  return (
    <Container disableGutters maxWidth={false}>
      <ApplicationLayout
        step={'application'}
        headerSize='large'
        ctaText={'Verify'}
        ctaOnClick={handleSubmit(onVerify)}
        ctaDisabled={submitted || loading}
        ctaHidden={false}>
        <ArtistStatic fade={0} artist={artist} size="large" />
        <div id="verify-form" style={styles.form}>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <PinInput
              style={{ color: colors.white }}
              error={!!error}
              onChange={onChangePin}
              value={getValues('pin')} />
          </div>
          <Grid container alignItems="center" paddingTop={3}>
            <Typography variant="body1" align="center" color="primary" sx={styles.info}>
              {'Enter the verification code'}
            </Typography>
          </Grid>
          {!error && !showResend ? undefined : <div onClick={() => onResendCode()}>
            <Typography
              variant="body2" align="center" style={{
                paddingTop: 24, color: colors.greyLink, textTransform: 'uppercase', cursor: 'pointer'
              }}>
              Resend
            </Typography>
          </div>}
        </div>
        {error &&
          <Typography variant="body2" align="center" color="secondary" sx={styles.info}>
            {error.toString()}
          </Typography>
        }
      </ApplicationLayout>
    </Container >
  )
}
