import { Controller, Control } from 'react-hook-form'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { makeStyles } from '@mui/styles'

type PhoneNumberFieldProps = {
  control: Control<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiInputAdornment-root .MuiTypography-root': {
      ...theme.typography.h6,
    },
    '& .MuiOutlinedInput-root': {
      ...theme.typography.h6,
      '&.Mui-error fieldset': {
        borderColor: 'error.main'
      }
    }
  }
}))

export const PhoneNumberField = ({
  control, name, error, size
}: PhoneNumberFieldProps) => {
  const classes = useStyles()

  return (
    <Controller
      name={name}
      rules={{ required: true, validate: matchIsValidTel }}
      control={(control as unknown) as Control}
      render={({ field }) =>
        <MuiTelInput
          {...field}
          autoComplete="tel"
          label="Phone Number"
          fullWidth
          size={size}
          className={classes.input}
          defaultCountry="US"
          onlyCountries={['US']}
          forceCallingCode
          disableDropdown
          error={error} />} />
  )
}
