import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { useEffect } from 'react'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { useMuiInputSize } from '../../../global/dimensions'
import { AddressFields } from '../shared/AddressFields'

export const AddressFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const { formState: { errors } } = form
  const inputSize = useMuiInputSize()

  useEffect(() => {
    setPageFields(['address'])
  }, [])

  return (
    <div style={{ flexGrow: 1 }}>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.Address)}
        stepCount={steps.length}
        expanded
        collapsible={false}
        error={!!errors.address}
        question="Business Address"
        summary="Business Address"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => undefined}>
        <Grid container spacing={2}>
          <AddressFields basePath="address" form={form} errors={errors.address} inputSize={inputSize} />
        </Grid>
      </AccordionBubble>
    </div>
  )
}
