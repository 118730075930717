import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { REACT_APP_ENV } = process.env

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://7da9d2ee1066a3bac3f68525fdded8c8@o160250.ingest.sentry.io/4506185066020864',
    integrations: [new BrowserTracing()],
    environment: REACT_APP_ENV === 'prod' ? 'production' : 'development',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

export { Sentry }
