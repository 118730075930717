import { Controller, Control } from 'react-hook-form'
import { DateField } from '@mui/x-date-pickers/DateField/DateField'

type DateOfBirthFieldProps = {
  control: Control<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

export const DateOfBirthField = ({
  control, name, error, size
}: DateOfBirthFieldProps) => {
  return (
    <Controller
      name={name}
      rules={{ required: true, validate: (value) => !isNaN(value) }}
      control={control}
      render={({ field }) =>
        <DateField
          {...field}
          InputProps={{ error }}
          fullWidth
          label="Date of Birth"
          size={size} />
      } />
  )
}
