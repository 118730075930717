import {
  useState,
  useLayoutEffect,
  useRef,
  MutableRefObject
} from 'react'
import { gsap } from 'gsap'
import makeStyles from '@mui/styles/makeStyles'

import { Assets } from '../../../../assets'
import { LargeTicker } from '../LargeTicker'

const useStyles = makeStyles(() => ({
  section: {
    position: 'relative',
    marginTop: '22vw',
  },
  goldCardRow: {
    position: 'relative',
    left: 0,
  },
  goldCard: {
    width: '130vw',
    aspectRatio: 'auto 500 / 314',
    zIndex: 1
  },
  tickerTopRow: {
    position: 'relative'
  },
  tickerBottomRow: {
    position: 'absolute',
    bottom: 0
  },
  tickerTop: {
    position: 'relative',
    left: '-5vw',
  },
  cardAngleRow: {
    position: 'relative',
    marginTop: '-10vh',
  },
  cardAngle1: {
    width: '180vw',
    display: 'block',
    aspectRatio: 'auto 1600 / 971',
    // browser hint to improve perf. and avoid flickering
    willChange: 'transform'
  },
  cardAngle2: {
    width: '180vw',
    display: 'block',
    marginTop: '-45vw',
    aspectRatio: 'auto 1600 / 1007',
  }
}))

type GoldSectionProps = {
  section: MutableRefObject<any>
  tickerTop: MutableRefObject<any>
}

export const GoldSection = ({ section, tickerTop }: GoldSectionProps) => {
  const classes = useStyles()

  const [ticking, setTicking] = useState(false)

  const goldCard = useRef<any>()
  const cardAngle1 = useRef<any>()
  const cardAngle2 = useRef<any>()

  useLayoutEffect(() => {
    gsap.fromTo(goldCard.current, { x: '100vw', xPercent: -20 }, {
      scrollTrigger: {
        trigger: goldCard.current,
        scrub: true,
        start: 'top bottom',
        end: '+=1500'
      },
      xPercent: -100,
      x: 0,
      ease: 'power1.inOut'
    })

    gsap.fromTo(tickerTop.current, { opacity: 0 }, {
      scrollTrigger: {
        trigger: tickerTop.current,
        onEnter: () => setTicking(true)
      },
      opacity: 1
    })

    gsap.set(cardAngle1.current, { xPercent: -100 })
    gsap.set(cardAngle2.current, { x: '100vw' })

    // angled cards
    gsap.timeline({
      scrollTrigger: {
        trigger: cardAngle1.current
      }
    })
      .to(cardAngle1.current, { x: '50vw', xPercent: -75 })
      .to(cardAngle2.current, { x: '50vw', xPercent: -25 })
  }, [])

  return (
    <section ref={section} className={classes.section}>
      <div className={classes.goldCardRow}>
        <div ref={goldCard}>
          <img
            src={Assets.cardShadow}
            alt="card shadow"
            className={classes.goldCard}
            style={{
              position: 'absolute', top: '5vw', left: '-5vw', zIndex: -1
            }} />
          <img src={Assets.cardGold} alt="card" className={classes.goldCard} />
        </div>

      </div>

      <div className={classes.tickerTopRow}>
        <LargeTicker
          className={classes.tickerTop}
          tickerRef={tickerTop}
          ticking={ticking}
          start={105}
          end={845} />
      </div>

      <div className={classes.cardAngleRow}>
        <div className={classes.tickerBottomRow}>
        </div>

        <img
          ref={cardAngle1}
          src={Assets.cardAngle1}
          alt="card"
          className={classes.cardAngle1} />

        <img
          ref={cardAngle2}
          src={Assets.cardAngle2}
          alt="card"
          className={classes.cardAngle2} />
      </div>
    </section>
  )
}
