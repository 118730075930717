import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRef, useLayoutEffect, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { Intro } from './sections/Intro'
import { PaysRoyalties } from './sections/PaysRoyalties'
import { CentralTicker } from './sections/CentralTicker'
import { GoldSection } from './sections/GoldSection'
import { CentralCards } from './sections/CentralCards'

import { Assets } from '../../../assets'
import { colors } from '../../../global/colors'
import { normalizeScroll } from '../../../global/scroll'

gsap.registerPlugin(ScrollTrigger)

const useStyles = makeStyles(() => ({
  backgroundGold: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100lvh',
    zIndex: 2,
    objectFit: 'cover',
    // browser hint to improve perf. and avoid flickering
    willChange: 'transform'
  },
  backgroundBlack: {
    background: 'black',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100lvh',
    zIndex: 1
  },
  sectionContainer: {
    position: 'relative',
    zIndex: 3
  }
}))

// Safari-only hack to reset scroll position on reload
window.onbeforeunload = () => {
  window.scrollTo(0, 0)
  ScrollTrigger.clearScrollMemory()
}

ScrollTrigger.clearScrollMemory()
ScrollTrigger.refresh()

window.addEventListener('load', () => {
  ScrollTrigger.clearScrollMemory()
  window.history.scrollRestoration = 'manual'
  window.scrollTo(0, 0)
})

export const ProductOverview = () => {
  const classes = useStyles()

  const container = useRef<any>()
  const backgroundTrigger = useRef<any>()
  const backgroundGold = useRef<any>()
  const backgroundBlack = useRef<any>()
  const tickerTop = useRef<any>()
  const tickerBottom = useRef<any>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useLayoutEffect(() => {
    ScrollTrigger.clearScrollMemory()
    window.history.scrollRestoration = 'manual'

    gsap.set(backgroundGold.current, { opacity: 0 })
    gsap.set(backgroundBlack.current, { opacity: 0 })

    gsap.timeline({
      scrollTrigger: {
        trigger: backgroundTrigger.current,
        start: 'top center',
        end: 'bottom top'
      }
    })
      .to(backgroundGold.current, { opacity: 1, duration: 1 })
      .to({}, { duration: 2 })
      .to(backgroundGold.current, { opacity: 0, duration: 1 })
      .to(backgroundBlack.current, { opacity: 1, duration: 1 }, '<')
      .fromTo(tickerTop.current, { color: colors.black }, { color: colors.white, duration: 1 }, '<')
      .fromTo(tickerBottom.current, { color: colors.black }, { color: colors.white, duration: 1 }, '<')
      .to({}, { duration: 2 })
      .to(backgroundBlack.current, { opacity: 0, duration: .5 })
  }, [])

  gsap.defaults({
    ease: 'power4.out'
  })

  ScrollTrigger.defaults({
    scrub: 1,
    start: 'top bottom',
    end: 'bottom center'
  })

  normalizeScroll(true)

  return (
    <div id="product-overview" ref={container}>
      <img ref={backgroundGold} className={classes.backgroundGold} src={Assets.goldTexture} alt="gold texture" />
      <div ref={backgroundBlack} className={classes.backgroundBlack} />

      <div className={classes.sectionContainer}>
        <Intro />

        <PaysRoyalties />

        <CentralTicker />

        <GoldSection section={backgroundTrigger} tickerTop={tickerTop} />

        <CentralCards />
      </div>
    </div>
  )
}
