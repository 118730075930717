
import { makeStyles } from '@mui/styles'
import { Button, Typography } from '@mui/material'

import { Assets } from '../../../assets'

const useStyles = makeStyles(() => ({
  cta: {
    borderRadius: 6,
    paddingTop: '2.5svh',
    paddingBottom: '2.5svh',
  },
}))

export const ButtonRounded = ({
  text = 'Button',
  arrow = true,
  disabled = false,
  style = {} as any,
  onClick = () => undefined as any
}) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      onClick={() => onClick ? onClick() : undefined}
      disableElevation
      fullWidth
      className={classes.cta}
      color="secondary"
      style={{
        ...style,
        justifyContent: arrow ? 'space-between' : 'center',
        paddingLeft: arrow ? 16 : 0,
        paddingRight: arrow ? 16 : 0,
      }}
      disabled={disabled}>
      <Typography fontWeight={400} color="white" style={{ letterSpacing: 0.3, fontSize: 16 }}>
        {text}
      </Typography>
      {arrow && <img
        src={Assets.buttonArrow}
        style={{
          flex: 0,
          width: 59,
          height: 11,
        }} />}
    </Button>
  )
}
