import { IdentityStatus } from '../types'

export const formatStatus = (status: IdentityStatus | undefined, expectedStatus: IdentityStatus) => {
  if (status === 'Approved') {
    return 'Success!'
  }

  if ([status, expectedStatus].includes(IdentityStatus.PendingReview)) {
    return 'Documents In Review'
  }

  return 'Processing'
}
