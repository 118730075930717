import { SyntheticEvent, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import QuestionMark from '@mui/icons-material/QuestionMark'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { isAndroid } from 'react-device-detect'

import { Assets } from '../../../assets'
import { normalizeScroll } from '../../../global/scroll'
import { trackEvent } from '../../../global/analytics'
import { HelpModalApplication } from '../modals/HelpModalApplication'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '1em',
    height: '100dvh',
    // adds margin equal to size of the on-screen keyboard when open
    marginBottom: 'env(keyboard-inset-height)'
  },
  questionMarkSmall: {
    marginRight: '.6em',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      marginRight: '2em',
    }
  },
  questionMarkLarge: {
    marginRight: '.6em',
    marginBottom: '1em',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      marginRight: '2em',
      marginBottom: '2em'
    }
  },
  questionMarkIcon: {
    fontSize: '.8em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2em',
    }
  },
  titleRowSmall: {
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '2em'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '3em'
    }
  },
  titleRowLarge: {
    marginBottom: '2em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '4em'
    }
  },
  logoSmall: {
    pointerEvents: 'none',
    height: '1.4em',
    [theme.breakpoints.up('sm')]: {
      height: '2.2em'
    },
    [theme.breakpoints.up('md')]: {
      height: '2.5em'
    },
    [theme.breakpoints.up('lg')]: {
      height: '2.8em'
    },
  },
  logoLarge: {
    pointerEvents: 'none',
    height: '2em',
    [theme.breakpoints.up('sm')]: {
      height: '3em'
    },
    [theme.breakpoints.up('md')]: {
      height: '3.7em'
    },
    [theme.breakpoints.up('lg')]: {
      height: '4.3em'
    },
  },
  cta: {
    borderRadius: 0,
    padding: '2em 0'
  },
  contentRow: {
    flexGrow: 1
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingRight: 4,
  }
}))

type FullPageProps = {
  step: 'application' | 'kyc'
  headerSize: 'small' | 'large'
  spotifyArtistId?: string,
  ctaText: string
  ctaOnClick: () => void
  ctaDisabled: boolean
  ctaHidden?: boolean
  onClick?: (e: SyntheticEvent) => void
  children: any
}

// turn VirtualKeyboard API on in Android to expose related CSS envs
if (isAndroid && 'virtualKeyboard' in navigator) {
  // lib.dom.d.ts is missing the virtualKeyboard property
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.virtualKeyboard.overlaysContent = true
}

export const ApplicationLayout = ({
  step,
  children,
  spotifyArtistId,
  headerSize,
  ctaText,
  ctaOnClick,
  ctaDisabled,
  ctaHidden = false,
  onClick = () => undefined
}: FullPageProps) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    trackEvent('help_open', {
      'event_category': 'cashflow'
    })
    setModalOpen(true)
    normalizeScroll(false)
  }

  const closeModal = () => {
    setModalOpen(false)
    normalizeScroll(true)
  }

  const isSmall = headerSize === 'small'

  return (
    <>
      <Grid
        container
        onClick={onClick}
        className={classes.container}
        flexDirection="column"
        flexWrap="nowrap"
        alignItems="center">

        <Grid item width="100%" style={{ display: 'flex', position: 'absolute', paddingTop: isSmall ? 8 : 14 }}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton
                onClick={openModal}
                color="primary"
                aria-label="help"
                size="small"
                className={isSmall ? classes.questionMarkSmall : classes.questionMarkLarge}>
                <QuestionMark className={classes.questionMarkIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={isSmall ? classes.titleRowSmall : classes.titleRowLarge}>
          <img src={Assets.hifiLogo} alt="logo" className={isSmall ? classes.logoSmall : classes.logoLarge} />
          <Typography variant={isSmall ? 'h2' : 'h1'} component="span" fontWeight={900} style={{ letterSpacing: isSmall ? -0.5 : -1.5 }} color="secondary">
            &nbsp;Cash Flow
          </Typography>
        </Grid>

        <Grid id="inner-content" item container columnSpacing={2} justifyContent="center" className={classes.contentRow}>
          <Grid
            item
            xs={11.25}
            sm={9}
            md={8}
            lg={6}
            xl={4}
            className={classes.contentItem}>
            {children}
          </Grid>
        </Grid>

        {!ctaHidden &&
          <Grid item width="100%">
            <Button
              variant="contained"
              onClick={ctaOnClick}
              disableElevation
              fullWidth
              className={classes.cta}
              color="secondary"
              disabled={ctaDisabled}>
              <Typography variant="h6" fontWeight={600} color="white">
                {ctaText}
              </Typography>
            </Button>
          </Grid>
        }
      </Grid>

      <HelpModalApplication
        step={step}
        open={modalOpen}
        spotifyArtistId={spotifyArtistId}
        onClose={closeModal} />
    </>
  )
}
