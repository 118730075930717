import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import {
  SpotifyArtist,
  calculateEarningsToday,
  calculateEarningsDaily
} from '@rcrdclub/common-front'
import { makeStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'

import { Earnings } from './Earnings'

import { EARNINGS_RECALCULATION_INTERVAL_MS } from '../../../domain/earnings/constants'

type ArtistProps = {
  artist: SpotifyArtist | undefined
  loading?: boolean
  size: 'small' | 'large'
}

const useStyles = makeStyles((theme) => ({
  container: {
    transition: 'font-size .2s',
    marginBottom: '2em'
  },
  avatar: {
    fontSize: 'unset',
    border: '0.125em solid black',
    height: '3em',
    width: '3em',
    [theme.breakpoints.up('sm')]: {
      width: '4em',
      height: '4em'
    },
    [theme.breakpoints.up('lg')]: {
      width: '5em',
      height: '5em'
    },
  },
  name: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    // override standard h4 rem dimensions with scalable em units
    fontSize: '1.7em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.9em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.2em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.4em'
    }
  }
}))

export const Artist = ({ artist, size, loading = false }: ArtistProps) => {
  const [dailyEarnings, setDailyEarnings] = useState(0)
  const [realtimeEarnings, setRealtimeEarnings] = useState(0)
  const classes = useStyles()

  // used to scale entire component
  const scaledFontSize = size === 'small' ? '0.7rem' : '1rem'

  useEffect(() => {
    if (!artist) return

    setDailyEarnings(calculateEarningsDaily(artist, new Date()))
    const updateEarnings = () => setRealtimeEarnings(calculateEarningsToday(artist))

    updateEarnings()

    // start the refresh loop
    const intervalId = setInterval(updateEarnings, EARNINGS_RECALCULATION_INTERVAL_MS)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [artist])

  if (!artist) return null

  return (
    <Grid
      container
      fontSize={scaledFontSize}
      flexDirection="column"
      alignItems="center"
      className={classes.container}>

      {loading && !artist ?
        <CircularProgress size={64} /> :
        <Grid item>
          <FadeIn delay={100}>
            <Grid container alignItems="center" spacing={{ xs: 1, sm: 2 }} flexWrap="nowrap">
              <Grid item>
                <Avatar
                  className={classes.avatar}
                  alt={artist.name}
                  src={artist?.images?.length ? artist.images[artist.images.length - 1].url : ''} />
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  className={classes.name}
                  fontWeight={600}
                  textAlign="center">
                  {artist.name}
                </Typography>
              </Grid>
            </Grid>
          </FadeIn>
        </Grid>
      }

      {loading && !artist ? undefined : <Grid item>
        <FadeIn delay={200}>
          <Earnings daily={dailyEarnings} realtime={realtimeEarnings} />
        </FadeIn>
      </Grid>
      }

      {loading && !artist ? undefined : <Grid item>
        <FadeIn delay={200}>
          <Typography variant="overline" fontSize="0.8em">
            Today&apos;s Estimated Cash Flow Payment
          </Typography>
        </FadeIn>
      </Grid>
      }

    </Grid>
  )
}
