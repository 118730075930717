import { SpotifyArtist, convertToDisplayName, VendorSlug } from '@rcrdclub/common-front'

import { WaitlistStep } from './types'
import { DistributorStep } from './DistributorStep'
import { PhoneNumberStep } from './PhoneNumberStep'
import { ArtistTypeStep } from './ArtistTypeStep'
import { ArtistSearchStep } from './ArtistSearchStep'

import { ArtistType } from '../../../../domain/types'
import { ARTIST_TYPE_DISPLAY_NAMES } from '../../../../domain/waitlist/constants'

export const steps: WaitlistStep[] = [
  {
    name: 'spotifyArtistId',
    question: 'What is your artist name?',
    summary: (_: string, artist: SpotifyArtist) => artist?.name ?? 'Artist',
    Component: ArtistSearchStep
  },
  {
    name: 'artistType',
    question: 'Are you an individual artist or band?',
    summary: (value: ArtistType) => ARTIST_TYPE_DISPLAY_NAMES[value] ?? 'Artist Type',
    Component: ArtistTypeStep
  },
  {
    name: 'distributor',
    question: 'What is your primary music distributor?',
    summary: (value: VendorSlug) => value ? convertToDisplayName(value) : 'Distributor',
    Component: DistributorStep
  },
  {
    name: 'phoneNumber',
    question: 'What is your mobile phone number?',
    summary: (value: string) => value ?? 'Phone Number',
    Component: PhoneNumberStep
  },
]
