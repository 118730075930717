import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {
  UseFormReturn, Merge, FieldErrorsImpl, FieldPath
} from 'react-hook-form'
import { UsAddress } from '@unit-finance/unit-node-sdk'
import type { FieldError } from 'react-hook-form/dist/types/errors'

import { StateField } from './StateField'
import { ZipCodeField } from './ZipCodeField'

type AddressFieldsProps = {
  basePath: FieldPath<any>
  form: UseFormReturn<any>
  errors: Merge<FieldError, FieldErrorsImpl<UsAddress>> | undefined
  inputSize: 'small' | undefined
}

export const AddressFields = ({
  basePath, form, errors, inputSize
}: AddressFieldsProps) => {
  const { register, control } = form

  return (
    <>
      <Grid item xs={12}>
        <TextField
          {...register(`${basePath}.street`, { required: true })}
          size={inputSize}
          autoComplete="address-line1"
          label="Street"
          error={!!errors?.street}
          fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register(`${basePath}.street2`)}
          size={inputSize}
          autoComplete="address-line2"
          label="Apt, suite, etc."
          error={!!errors?.street2}
          fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register(`${basePath}.city`, { required: true })}
          size={inputSize}
          autoComplete="address-level2"
          label="City"
          error={!!errors?.city}
          fullWidth />
      </Grid>
      <Grid item xs={6}>
        <StateField
          control={control}
          name={`${basePath}.state`}
          error={!!errors?.state}
          size={inputSize} />
      </Grid>
      <Grid item xs={6}>
        <ZipCodeField
          register={register}
          name={`${basePath}.postalCode`}
          error={!!errors?.postalCode}
          size={inputSize} />
      </Grid>
    </>
  )
}
