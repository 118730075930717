import { useEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import {
  useVendorAccounts,
  useCreateVendorAccount,
  useUpdateVendorCredential,
  isMfaRequired,
  useSubmitMfaCode,
  isErrored,
  useStartVendorJob,
  Status,
  JobStep,
} from '@rcrdclub/common-front'

import { InputGeneral } from '../components/inputs/InputGeneral'
import { CashflowApplication } from '../../domain/types'
import { selectVendorSpecificAccount, selectVendorSpecificJobs } from '../../global/selectors'
import { formatProgress } from '../../domain/vendors/formatters'
import { ButtonRounded } from '../components/buttons/ButtonRounded'

const styles = {
  info: {
    margin: 'auto'
  },
}

export const DistributorStep = (
  { application }: { application: CashflowApplication }
) => {
  const [startVendorJob] = useStartVendorJob()
  const [{ mutation: submitMfaCode }] = useSubmitMfaCode({})
  const { data: vendorAccountDetails, loading: loadingVendorAccounts } = useVendorAccounts({})
  const [{ mutation: updateVendorCredential, loading: loadingUpdateCredential }] = useUpdateVendorCredential()
  const [{ mutation: createVendorAccount, loading: loadingCreateAccount }] = useCreateVendorAccount({ autoRun: true })

  const [form, setForm] = useState<Map<string, string>>(new Map())
  const [error, setError] = useState<string | undefined>(undefined)
  const [linking, setLinking] = useState(false)

  const loading = loadingCreateAccount || loadingVendorAccounts || loadingUpdateCredential

  const { distributor: distributorSlug } = application ?? {}

  const latestJob = selectVendorSpecificJobs(distributorSlug ?? '', vendorAccountDetails)
  const vendorAccount = selectVendorSpecificAccount(distributorSlug, vendorAccountDetails)
  const isMfaNeeded = isMfaRequired(latestJob)
  const { step, status } = latestJob

  const progress = useMemo(() => {
    return formatProgress(latestJob)
  }, [step, status, latestJob])

  useEffect(() => {
    if (isErrored(latestJob)) {
      setLinking(false)
    }

    if (isMfaRequired(latestJob)) {
      setLinking(false)
    }

    if (latestJob.step === JobStep.Scraping) {
      setLinking(true)
    }
  }, [latestJob])

  const onChangeUsername = (value: string) => {
    form.set('username', value)
    setForm(new Map(form))
    setError(undefined)
  }

  const onChangePassword = (value: string) => {
    form.set('password', value)
    setForm(new Map(form))
    setError(undefined)
  }

  const onChangeMfaCode = (value: string) => {
    form.set('code', value)
    setForm(new Map(form))
    setError(undefined)
  }

  useEffect(() => {
    const login = form.get('username')
    if (!login && vendorAccount.login) {
      onChangeUsername(vendorAccount.login)
    }
  }, [vendorAccount?.login])

  const onSubmitMfaCode = async () => {
    try {
      setError(undefined)
      setLinking(true)

      await submitMfaCode(
        latestJob?.vendorAccountId ?? '',
        form.get('code') ?? ''
      )

      setLinking(true)
    } catch (error: any) {
      // network error
      setError(error.toString())
      setLinking(false)
    }
  }

  const onStartVendorAccount = async () => {
    try {
      setError(undefined)
      setLinking(true)

      if (vendorAccount?.id) {
        const { data: updateCredentialData } = await updateVendorCredential({
          variables: {
            vendorAccountId: vendorAccount.id,
            credential: {
              login: form.get('username') ?? '',
              password: form.get('password') ?? '',
            }
          }
        })

        const { updateCredential: updateCredentialResult } = updateCredentialData ?? {}

        if (updateCredentialResult?.status !== Status.success) {
          throw Error(updateCredentialResult?.message)
        }

        const { data: vendorJobdata } = await startVendorJob({
          variables: {
            vendorAccountId: vendorAccount.id,
          }
        })

        const { startVendorJob: vendorJobResult } = vendorJobdata ?? {}

        if (vendorJobResult?.status !== Status.success) {
          throw Error('Failed to start linking process, please try again')
        }
      } else {
        const { createVendorAccount: data } = await createVendorAccount(
          application.distributor,
          form.get('username') ?? '',
          form.get('password') ?? '',
        )

        if (!data) {
          throw Error('Verification ID was invalid')
        }
      }
    } catch (error: any) {
      setError(error.toString())
      setLinking(false)
    }
  }

  const renderActionText = () => {
    if (isMfaRequired(latestJob)) {
      return 'Verify Code'
    }

    if (progress.length) {
      return progress
    }

    if (linking) {
      return 'Linking'
    }

    if (isErrored(latestJob)) {
      return 'Retry Linking'
    }

    return 'Link Account'
  }

  const isActionable = isErrored(latestJob) || isMfaRequired(latestJob)
  const isDisabled = !!error || loading || linking || (!!progress.length && !isActionable)

  return (
    <form>
      {isMfaNeeded ? <InputGeneral
        placeholder='Enter verification code'
        onChange={onChangeMfaCode}
        error={error}
        type="text" />
        : <div>
          <InputGeneral
            placeholder='Username'
            style={{ paddingTop: 4, paddingBottom: 4 }}
            onChange={onChangeUsername}
            value={form.get('username') ?? ''}
            disabled={isDisabled}
            error={error} />
          <InputGeneral
            placeholder='Password'
            style={{ paddingTop: 4, paddingBottom: 4 }}
            onChange={onChangePassword}
            error={error}
            disabled={isDisabled}
            type="password" />
        </div>}
      <ButtonRounded
        text={renderActionText()}
        disabled={isDisabled}
        onClick={() => isMfaNeeded ? onSubmitMfaCode() : onStartVendorAccount()}
        style={{ marginTop: 8 }} />
      {error &&
        <Typography variant="body2" align="center" color="secondary" sx={styles.info}>
          {error}
        </Typography>
      }
    </form>
  )
}
