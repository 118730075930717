import {
  useState, useLayoutEffect, MutableRefObject, useEffect
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useSize = (target: MutableRefObject<any>) => {
  const [size, setSize] = useState<DOMRectReadOnly>({ width: 0, height: 0 } as DOMRectReadOnly)

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

// iPhone 12+ Max (with browser address bars)
const VERTICAL_BREAKPOINT = 740

export const useMuiInputSize = () => {
  const { height } = useWindowDimensions()

  return height < VERTICAL_BREAKPOINT ? 'small' : undefined
}
