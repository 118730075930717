export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Date: any;
  DateTime: any;
  LocalDate: any;
  SafeInt: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
};

export type Artist = {
  __typename?: 'Artist';
  id?: Maybe<Scalars['String']>;
  listenersWeekly?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum ArtistType {
  Band = 'band',
  SoloArtist = 'solo_artist'
}

export type Authorization = {
  __typename?: 'Authorization';
  attributes: AuthorizationAttributes;
  id: Scalars['String'];
  type: Scalars['String'];
};

export type AuthorizationAttributes = {
  __typename?: 'AuthorizationAttributes';
  amount: Scalars['SafeInt'];
  createdAt: Scalars['LocalDate'];
  summary: Scalars['String'];
};

export enum AuthorizationStatus {
  Authorized = 'Authorized',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Declined = 'Declined'
}

export type AuthorizationsQueryParams = {
  accountId: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AuthorizationStatus>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  attributes: BankAccountAttributes;
  id: Scalars['String'];
};

export type BankAccountAttributes = {
  __typename?: 'BankAccountAttributes';
  accountNumber: Scalars['String'];
  available: Scalars['SafeInt'];
  /** @deprecated Use available instead. */
  balance: Scalars['SafeInt'];
  routingNumber: Scalars['String'];
};

export type BankAccountBalance = {
  __typename?: 'BankAccountBalance';
  available?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  iso_currency_code?: Maybe<Scalars['String']>;
  last_updated_datetime: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  unofficial_currency_code?: Maybe<Scalars['String']>;
};

export type BankAccountExternal = {
  __typename?: 'BankAccountExternal';
  account_id?: Maybe<Scalars['String']>;
  balances?: Maybe<BankAccountBalance>;
  id: Scalars['String'];
  mask?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BankStatement = {
  __typename?: 'BankStatement';
  attributes: BankStatementAttributes;
  id: Scalars['String'];
  type: Scalars['String'];
};

export type BankStatementAttributes = {
  __typename?: 'BankStatementAttributes';
  period: Scalars['String'];
};

export type BankTransfer = {
  accountId: Scalars['String'];
  amount: Scalars['Int'];
  idempotencyKey: Scalars['String'];
};

export type BankingLimits = {
  __typename?: 'BankingLimits';
  attributes?: Maybe<LimitsAttributes>;
};

export type Breakdown = {
  __typename?: 'Breakdown';
  rows: Array<BreakdownItem>;
  title: Scalars['String'];
};

export type BreakdownFilter = {
  type: BreakdownTypes;
  value: Scalars['String'];
};

export type BreakdownFilterType = {
  __typename?: 'BreakdownFilterType';
  type: BreakdownTypes;
  value: Scalars['String'];
};

export type BreakdownItem = {
  __typename?: 'BreakdownItem';
  name: Scalars['String'];
  total: Money;
  type?: Maybe<Scalars['String']>;
};

export type BreakdownPeriod = {
  __typename?: 'BreakdownPeriod';
  breakdowns: Array<Breakdown>;
  date?: Maybe<Scalars['LocalDate']>;
  period?: Maybe<Period>;
};

export enum BreakdownTypes {
  Country = 'Country',
  Project = 'Project',
  Royalty = 'Royalty',
  Source = 'Source',
  Vendor = 'Vendor'
}

export type Breakdowns = {
  __typename?: 'Breakdowns';
  full: BreakdownPeriod;
  period: Array<BreakdownPeriod>;
};

export enum CardDesign {
  Black = 'Black',
  Gold = 'Gold',
  Pink = 'Pink'
}

export type CashflowAgreementPreviewValues = {
  address: AddressInput;
  date: Scalars['Date'];
  email: Scalars['String'];
  entityType?: InputMaybe<Scalars['String']>;
  incorporationState?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CashflowApplication = {
  __typename?: 'CashflowApplication';
  artistType: ArtistType;
  cancelled?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmedHifi?: Maybe<Scalars['Boolean']>;
  distributor: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  spotifyArtistId: Scalars['String'];
};

export type CashflowBusinessAccountDetails = {
  address: AddressInput;
  dob: Scalars['LocalDate'];
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CashflowIndividualAccountDetails = {
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CashflowStatus = {
  __typename?: 'CashflowStatus';
  depositsOnHoldByHifi: Scalars['Boolean'];
  depositsPausedByUser: Scalars['Boolean'];
};

export type CashflowToken = {
  __typename?: 'CashflowToken';
  expiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type CashflowWaitlistApplication = {
  artistType: ArtistType;
  distributor: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  spotifyArtistId: Scalars['String'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type Credential = {
  __typename?: 'Credential';
  clientName?: Maybe<Scalars['String']>;
  login: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  vendorAccountId: Scalars['String'];
  vendorSlug?: Maybe<Scalars['String']>;
};

export type CredentialInput = {
  clientName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  vendorAccountId?: InputMaybe<Scalars['String']>;
  vendorSlug?: InputMaybe<Scalars['String']>;
};

export type CredentialRecord = {
  __typename?: 'CredentialRecord';
  id?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  vendor_account_id?: Maybe<Scalars['String']>;
};

export type CustomClaims = {
  __typename?: 'CustomClaims';
  hasBulkCreds: Scalars['Boolean'];
  hasCashflow: Scalars['Boolean'];
  hasCashflowActivated: Scalars['Boolean'];
  hasCustomDashboards: Scalars['Boolean'];
  hasEnterprise: Scalars['Boolean'];
  hasNormalizedDownloads: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isApplicant: Scalars['Boolean'];
  userHash: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  attributes: CustomerAttributes;
  type: CustomerType;
};

export type CustomerAttributes = {
  __typename?: 'CustomerAttributes';
  address: Address;
};

export enum CustomerType {
  BusinessCustomer = 'businessCustomer',
  IndividualCustomer = 'individualCustomer'
}

export type Dashboard = {
  __typename?: 'Dashboard';
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title: Scalars['String'];
  userId: Scalars['ID'];
  vendorAccountIds: Array<Scalars['ID']>;
  vendorSubaccountIds: Array<Scalars['ID']>;
};

export type DashboardInput = {
  title: Scalars['String'];
  vendorAccountIds: Array<Scalars['ID']>;
  vendorSubaccountIds: Array<Scalars['ID']>;
};

export enum DateType {
  SaleAt = 'SaleAt',
  StatementAt = 'StatementAt'
}

export type DebitCard = {
  __typename?: 'DebitCard';
  cardholder: Scalars['String'];
  expiry: Scalars['String'];
  id: Scalars['String'];
  last4Digits: Scalars['String'];
};

export type DepositDetails = {
  __typename?: 'DepositDetails';
  fee: Scalars['Float'];
  gross: Scalars['Float'];
  net: Scalars['Float'];
};

export type DepositSummary = {
  __typename?: 'DepositSummary';
  outstandingCollection: Scalars['Float'];
  totalCollection: Scalars['Float'];
  totalFees: Scalars['Float'];
  totalPaid: Scalars['Float'];
};

export type EarningsDaily = {
  __typename?: 'EarningsDaily';
  available: Scalars['Float'];
  model: Scalars['String'];
  paid: Scalars['Float'];
  realtime: Scalars['Float'];
  total: Scalars['Float'];
};

export enum Feature {
  MembershipAppCompleteModule = 'membership_app_complete_module',
  SoundExchangeModule = 'sound_exchange_module',
  WorkingCapital = 'working_capital'
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  hasBulkCreds: Scalars['Boolean'];
};

export type FeatureInterests = {
  __typename?: 'FeatureInterests';
  cashflow: Interest;
  membership: Interest;
  soundexchange: Interest;
};

export enum Filter {
  Country = 'Country',
  Project = 'Project',
  Royalty = 'Royalty',
  Source = 'Source',
  Vendor = 'Vendor'
}

export type HifiDeposit = {
  __typename?: 'HifiDeposit';
  amount: Scalars['Float'];
  depositedAt: Scalars['LocalDate'];
  type: HifiDepositType;
};

export type HifiDepositAggregate = {
  __typename?: 'HifiDepositAggregate';
  amount: Scalars['Float'];
  dailyTransactionId: Scalars['String'];
  disbursementDate: Scalars['Date'];
};

export enum HifiDepositType {
  Daily = 'daily',
  Realtime = 'realtime'
}

export enum HifiTransactionType {
  DepositDistributor = 'depositDistributor',
  DepositHifiDaily = 'depositHifiDaily',
  DepositHifiRealtime = 'depositHifiRealtime',
  WithdrawalHifiFees = 'withdrawalHifiFees'
}

export type IdentityBusiness = {
  address: AddressInput;
  ein: Scalars['String'];
  entityType: Scalars['String'];
  incorporationState: Scalars['String'];
  incorporationYear: Scalars['String'];
  industry: Scalars['String'];
  name: Scalars['String'];
  officer: PersonInput;
  owners: Array<InputMaybe<PersonInput>>;
  phoneNumber: Scalars['String'];
};

export type IdentityIndividual = {
  address: AddressInput;
  dob: Scalars['LocalDate'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  industry: Scalars['String'];
  lastName: Scalars['String'];
  ssn: Scalars['String'];
};

export enum IdentityStatus {
  Approved = 'Approved',
  AwaitingDocuments = 'AwaitingDocuments',
  Canceled = 'Canceled',
  Denied = 'Denied',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  PendingReview = 'PendingReview'
}

export type InstitutionInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum Interest {
  Interested = 'interested',
  NotInterested = 'not_interested',
  Unknown = 'unknown'
}

export type JobOptions = {
  connect?: InputMaybe<Scalars['Boolean']>;
};

export enum JobStatus {
  CaptchaFailure = 'captcha_failure',
  Completed = 'completed',
  CredentialsFailure = 'credentials_failure',
  Disabled = 'disabled',
  Failed = 'failed',
  Initalizing = 'initalizing',
  MfaChoiceRequired = 'mfa_choice_required',
  MfaCodeFailure = 'mfa_code_failure',
  MfaCodeFailureNoRetry = 'mfa_code_failure_no_retry',
  MfaCodeRequired = 'mfa_code_required',
  MfaCodeResend = 'mfa_code_resend',
  MfaQuestionFailure = 'mfa_question_failure',
  MfaQuestionRequired = 'mfa_question_required',
  Running = 'running',
  SessionActive = 'session_active',
  SessionExpired = 'session_expired',
  Skipped = 'skipped',
  Stalled = 'stalled',
  TermsOfServiceFailure = 'terms_of_service_failure',
  Unknown = 'unknown'
}

export enum JobStep {
  Authenticating = 'authenticating',
  Downloading = 'downloading',
  Finished = 'finished',
  Normalizing = 'normalizing',
  Scraping = 'scraping',
  Starting = 'starting',
  Waiting = 'waiting'
}

export type KycForm = {
  __typename?: 'KycForm';
  status: IdentityStatus;
  url: Scalars['String'];
};

export type LimitsAch = {
  __typename?: 'LimitsAch';
  dailyCredit: Scalars['SafeInt'];
  dailyDebit: Scalars['SafeInt'];
  monthlyCredit: Scalars['SafeInt'];
  monthlyDebit: Scalars['SafeInt'];
};

export type LimitsAttributes = {
  __typename?: 'LimitsAttributes';
  ach?: Maybe<LimitsAttributesAch>;
  card?: Maybe<LimitsAttributesCard>;
};

export type LimitsAttributesAch = {
  __typename?: 'LimitsAttributesAch';
  limits?: Maybe<LimitsAch>;
  totalsDaily?: Maybe<TotalsAch>;
  totalsMonthly?: Maybe<TotalsAch>;
};

export type LimitsAttributesCard = {
  __typename?: 'LimitsAttributesCard';
  limits?: Maybe<LimitsCard>;
  totalsDaily?: Maybe<TotalsCard>;
};

export type LimitsCard = {
  __typename?: 'LimitsCard';
  dailyCardTransaction: Scalars['SafeInt'];
  dailyDeposit: Scalars['SafeInt'];
  dailyPurchase: Scalars['SafeInt'];
  dailyWithdrawal: Scalars['SafeInt'];
};

export type ListStatus = {
  __typename?: 'ListStatus';
  waitlisted: Scalars['Boolean'];
  whitelisted: Scalars['Boolean'];
};

export type Metrics = {
  __typename?: 'Metrics';
  countries: Scalars['Int'];
  projects: Scalars['Int'];
};

export type MfaAnswerInput = {
  answer: Scalars['String'];
  questionId: Scalars['String'];
  vendorAccountId: Scalars['ID'];
};

export type MfaChoice = {
  __typename?: 'MfaChoice';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type MfaQuestion = {
  __typename?: 'MfaQuestion';
  id: Scalars['String'];
  status: MfaQuestionStatus;
  text: Scalars['String'];
};

export enum MfaQuestionStatus {
  Answered = 'answered',
  Incorrect = 'incorrect',
  Unanswered = 'unanswered'
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['BigDecimal'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addVendors: Result;
  cashflowBusinessAccount: BankAccount;
  cashflowIndividualAccount: BankAccount;
  cashflowWaitlist: Result;
  confirmApplication: Result;
  createDashboard?: Maybe<Dashboard>;
  /** @deprecated No longer supported */
  createKycForm: KycForm;
  createVendorAccount: VendorAccount;
  deleteAccount: Result;
  deleteAccountSoft: Result;
  deleteDashboard: Result;
  deleteVendorAccount: Result;
  generatePlaidLinkToken?: Maybe<PlaidLinkToken>;
  inputMfaAnswer: Result;
  inputMfaChoice: Result;
  inputMfaCode: Result;
  login: Verification;
  loginSignup: Verification;
  logout: Result;
  removeBankAccountExternal?: Maybe<Result>;
  resendCode: Verification;
  resendMfaCode: Result;
  saveCashflowActivated: Result;
  saveFeatureInterest: Result;
  saveNotificationPreference: Result;
  savePlaidAccount?: Maybe<Result>;
  saveStatementsNormalized: StatementsNormalizedResult;
  saveSuggestion: Result;
  signup: Verification;
  startVendorJob: Result;
  toggleMfaCredential: Result;
  toggleSubaccount: Result;
  toggleSubaccounts: Result;
  transferExternal?: Maybe<Result>;
  updateCredential: Result;
  updateCredentials: Result;
  updateDashboard: Result;
  updateMfaCredential: Result;
  verify: Session;
  verifyIdentityBusiness: Result;
  verifyIdentityIndividual: Result;
};


export type MutationAddVendorsArgs = {
  vendorSlugs: Array<VendorSlug>;
};


export type MutationCashflowBusinessAccountArgs = {
  details?: InputMaybe<CashflowBusinessAccountDetails>;
};


export type MutationCashflowIndividualAccountArgs = {
  details?: InputMaybe<CashflowIndividualAccountDetails>;
};


export type MutationCashflowWaitlistArgs = {
  application?: InputMaybe<CashflowWaitlistApplication>;
};


export type MutationConfirmApplicationArgs = {
  confirm: Scalars['Boolean'];
};


export type MutationCreateDashboardArgs = {
  dashboard: DashboardInput;
};


export type MutationCreateVendorAccountArgs = {
  credential?: InputMaybe<CredentialInput>;
  vendorSlug: VendorSlug;
};


export type MutationDeleteDashboardArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVendorAccountArgs = {
  vendorAccountId: Scalars['ID'];
};


export type MutationInputMfaAnswerArgs = {
  input: MfaAnswerInput;
};


export type MutationInputMfaChoiceArgs = {
  choiceId: Scalars['String'];
  vendorAccountId: Scalars['ID'];
};


export type MutationInputMfaCodeArgs = {
  code: Scalars['String'];
  manual?: InputMaybe<Scalars['Boolean']>;
  vendorAccountId: Scalars['ID'];
};


export type MutationLoginArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationLoginSignupArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationRemoveBankAccountExternalArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type MutationResendCodeArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationResendMfaCodeArgs = {
  vendorAccountId: Scalars['ID'];
};


export type MutationSaveCashflowActivatedArgs = {
  timeZone: Scalars['String'];
};


export type MutationSaveFeatureInterestArgs = {
  feature: Feature;
  interest: Interest;
};


export type MutationSaveNotificationPreferenceArgs = {
  enabled: Scalars['Boolean'];
};


export type MutationSavePlaidAccountArgs = {
  institution: InstitutionInput;
  linkSessionId?: InputMaybe<Scalars['String']>;
  publicToken: Scalars['String'];
};


export type MutationSaveStatementsNormalizedArgs = {
  filterTags?: InputMaybe<Scalars['String']>;
  filters: RoyaltyFilters;
};


export type MutationSaveSuggestionArgs = {
  suggestion: Scalars['String'];
};


export type MutationSignupArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationStartVendorJobArgs = {
  options?: InputMaybe<JobOptions>;
  vendorAccountId: Scalars['ID'];
};


export type MutationToggleMfaCredentialArgs = {
  bulkReady?: InputMaybe<Scalars['Boolean']>;
  mfaBulkReady?: InputMaybe<Scalars['Boolean']>;
  mfaManualBulkReady?: InputMaybe<Scalars['Boolean']>;
  vendorAccountId: Scalars['ID'];
};


export type MutationToggleSubaccountArgs = {
  enabled: Scalars['Boolean'];
  subaccountId: Scalars['ID'];
};


export type MutationToggleSubaccountsArgs = {
  enabled: Scalars['Boolean'];
  subaccountIds: Array<Scalars['ID']>;
};


export type MutationTransferExternalArgs = {
  transfer?: InputMaybe<BankTransfer>;
};


export type MutationUpdateCredentialArgs = {
  credential: CredentialInput;
  vendorAccountId: Scalars['ID'];
};


export type MutationUpdateCredentialsArgs = {
  credentials: Array<InputMaybe<CredentialInput>>;
};


export type MutationUpdateDashboardArgs = {
  dashboard: DashboardInput;
  id: Scalars['ID'];
};


export type MutationUpdateMfaCredentialArgs = {
  bulkReady?: InputMaybe<Scalars['Boolean']>;
  credential: CredentialInput;
  vendorAccountId: Scalars['ID'];
};


export type MutationVerifyArgs = {
  pin: Scalars['String'];
  verificationId: Scalars['ID'];
};


export type MutationVerifyIdentityBusinessArgs = {
  identity: IdentityBusiness;
};


export type MutationVerifyIdentityIndividualArgs = {
  identity: IdentityIndividual;
};

export type NormalizedDownloadHistoryRecord = {
  __typename?: 'NormalizedDownloadHistoryRecord';
  activityStatus?: Maybe<NormalizedDownloadHistoryStatus>;
  downloadId?: Maybe<Scalars['String']>;
  filterTags?: Maybe<Scalars['String']>;
  gcsFileName?: Maybe<Scalars['String']>;
  gcsFilePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  queryParameters?: Maybe<QueryParameters>;
  updatedAt?: Maybe<Scalars['String']>;
  useCurrency?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export enum NormalizedDownloadHistoryStatus {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Running = 'running',
  Skipped = 'skipped',
  Succeeded = 'succeeded'
}

export enum Period {
  Daily = 'Daily',
  Full = 'Full',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export type PeriodTotal = {
  __typename?: 'PeriodTotal';
  date: Scalars['String'];
  total: Money;
};

export type PersonInput = {
  address: AddressInput;
  dob: Scalars['LocalDate'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  occupation: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  share?: InputMaybe<Scalars['Int']>;
  ssn: Scalars['String'];
};

export type PlaidLinkToken = {
  __typename?: 'PlaidLinkToken';
  expiration?: Maybe<Scalars['String']>;
  link_token?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  application?: Maybe<CashflowApplication>;
  artist?: Maybe<Artist>;
  bankAccountExternalBalance?: Maybe<BankAccountExternal>;
  bankAccountsExternal?: Maybe<Array<Maybe<BankAccountExternal>>>;
  bankingLimits?: Maybe<BankingLimits>;
  breakdownPeriods?: Maybe<Breakdowns>;
  breakdowns?: Maybe<Breakdowns>;
  cache?: Maybe<Result>;
  cashflowAccount?: Maybe<BankAccount>;
  cashflowAgreement: Scalars['String'];
  cashflowAgreementPreview: Scalars['String'];
  cashflowAuthorizations: Array<Authorization>;
  cashflowBankLetter: Scalars['String'];
  cashflowCard: DebitCard;
  cashflowCustomer: Customer;
  cashflowDeposits: Array<HifiDepositAggregate>;
  cashflowDepositsRealtime: Array<HifiDeposit>;
  cashflowFee: Scalars['Float'];
  cashflowKycForm: KycForm;
  cashflowMobileWalletPayload: Scalars['String'];
  cashflowStatement: Scalars['String'];
  cashflowStatements: Array<BankStatement>;
  cashflowStatus: CashflowStatus;
  cashflowToken: CashflowToken;
  cashflowTransaction: Transaction;
  cashflowTransactions: Array<Transaction>;
  countries?: Maybe<Breakdowns>;
  currencies: Array<Scalars['String']>;
  customClaims: CustomClaims;
  dashboard?: Maybe<Dashboard>;
  dashboards: Array<Maybe<Dashboard>>;
  depositDetails: DepositDetails;
  depositSummary: DepositSummary;
  downloadingNormalizedPercentage?: Maybe<Scalars['String']>;
  earningsHistoric?: Maybe<EarningsDaily>;
  earningsPrediction: EarningsDaily;
  earningsRealtime: EarningsDaily;
  lastCredentialsSubmitted?: Maybe<Array<CredentialRecord>>;
  metrics: Metrics;
  normalizedDownloadsHistory?: Maybe<Array<Maybe<NormalizedDownloadHistoryRecord>>>;
  projects?: Maybe<Breakdowns>;
  refresh?: Maybe<Scalars['Boolean']>;
  sources?: Maybe<Breakdowns>;
  spotifyArtist?: Maybe<SpotifyArtist>;
  spotifySearchArtists?: Maybe<Array<Maybe<SpotifyArtist>>>;
  statementUrl: Scalars['String'];
  statements: Array<Statement>;
  statementsNormalizedCount?: Maybe<Scalars['String']>;
  statementsNormalizedUrl?: Maybe<Scalars['String']>;
  suggestions: Array<Scalars['String']>;
  terms?: Maybe<Array<Maybe<UserTerm>>>;
  totals?: Maybe<Totals>;
  trackMetrics?: Maybe<TrackMetrics>;
  tracks?: Maybe<Array<Maybe<Track>>>;
  uploadFileExists: Scalars['Boolean'];
  user?: Maybe<User>;
  vendorAccountBalance?: Maybe<VendorAccountBalance>;
  vendorAccounts: Array<VendorAccount>;
  vendorJob?: Maybe<VendorJob>;
  vendorJobs: Array<VendorJob>;
  vendors: Array<Vendor>;
};


export type QueryArtistArgs = {
  vendorAccountId?: InputMaybe<Scalars['String']>;
};


export type QueryBankAccountExternalBalanceArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type QueryBreakdownPeriodsArgs = {
  filters: RoyaltyFilters;
};


export type QueryBreakdownsArgs = {
  filters: RoyaltyFilters;
};


export type QueryCacheArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryCashflowAgreementPreviewArgs = {
  values: CashflowAgreementPreviewValues;
};


export type QueryCashflowAuthorizationsArgs = {
  params: AuthorizationsQueryParams;
};


export type QueryCashflowDepositsArgs = {
  days: Scalars['Int'];
};


export type QueryCashflowDepositsRealtimeArgs = {
  disbursementId: Scalars['String'];
};


export type QueryCashflowFeeArgs = {
  date: Scalars['Date'];
};


export type QueryCashflowMobileWalletPayloadArgs = {
  signedNonce: Scalars['String'];
};


export type QueryCashflowStatementArgs = {
  id: Scalars['String'];
  isPdf?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCashflowTransactionArgs = {
  id: Scalars['String'];
};


export type QueryCashflowTransactionsArgs = {
  params: TransactionsQueryParams;
};


export type QueryCountriesArgs = {
  filters: RoyaltyFilters;
};


export type QueryCurrenciesArgs = {
  filters: RoyaltyFilters;
};


export type QueryDashboardArgs = {
  id: Scalars['ID'];
};


export type QueryDashboardsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryDepositDetailsArgs = {
  disbursementId: Scalars['String'];
};


export type QueryDownloadingNormalizedPercentageArgs = {
  downloadId?: InputMaybe<Scalars['String']>;
};


export type QueryEarningsHistoricArgs = {
  date: Scalars['Date'];
};


export type QueryEarningsPredictionArgs = {
  timeZone?: InputMaybe<Scalars['String']>;
};


export type QueryNormalizedDownloadsHistoryArgs = {
  dashboardId?: InputMaybe<Scalars['String']>;
};


export type QueryProjectsArgs = {
  filters: RoyaltyFilters;
};


export type QuerySourcesArgs = {
  filters: RoyaltyFilters;
};


export type QuerySpotifyArtistArgs = {
  id: Scalars['String'];
};


export type QuerySpotifySearchArtistsArgs = {
  request?: InputMaybe<SpotifySearchRequest>;
};


export type QueryStatementUrlArgs = {
  jobId: Scalars['String'];
  statementId: Scalars['String'];
};


export type QueryStatementsArgs = {
  filters: RoyaltyFilters;
};


export type QueryStatementsNormalizedCountArgs = {
  filters: RoyaltyFilters;
};


export type QueryStatementsNormalizedUrlArgs = {
  filters: RoyaltyFilters;
  timeStamp?: InputMaybe<Scalars['String']>;
};


export type QueryTotalsArgs = {
  filters: RoyaltyFilters;
};


export type QueryTrackMetricsArgs = {
  distributorId?: InputMaybe<Scalars['String']>;
  dspId?: InputMaybe<Scalars['String']>;
};


export type QueryTracksArgs = {
  vendorAccountId?: InputMaybe<Scalars['String']>;
};


export type QueryUploadFileExistsArgs = {
  fileName: Scalars['String'];
};


export type QueryVendorAccountBalanceArgs = {
  jobId: Scalars['ID'];
};


export type QueryVendorJobArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryParameters = {
  __typename?: 'QueryParameters';
  filters?: Maybe<RoyaltyFiltersType>;
  jobIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RoyaltyFilters = {
  currency?: InputMaybe<Scalars['String']>;
  dashboardIds?: InputMaybe<Array<Scalars['ID']>>;
  dateType?: InputMaybe<DateType>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  period?: InputMaybe<Period>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  values?: InputMaybe<Array<BreakdownFilter>>;
  vendorAccountIds?: InputMaybe<Array<Scalars['ID']>>;
  vendorSlugs?: InputMaybe<Array<VendorSlug>>;
  vendorSubaccountIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RoyaltyFiltersType = {
  __typename?: 'RoyaltyFiltersType';
  currency?: Maybe<Scalars['String']>;
  dashboardIds?: Maybe<Array<Scalars['ID']>>;
  dateType?: Maybe<DateType>;
  endDate?: Maybe<Scalars['LocalDate']>;
  period?: Maybe<Period>;
  startDate?: Maybe<Scalars['LocalDate']>;
  values?: Maybe<Array<BreakdownFilterType>>;
  vendorAccountIds?: Maybe<Array<Scalars['ID']>>;
  vendorSlugs?: Maybe<Array<VendorSlug>>;
  vendorSubaccountIds?: Maybe<Array<Scalars['ID']>>;
};

export type Session = {
  __typename?: 'Session';
  accountId?: Maybe<Scalars['ID']>;
  jwt?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  verification: Verification;
};

export type SpotifyArtist = {
  __typename?: 'SpotifyArtist';
  followers?: Maybe<Scalars['Int']>;
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type SpotifyImage = {
  __typename?: 'SpotifyImage';
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type SpotifySearchRequest = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type Statement = {
  __typename?: 'Statement';
  createdAt: Scalars['LocalDate'];
  date?: Maybe<Scalars['LocalDate']>;
  id: Scalars['ID'];
  jobId: Scalars['String'];
  name: Scalars['String'];
  subaccountId?: Maybe<Scalars['ID']>;
  total?: Maybe<Scalars['BigDecimal']>;
  vendorAccountId: Scalars['ID'];
  vendorSlug: VendorSlug;
};

export type StatementsNormalizedResult = {
  __typename?: 'StatementsNormalizedResult';
  downloadId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<StatementsNormalizedStatusTypes>;
};

export enum StatementsNormalizedStatusTypes {
  Failure = 'failure',
  Pending = 'pending',
  Success = 'success'
}

export enum SubaccountCategory {
  Artist = 'artist',
  Neighbouring = 'neighbouring',
  Publishing = 'publishing',
  Recording = 'recording',
  RightsOwner = 'rights_owner'
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
};

export enum TermType {
  CardDisclosure = 'card_disclosure',
  CardholderTerms = 'cardholder_terms',
  CashflowAgreement = 'cashflow_agreement',
  ClientTerms = 'client_terms',
  DepositTerms = 'deposit_terms',
  ElectronicDisclosure = 'electronic_disclosure',
  PrivacyPolicy = 'privacy_policy'
}

export type Totals = {
  __typename?: 'Totals';
  full: PeriodTotal;
  period: Array<PeriodTotal>;
};

export type TotalsAch = {
  __typename?: 'TotalsAch';
  credits: Scalars['SafeInt'];
  debits: Scalars['SafeInt'];
};

export type TotalsCard = {
  __typename?: 'TotalsCard';
  cardTransactions: Scalars['SafeInt'];
  deposits: Scalars['SafeInt'];
  purchases: Scalars['SafeInt'];
  withdrawals: Scalars['SafeInt'];
};

export type Track = {
  __typename?: 'Track';
  cashflowEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isrc?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  vendorAccountId?: Maybe<Scalars['String']>;
};

export type TrackMetrics = {
  __typename?: 'TrackMetrics';
  trackCount?: Maybe<Scalars['Int']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  attributes: TransactionAttributes;
  id: Scalars['String'];
  relationships: TransactionRelationships;
  type: Scalars['String'];
};

export type TransactionAttributes = {
  __typename?: 'TransactionAttributes';
  amount: Scalars['SafeInt'];
  counterparty?: Maybe<Counterparty>;
  createdAt: Scalars['LocalDate'];
  direction: Scalars['String'];
  summary: Scalars['String'];
  tags?: Maybe<TransactionTags>;
};

export type TransactionRelationships = {
  __typename?: 'TransactionRelationships';
  counterpartyAccount?: Maybe<UnitRelationship>;
};

export type TransactionTags = {
  __typename?: 'TransactionTags';
  hifiCollectionAmount?: Maybe<Scalars['String']>;
  hifiDisbursementId?: Maybe<Scalars['String']>;
  hifiDisbursementTotal?: Maybe<Scalars['String']>;
  hifiDistributorSlug?: Maybe<VendorSlug>;
  hifiTransactionType?: Maybe<HifiTransactionType>;
};

export type TransactionsQueryParams = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type UnitRelationship = {
  __typename?: 'UnitRelationship';
  data?: Maybe<UnitRelationshipData>;
};

export type UnitRelationshipData = {
  __typename?: 'UnitRelationshipData';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  accountId: Scalars['ID'];
  allowlisted: Scalars['Boolean'];
  fileUploadsEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  interests: FeatureInterests;
  memberApplicationSubmitted: Scalars['Boolean'];
  notificationsEnabled: Scalars['Boolean'];
  onboarded: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
};

export type UserTerm = {
  __typename?: 'UserTerm';
  acceptedAt?: Maybe<Scalars['Date']>;
  type: TermType;
  url: Scalars['String'];
  version: Scalars['Int'];
};

export type Vendor = {
  __typename?: 'Vendor';
  createdAt?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  mfa?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<VendorStatus>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type VendorAccount = {
  __typename?: 'VendorAccount';
  bulkReady: Scalars['Boolean'];
  cashflowEnabled: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  latestJob?: Maybe<Scalars['ID']>;
  latestSuccessfulJob?: Maybe<Scalars['ID']>;
  login?: Maybe<Scalars['String']>;
  mfaBulkReady: Scalars['Boolean'];
  mfaManualBulkReady?: Maybe<Scalars['Boolean']>;
  status?: Maybe<VendorAccountStatus>;
  subaccounts: Array<VendorSubaccount>;
  updatedAt: Scalars['String'];
  vendorSlug: VendorSlug;
};

export type VendorAccountBalance = {
  __typename?: 'VendorAccountBalance';
  balanceAmount?: Maybe<Scalars['Float']>;
  balanceCurrency?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  jobId: Scalars['ID'];
  payoutDestination?: Maybe<Scalars['String']>;
  payoutDestinationTrusted?: Maybe<Scalars['Boolean']>;
  payoutThreshold?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
};

export enum VendorAccountStatus {
  Connected = 'connected',
  Degraded = 'degraded',
  Disconnected = 'disconnected',
  Hidden = 'hidden',
  Linking = 'linking',
  NeedsAttention = 'needs_attention'
}

export type VendorJob = {
  __typename?: 'VendorJob';
  credentialsIncorrect?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfaChoices: Array<MfaChoice>;
  mfaQuestions: Array<MfaQuestion>;
  status?: Maybe<JobStatus>;
  step?: Maybe<JobStep>;
  targets?: Maybe<Scalars['String']>;
  vendorAccountId: Scalars['ID'];
  vendorSlug?: Maybe<VendorSlug>;
};

export enum VendorSlug {
  Actra = 'actra',
  Ascap = 'ascap',
  Awal = 'awal',
  BandCamp = 'band_camp',
  Bmg = 'bmg',
  Bmi = 'bmi',
  CdBaby = 'cd_baby',
  Cmrra = 'cmrra',
  Concord = 'concord',
  Distrokid = 'distrokid',
  Ditto = 'ditto',
  Empire = 'empire',
  Gmr = 'gmr',
  Hifi = 'hifi',
  Kobalt = 'kobalt',
  Level = 'level',
  Mlc = 'mlc',
  Mroc = 'mroc',
  MushroomAvex = 'mushroom_avex',
  MushroomMusic = 'mushroom_music',
  Orchard = 'orchard',
  Platoon = 'platoon',
  PlatoonAwal = 'platoon_awal',
  Ppl = 'ppl',
  Prs = 'prs',
  Repost = 'repost',
  ReverbNation = 'reverb_nation',
  Sesac = 'sesac',
  Socan = 'socan',
  Songtrust = 'songtrust',
  SonyMusic = 'sony_music',
  SonyMusicPublishing = 'sony_music_publishing',
  SoundExchange = 'sound_exchange',
  Spotify = 'spotify',
  Stem = 'stem',
  Symphonic = 'symphonic',
  Tunecore = 'tunecore',
  Umg = 'umg',
  Umpg = 'umpg',
  UnitedMasters = 'united_masters',
  WarnerChappell = 'warner_chappell',
  WarnerMusic = 'warner_music'
}

export enum VendorStatus {
  Degraded = 'degraded',
  Hidden = 'hidden',
  Offline = 'offline',
  Online = 'online',
  Unimplemented = 'unimplemented'
}

export type VendorSubaccount = {
  __typename?: 'VendorSubaccount';
  category?: Maybe<SubaccountCategory>;
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  vendorAccountId: Scalars['ID'];
};

export type Verification = {
  __typename?: 'Verification';
  exists?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  status?: Maybe<VerificationStatus>;
};

export enum VerificationStatus {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type CashflowKycFormQueryVariables = Exact<{ [key: string]: never; }>;


export type CashflowKycFormQuery = { __typename?: 'Query', cashflowKycForm: { __typename?: 'KycForm', status: IdentityStatus, url: string } };

export type ConfirmApplicationMutationVariables = Exact<{
  confirm: Scalars['Boolean'];
}>;


export type ConfirmApplicationMutation = { __typename?: 'Mutation', confirmApplication: { __typename?: 'Result', status?: string, message?: string } };

export type VerifyIdentityIndividualMutationVariables = Exact<{
  identity: IdentityIndividual;
}>;


export type VerifyIdentityIndividualMutation = { __typename?: 'Mutation', verifyIdentityIndividual: { __typename?: 'Result', status?: string, message?: string } };

export type VerifyIdentityBusinessMutationVariables = Exact<{
  identity: IdentityBusiness;
}>;


export type VerifyIdentityBusinessMutation = { __typename?: 'Mutation', verifyIdentityBusiness: { __typename?: 'Result', status?: string, message?: string } };

export type SearchSpotifyArtistsQueryVariables = Exact<{
  request?: InputMaybe<SpotifySearchRequest>;
}>;


export type SearchSpotifyArtistsQuery = { __typename?: 'Query', spotifySearchArtists?: Array<{ __typename?: 'SpotifyArtist', id?: string, name?: string, uri?: string, popularity?: number, genres?: Array<string>, followers?: number, images?: Array<{ __typename?: 'SpotifyImage', width?: number, height?: number, url?: string }> }> };

export type SpotifyArtistQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SpotifyArtistQuery = { __typename?: 'Query', spotifyArtist?: { __typename?: 'SpotifyArtist', id?: string, name?: string, uri?: string, popularity?: number, genres?: Array<string>, followers?: number, images?: Array<{ __typename?: 'SpotifyImage', width?: number, height?: number, url?: string }> } };

export type ArtistQueryVariables = Exact<{
  vendorAccountId: Scalars['String'];
}>;


export type ArtistQuery = { __typename?: 'Query', artist?: { __typename?: 'Artist', id?: string, name?: string, listenersWeekly?: number } };

export type TrackMetricsQueryVariables = Exact<{
  dspId: Scalars['String'];
  distributorId: Scalars['String'];
}>;


export type TrackMetricsQuery = { __typename?: 'Query', trackMetrics?: { __typename?: 'TrackMetrics', trackCount?: number } };

export type TermsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsQuery = { __typename?: 'Query', terms?: Array<{ __typename?: 'UserTerm', type: TermType, version: number, url: string }> };

export type CashflowAgreementPreviewQueryVariables = Exact<{
  values: CashflowAgreementPreviewValues;
}>;


export type CashflowAgreementPreviewQuery = { __typename?: 'Query', cashflowAgreementPreview: string };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', phoneNumber: string } };

export type CashflowWaitlistMutationVariables = Exact<{
  application?: InputMaybe<CashflowWaitlistApplication>;
}>;


export type CashflowWaitlistMutation = { __typename?: 'Mutation', cashflowWaitlist: { __typename?: 'Result', status?: string, message?: string } };
