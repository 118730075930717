import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { EntityType } from '@unit-finance/unit-node-sdk'
import words from 'lodash/words'
import { useState, useEffect } from 'react'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { ENTITY_TYPES } from '../shared/types'
import { IndustryField } from '../shared/IndustryField'
import { StateField } from '../shared/StateField'
import { PhoneNumberField } from '../shared/PhoneNumberField'
import { useMuiInputSize } from '../../../global/dimensions'

export const CompanyFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const { control, register, formState: { errors } } = form
  const [step, setStep] = useState(IdentityStepBusiness.Company)
  const inputSize = useMuiInputSize()

  useEffect(() => {
    setPageFields(['name', 'ein', 'industry', 'phoneNumber', 'incorporationState', 'incorporationYear', 'entityType'])
  }, [])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.Company)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.Company}
        collapsible
        error={!!errors.name || !!errors.ein || !!errors.industry || !!errors.phoneNumber}
        question="Company Info"
        summary="Company Info"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.Company)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('name', { required: true })}
              size={inputSize}
              autoComplete="off"
              label="Legal Business Name"
              error={!!errors.name}
              fullWidth />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              {...register('ein', { required: true, pattern: /^\d{2}-?\d{7}$/ })}
              size={inputSize}
              label="Employer Identification Number"
              inputProps={{ inputMode: 'numeric' }}
              error={!!errors.ein}
              fullWidth />
          </Grid>

          <Grid item xs={12}>
            <IndustryField
              name="industry"
              error={!!errors.industry}
              size={inputSize}
              control={control} />
          </Grid>

          <Grid item xs={12}>
            <PhoneNumberField
              control={control}
              name="phoneNumber"
              error={!!errors.phoneNumber}
              size={inputSize} />
          </Grid>

        </Grid>
      </AccordionBubble>

      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.Incorporation)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.Incorporation}
        collapsible
        error={!!errors.incorporationState || !!errors.incorporationYear || !!errors.entityType}
        question="Incorporation"
        summary="Incorporation"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.Incorporation)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StateField
              control={control}
              name="incorporationState"
              label="State of Incorporation"
              error={!!errors.incorporationState}
              size={inputSize} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register('incorporationYear', { required: true, minLength: 4, maxLength: 4 })}
              size={inputSize}
              label="Year of Incorporation"
              inputProps={{ inputMode: 'numeric' }}
              error={!!errors.incorporationYear}
              fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="entityType"
              rules={{ required: true }}
              control={control}
              render={({ field }) =>
                <FormControl fullWidth>
                  <InputLabel id="entity-label" size={inputSize}>Entity Type</InputLabel>
                  <Select<EntityType> {...field} fullWidth error={!!errors.entityType} labelId="entity-label" label="Entity Type" size={inputSize}>
                    {ENTITY_TYPES.map((industry) =>
                      <MenuItem key={industry} value={industry}>
                        {words(industry).join(' ')}
                      </MenuItem>)}
                  </Select>
                </FormControl>
              } />
          </Grid>
        </Grid>
      </AccordionBubble>
    </>
  )
}
