import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  SpotifyArtist, useLoginSignup, useVerify
} from '@rcrdclub/common-front'
import { useLazyQuery } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { matchIsValidTel } from 'mui-tel-input'

import { PhoneNumberInput } from './PhoneNumberInput'

import { useArtist } from '../../domain/artist/hooks'
import { colors } from '../../global/colors'
import { QUERY_SPOTIFY_ARTIST } from '../../domain/streaming/queries'
import { SpotifyArtistQuery, SpotifyArtistQueryVariables } from '../../domain/types'
import { ArtistStatic } from '../components/artist/ArtistStatic'
import { ApplicationLayout } from '../components/layouts/ApplicationLayout'

const styles = {
  form: {
    marginBottom: '1rem',
    color: colors.pink500
  },
  info: {
    margin: 'auto'
  },
  thanks: {
    marginBottom: '.5em'
  }
}

export const LoginPage = () => {
  const [searchParams] = useSearchParams()

  const [, setVerify] = useVerify()
  const [artist, setArtist] = useArtist()

  const [error, setError] = useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [{ mutation: loginSignup }] = useLoginSignup()

  const [searchArtists] = useLazyQuery<
    SpotifyArtistQuery,
    SpotifyArtistQueryVariables
  >(QUERY_SPOTIFY_ARTIST)

  useEffect(() => {
    const onLoadArtist = async () => {
      const artistId = searchParams.get('artistId')

      if (artistId && !artist?.id) {
        const { data } = await searchArtists({ variables: { id: artistId } })

        if (data) {
          setArtist({ ...data.spotifyArtist } as SpotifyArtist)
        }
      }
    }

    onLoadArtist()
  }, [searchParams])

  const onLogin = async ({ phoneNumber }: { phoneNumber: string }) => {
    try {
      setError(undefined)
      setSubmitted(true)

      const { data } = await loginSignup(phoneNumber)

      const verificationId = data?.loginSignup?.id

      if (!verificationId) {
        throw Error('Verification ID was invalid')
      }

      setVerify({ verificationId, phoneNumber })
    } catch (error: any) {
      setSubmitted(false)
      setError(error)
    }
  }

  const onChangePhoneNumber = (value: string) => {
    if (value.length < 16) {
      setError(undefined)
      return setPhoneNumber(value)
    }

    if (!matchIsValidTel(value)) {
      return setError('Invalid phone number entered')
    }

    setError(undefined)
  }

  return (
    <Container disableGutters maxWidth={false}>
      <ApplicationLayout
        step={'application'}
        headerSize="large"
        ctaText={'Send Code'}
        ctaOnClick={() => onLogin({ phoneNumber })}
        ctaDisabled={submitted && !error}
        ctaHidden={false}>
        <ArtistStatic artist={artist} size="large" />
        <div style={styles.form}>
          <PhoneNumberInput value={phoneNumber} error={error} onChange={onChangePhoneNumber} />
          <Grid container alignItems="center" flexDirection={'column'} paddingTop={3}>
            <Typography variant="body1" align="center" color="primary" sx={styles.info}>
              {'Verify your phone number to begin the'}
            </Typography>
            <Typography variant="body1" align="center" color="primary" sx={styles.info}>
              {'HIFI Cash Flow application'}
            </Typography>
          </Grid>
        </div>
        {error &&
          <Typography variant="body2" align="center" color="secondary" sx={styles.info}>
            {error.toString()}
          </Typography>
        }
      </ApplicationLayout>
    </Container>
  )
}
