import { Document, Page } from 'react-pdf'
import { useRef, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Warning from '@mui/icons-material/Warning'
import { UseFormReturn, FieldPath } from 'react-hook-form'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

// eslint-disable-next-line import/no-unresolved
import 'overlayscrollbars/overlayscrollbars.css'

import { ApplicantType, IdentityFormShared } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { UserTerm, TermType } from '../../../domain/types'
import { useCashflowAgreement } from '../../../domain/identity/hooks'

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem'
  },
  document: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  pdf: {
    marginTop: '0.5rem',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexBasis: 0,
  },
  agree: {
    marginTop: '1em',
    borderRadius: 0,
    padding: '1.4em 0'
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
})

enum TermsStep {
  ElectronicDisclosure,
  Terms,
  CashflowAgreement
}

interface TermsFormPageProps {
  applicantType: ApplicantType
  terms: UserTerm[]
  stepCount: number
  form: UseFormReturn<any>
  setPageFields: (fields: FieldPath<IdentityFormShared>[]) => void
  error: string
}

const PdfLoader = () => <div style={{ textAlign: 'center' }}><CircularProgress color="secondary" size="1.5rem" /></div>

export const TermsFormPage = ({
  applicantType,
  stepCount,
  terms,
  form,
  setPageFields,
  error
}: TermsFormPageProps) => {
  const classes = useStyles()
  const container = useRef<any>()

  const [pagesWidth, setPageWidth] = useState(0)
  const [pagesAgreement, setPagesAgreement] = useState(0)
  const [step, setStep] = useState<TermsStep | undefined>(TermsStep.ElectronicDisclosure)

  const baseStepIndex = stepCount - Object.keys(TermsStep).length / 2

  const urls = terms.reduce((result, term) => ({
    ...result,
    [term.type]: term.url
  }), {} as { [key in TermType]: string})

  const {
    register,
    setValue,
    getValues,
    formState: {
      isSubmitting,
      errors
    }
  } = form

  const { data, loading } = useCashflowAgreement(getValues, applicantType)

  // manually register form fields
  register('confirmedCashflowAgreement', { validate: (value) => value })
  register('confirmedElectronicDisclosure', { validate: (value) => value })
  register('confirmedTerms', { validate: (value) => value })

  const [confirmedTerms, confirmedCashflowAgreement] = getValues(['confirmedTerms', 'confirmedCashflowAgreement'])

  useEffect(() => {
    setPageFields(['confirmedElectronicDisclosure', 'confirmedTerms', 'confirmedCashflowAgreement'])
  }, [])

  useEffect(() => {
    if (!container.current) return

    // initial width
    setPageWidth(container.current.getBoundingClientRect().width)

    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect
      setPageWidth(width)
    })

    // subscribe to width changes
    resizeObserver.observe(container.current)

    return () => resizeObserver.disconnect()
  }, [container])

  return (
    <>
      <div ref={container} className={classes.container}>

        <AccordionBubble
          stepIndex={baseStepIndex}
          stepCount={stepCount}
          expanded={step === TermsStep.ElectronicDisclosure}
          collapsible
          error={!!errors.confirmedElectronicDisclosure}
          question="Electronic Disclosure"
          summary="Electronic Disclosure"
          hide={false}
          ErrorIcon={Warning}
          onChange={() => setStep(TermsStep.ElectronicDisclosure)}>
          <div>
            <Typography variant="body1">
              I agree to the HIFI Cash Flow
              {' '}
              <Link href={urls[TermType.ElectronicDisclosure]} target="_blank">Consent to Electronic Disclosures</Link>
              .
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setValue('confirmedElectronicDisclosure', true, { shouldValidate: true })

                const nextStep = confirmedTerms
                  ? undefined
                  : TermsStep.Terms
                setStep(nextStep)
              }}
              disableElevation
              fullWidth
              className={classes.agree}
              color="secondary">
              <Typography variant="h6" fontWeight={600} color="white">
                Accept Terms
              </Typography>
            </Button>
          </div>
        </AccordionBubble>

        <AccordionBubble
          stepIndex={baseStepIndex + 1}
          stepCount={stepCount}
          expanded={step === TermsStep.Terms}
          collapsible
          error={!!errors.confirmedTerms}
          question="Terms & Conditions"
          summary="Terms & Conditions"
          hide={false}
          ErrorIcon={Warning}
          onChange={() => setStep(TermsStep.Terms)}>
          <div>
            <Typography variant="body1">
              I agree to the HIFI Cash Flow
              {' '}
              <Link href={urls[TermType.DepositTerms]} target="_blank">Deposit Terms & Conditions</Link>
              {', '}
              <Link href={urls[TermType.ClientTerms]} target="_blank">Client Terms of Service</Link>
              {', '}
              <Link href={urls[TermType.CardholderTerms]} target="_blank">Cardholder Terms & Conditions</Link>
              {', '}
              <Link href={urls[TermType.CardDisclosure]} target="_blank">Debit Card Disclosure</Link>
              {' and the '}
              <Link href={urls[TermType.PrivacyPolicy]} target="_blank">Privacy Policy</Link>
              .
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setValue('confirmedTerms', true, { shouldValidate: true })

                const nextStep = confirmedCashflowAgreement
                  ? undefined
                  : TermsStep.CashflowAgreement
                setStep(nextStep)
              }}
              disableElevation
              fullWidth
              className={classes.agree}
              color="secondary">
              <Typography variant="h6" fontWeight={600} color="white">
                Accept Terms
              </Typography>
            </Button>
          </div>
        </AccordionBubble>

        <AccordionBubble
          stepIndex={baseStepIndex + 2}
          stepCount={stepCount}
          expanded={step === TermsStep.CashflowAgreement}
          collapsible
          fullHeight
          error={!!errors.confirmedCashflowAgreement}
          question="Cash Flow Agreement"
          summary="Cash Flow Agreement"
          hide={false}
          ErrorIcon={Warning}
          onChange={() => setStep(TermsStep.CashflowAgreement)}>
          {loading && <PdfLoader />}
          {!loading &&
            <Document
              className={classes.document}
              file={data?.cashflowAgreementPreview}
              loading={<PdfLoader />}
              onLoadSuccess={({ numPages }) => setPagesAgreement(numPages)}>
              <Typography variant="body2" paddingLeft={2}>Please read the full agreement and confirm you accept this agreement.</Typography>
              <OverlayScrollbarsComponent defer className={classes.pdf}>
                {Array.from(new Array(pagesAgreement), (_, i) =>
                  <Page
                    key={i}
                    width={pagesWidth}
                    pageIndex={i}
                    renderTextLayer={false}
                    renderAnnotationLayer={false} />)
                }
                <Button
                  variant="contained"
                  onClick={() => {
                    setValue('confirmedCashflowAgreement', true, { shouldValidate: true })
                    setStep(undefined)
                  }}
                  disableElevation
                  fullWidth
                  className={classes.agree}
                  color="secondary">
                  <Typography variant="h6" fontWeight={600} color="white">
                    Accept Terms
                  </Typography>
                </Button>
              </OverlayScrollbarsComponent>
            </Document>
          }
        </AccordionBubble>

        {isSubmitting &&
          <div className={classes.loader}>
            <CircularProgress color="secondary" size={32} />
          </div>
        }
      </div>

      {error && <Typography color="secondary" marginBottom="2rem" textAlign="center">{error}</Typography>}
    </>
  )
}
