import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import { useState } from 'react'
import { initLocalStorage, AuthProvider, CashflowProvider } from '@rcrdclub/common-front'
import { Auth, defaultAuth } from '@rcrdclub/common-front/lib/auth/provider/types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { Router } from './Router'
import { theme } from './theme'

import { DEBUG } from '../global/envs'
import { ArtistProvider } from '../domain/artist/provider'
import { ApplicationProvider } from '../domain/application/provider'
import { apolloClient } from '../global/apollo'

import './styles.css'

if (!DEBUG) {
  console.log = () => null
  console.info = () => null
  console.debug = () => null
}

initLocalStorage(
  async (key: string) => {
    return localStorage.getItem(key)
  },
  async (key: string, value: string) => {
    localStorage.setItem(key, value)
    return true
  },
  async (key: string) => {
    await localStorage.removeItem(key)
    return true
  },
)

export function App() {
  const authState = useState<Auth>(defaultAuth)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloProvider client={apolloClient}>
            <AuthProvider state={authState}>
              <ArtistProvider>
                <CashflowProvider>
                  <ApplicationProvider>
                    <Router />
                  </ApplicationProvider>
                </CashflowProvider>
              </ArtistProvider>
            </AuthProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
