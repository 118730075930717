import { useState, useEffect } from 'react'
import { useForm, FieldPath } from 'react-hook-form'

import { CompanyFormPage } from './CompanyFormPage'
import { AddressFormPage } from './AddressFormPage'
import { OfficerFormPage } from './OfficerFormPage'
import { OwnersFormPage } from './OwnersFormPage'
import { OwnerFormPage } from './OwnerFormPage'
import { OwnersDeclarationFormPage } from './OwnersDeclarationFormPage'
import { OfficerAddressFormPage } from './OfficerAddressFormPage'
import {
  IdentityFormBusiness,
  DEFAULT_FORM_VALUES_BUSINESS,
  IdentityStepBusiness,
  getSteps
} from './types'

import { PaginatedForm } from '../../components/forms/PaginatedForm'
import { ApplicantTypePage } from '../shared/ApplicantTypePage'
import { ApplicationLayout } from '../../components/layouts/ApplicationLayout'
import { TermsFormPage } from '../shared/TermsFormPage'
import { useVerifyIdentityBusiness, useVerifyHandler } from '../../../domain/identity/hooks'
import { FormProps } from '../shared/types'
import { validate, isValid } from '../../../domain/identity/validators'
import { formatFormCta } from '../../../domain/identity/formatters'

export const BusinessForm = ({ applicantType, setApplicantType, terms }: FormProps) => {
  const verifyIdentity = useVerifyIdentityBusiness()
  const { verifyHandler, verifyErrorHandler, error } = useVerifyHandler(verifyIdentity)

  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [pageValid, setPageValid] = useState(true)
  const [pageFields, setPageFields] = useState<FieldPath<IdentityFormBusiness>[]>([])

  const finalPage = page + 1 === pageCount

  const [steps, setSteps] = useState<Array<IdentityStepBusiness>>([])

  const form = useForm<IdentityFormBusiness>({
    // NOTE: `onTouched` means that fields will first be validated when onBlur()
    // is called on them, then subsequently by any onChange() events.
    mode: 'onTouched',
    defaultValues: {
      ...DEFAULT_FORM_VALUES_BUSINESS
    }
  })

  const { handleSubmit, watch, formState } = form
  const { isSubmitting } = formState

  const fieldsValid = isValid(pageFields, form)
  const otherOwnersCount = watch('otherOwnersCount')

  useEffect(() => {
    setPageValid(true)
  }, [page])

  useEffect(() => {
    setPageValid(fieldsValid)
  }, [fieldsValid])

  useEffect(() => {
    setSteps(getSteps(otherOwnersCount))
  }, [otherOwnersCount])

  const submit = handleSubmit(verifyHandler, verifyErrorHandler)

  const handleCtaClick = async () => {
    const valid = await validate(pageFields, form)
    setPageValid(valid)

    if (!valid) return

    if (finalPage) {
      await submit()
      return
    }

    setPage(page + 1)
  }

  return (
    <ApplicationLayout
      step="kyc"
      headerSize="small"
      ctaText={formatFormCta(finalPage, isSubmitting)}
      ctaDisabled={!pageValid || isSubmitting}
      ctaOnClick={handleCtaClick}>
      <PaginatedForm activePageIndex={page} setPageCount={setPageCount} onBack={() => setPage(page - 1)}>
        <ApplicantTypePage
          applicantType={applicantType}
          setApplicantType={setApplicantType}
          stepCount={steps.length}
          setPageFields={setPageFields} />

        <CompanyFormPage steps={steps} form={form} setPageFields={setPageFields} />
        <AddressFormPage steps={steps} form={form} setPageFields={setPageFields} />
        <OfficerFormPage steps={steps} form={form} setPageFields={setPageFields} />
        <OfficerAddressFormPage steps={steps} form={form} setPageFields={setPageFields} />
        <OwnersFormPage steps={steps} form={form} setPageFields={setPageFields} />

        {/* NOTE: using Array.map() would return a single array element and break our pagination */}
        {otherOwnersCount > 0 &&
          <OwnerFormPage key={1} ownerIndex={0} steps={steps} form={form} setPageFields={setPageFields} />}
        {otherOwnersCount > 1
          && <OwnerFormPage key={2} ownerIndex={1} steps={steps} form={form} setPageFields={setPageFields} />}
        {otherOwnersCount > 2
          && <OwnerFormPage key={3} ownerIndex={2} steps={steps} form={form} setPageFields={setPageFields} />}
        {otherOwnersCount > 3
          && <OwnerFormPage key={4} ownerIndex={3} steps={steps} form={form} setPageFields={setPageFields} />}

        <OwnersDeclarationFormPage steps={steps} form={form} setPageFields={setPageFields} />

        <TermsFormPage
          applicantType={applicantType}
          terms={terms}
          error={error}
          stepCount={steps.length}
          setPageFields={setPageFields}
          form={form} />
      </PaginatedForm>
    </ApplicationLayout>
  )
}
