export enum Routes {
  root = '/',
  terms = '/terms',
  apply = '/apply',
  holding = '/holding',
  identity = '/identity',
  announce = '/announce',
  license = '/license',
  licenses = '/licenses',
}

// Routes that are exclusively available locally
// to aid with testing SSR pages.
export enum LocalRoutes {
  licenses = '/ssr/licenses',
}
