import { UseFormRegister } from 'react-hook-form'
import TextField from '@mui/material/TextField'

type EmailFieldProps = {
  register: UseFormRegister<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

export const EmailField = ({
  register, name, error, size
}: EmailFieldProps) => {
  return (
    <TextField
      {...register(name, { required: true, pattern: /.*@.*/ })}
      size={size}
      autoComplete="email"
      label="Email Address"
      type="email"
      inputProps={{ inputMode: 'email' }}
      error={error}
      fullWidth />
  )
}
