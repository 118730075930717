import { useEffect, useState } from 'react'
import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { Controller } from 'react-hook-form'
import {
  FormControl, Select, MenuItem, InputAdornment
} from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'

import { IdentityStepBusiness, BusinessFormPageProps, DEFAULT_OWNER_VALUES } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { useMuiInputSize } from '../../../global/dimensions'
import { colors } from '../../../global/colors'

const style = {
  backgroundColor: colors.white,
  borderRadius: 1,
  padding: 0,
  '& .MuiListItemButton-root': {
    paddingTop: '.65em',
    paddingBottom: '.65em'
  },
  '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
    '&:hover': {
      backgroundColor: colors.pink500,
    }
  }
}

export const OwnersFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const inputSize = useMuiInputSize()
  const [step, setStep] = useState(IdentityStepBusiness.SoleBeneficialOwner)

  const {
    control,
    watch,
    register,
    setValue,
    getValues,
    formState: { errors }
  } = form

  register('isOfficerBeneficialOwner')

  useEffect(() => {
    setPageFields(['isOfficerBeneficialOwner', 'otherOwnersCount'])
  }, [])

  const otherOwnersCount = watch('otherOwnersCount')
  const isOfficerBeneficialOwner = watch('isOfficerBeneficialOwner')

  // keep the otherOwners array in sync with the otherOwnersCount
  useEffect(() => {
    const owners = getValues('otherOwners')
    const difference = otherOwnersCount - owners.length

    if (difference > 0) {
      setValue('otherOwners', [...owners, ...Array(difference).fill(DEFAULT_OWNER_VALUES)])
    }

    if (difference < 0) {
      setValue('otherOwners', owners.slice(0, difference))
    }
  }, [otherOwnersCount])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.SoleBeneficialOwner)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.SoleBeneficialOwner}
        collapsible
        error={!!errors.otherOwnersCount}
        question="Are you a beneficial owner?"
        summary="Beneficial Owner"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.SoleBeneficialOwner)}>
        <Grid container>
          <Grid item xs={12}>
            <List sx={style}>
              <ListItemButton
                divider
                selected={isOfficerBeneficialOwner}
                onClick={() => setValue('isOfficerBeneficialOwner', true)}>
                <ListItemText
                  primary="Yes"
                  secondary="I own 25% or more of the business"
                  primaryTypographyProps={{ variant: 'h6' }} />
              </ListItemButton>
              <ListItemButton
                divider
                selected={!isOfficerBeneficialOwner}
                onClick={() => setValue('isOfficerBeneficialOwner', false)}>
                <ListItemText
                  primary="No"
                  secondary="I own less than 25% of the business"
                  primaryTypographyProps={{ variant: 'h6' }} />
              </ListItemButton>
            </List>
          </Grid>

          {isOfficerBeneficialOwner &&
            <Grid item xs={12}>
              <TextField
                {...register('officerShare', {
                  required: true,
                  min: 25,
                  max: 100,
                  valueAsNumber: true
                })}
                size={inputSize}
                sx={{ marginTop: '2em' }}
                label="How much do you own?"
                type="number"
                inputProps={{ inputMode: 'numeric' }}
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                error={!!errors.officerShare}
                fullWidth />
            </Grid>
          }
        </Grid>
      </AccordionBubble>

      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.Owners)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.Owners}
        collapsible
        error={!!errors.otherOwnersCount}
        question="How many other owners hold 25% or more?"
        summary="Additional Beneficial Owners"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.Owners)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="otherOwnersCount"
              rules={{ required: true }}
              control={control}
              render={({ field }) =>
                <FormControl fullWidth>
                  <Select
                    {...field}
                    fullWidth
                    error={!!errors.otherOwnersCount}
                    size={inputSize}>
                    <MenuItem value={0}>Zero</MenuItem>
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={2}>Two</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                    {!isOfficerBeneficialOwner && <MenuItem value={4}>Four</MenuItem>}
                  </Select>
                </FormControl>
              } />
          </Grid>
        </Grid>
      </AccordionBubble>
    </>
  )
}
