import { gql } from '@apollo/client'

export const QUERY_SEARCH_SPOTIFY_ARTISTS = gql`
  query SearchSpotifyArtists($request: SpotifySearchRequest) {
    spotifySearchArtists(request: $request) {
      id,
      name,
      uri,
      popularity,
      genres,
      followers
      images {
        width,
        height,
        url
      },
    }
  }
`

export const QUERY_SPOTIFY_ARTIST = gql`
  query SpotifyArtist($id: String!) {
    spotifyArtist(id: $id) {
      id,
      name,
      uri,
      popularity,
      genres,
      followers
      images {
        width,
        height,
        url
      }
    }
  }
`

export const QUERY_HIFI_ARTIST = gql`
  query Artist($vendorAccountId: String!) {
    artist(vendorAccountId: $vendorAccountId) {
      id,
      name, 
      listenersWeekly
    }
  }
`

export const QUERY_TRACK_METRICS = gql`
  query TrackMetrics($dspId: String!, $distributorId: String!) {
    trackMetrics(dspId: $dspId, distributorId: $distributorId, ) { 
      trackCount
    }
  }
`
