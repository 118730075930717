import { useAuth, useVerify } from '@rcrdclub/common-front'

import { LoginPage } from './Login/LoginPage'
import { VerifyPage } from './Verify/VerifyPage'

export const ProtectedRoute = ({ children }: any) => {
  const [{ jwt }] = useAuth()
  const [{ verificationId }] = useVerify()

  if (!jwt) {
    if (!verificationId) return <LoginPage />
    return <VerifyPage />
  }

  return <>{children}</>
}
