import { InMemoryCache } from '@apollo/client'
import { clearUserKeys, initApolloGraphql } from '@rcrdclub/common-front'

import { API_URL_FULL } from './envs'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        spotifySearchArtists: {
          keyArgs: ['request', ['query']],
          // This merge logic handles sequential page writes but it can also tolerate repeated,
          // overlapping, or out-of-order writes, without duplicating any list items.
          // https://www.apollographql.com/docs/react/pagination/core-api/#improving-the-merge-function
          merge(existing, incoming, { args }) {
            const offset = args?.request?.offset ?? 0
            const merged = existing ? [...existing] : []

            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i]
            }

            return merged
          }
        }
      }
    }
  }
})

export const apolloClient = initApolloGraphql(
  `${API_URL_FULL}/graphql`,
  'cashflow',
  async (error: any) => {
    if (error?.extensions?.code === 'UNAUTHENTICATED') {
      // wipe local storage
      await clearUserKeys()

      // reload page to reset React auth state
      window.location.reload()
    }

    console.error('[apollo] error', error)
  },
  cache
)
