import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import EditIcon from '@mui/icons-material/Edit'
import { useState, useEffect } from 'react'
import { SpotifyArtist } from '@rcrdclub/common-front'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { Artist } from '../../../components/artist/Artist'
import { SearchInput } from '../../../components/inputs/SearchInput'
import { FullPageLayout } from '../../../components/layouts/FullPageLayout'
import { useApplying } from '../../../../domain/application/hooks'
import { useArtist } from '../../../../domain/artist/hooks'
import { normalizeScroll } from '../../../../global/scroll'
import { trackEvent } from '../../../../global/analytics'
import { SpotifyArtistQuery, SpotifyArtistQueryVariables } from '../../../../domain/types'
import { QUERY_SPOTIFY_ARTIST } from '../../../../domain/streaming/queries'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  instructions: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 3
  },
  selected: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '5px',
    padding: '0 0 0 1em'
  },
  selectedName: {
    minWidth: 0
  },
  editButton: {
    padding: 6,
    marginRight: 10
  }
}))

export const Search = () => {
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const [artist, setArtist] = useArtist()
  const [, setApplying] = useApplying()
  const [autoFocus, setAutoFocus] = useState(false)

  const [searchArtists] = useLazyQuery<SpotifyArtistQuery, SpotifyArtistQueryVariables>(QUERY_SPOTIFY_ARTIST)

  // load artist from query param
  useEffect(() => {
    const artistId = searchParams.get('artistId')

    if (!artistId) return

    const onLoadArtist = async () => {
      const { data } = await searchArtists({ variables: { id: artistId } })

      if (data?.spotifyArtist) {
        setArtist({ ...data.spotifyArtist } as SpotifyArtist)
      }
    }

    onLoadArtist()
  }, [searchParams])

  useEffect(() => {
    if (!artist) return

    trackEvent('artist_search', {
      'event_category': 'cashflow',
      'event_label': artist.name
    })
  }, [artist])

  const handleEdit = () => {
    trackEvent('artist_edit', {
      'event_category': 'cashflow'
    })
    setArtist(undefined)
    setAutoFocus(true)
  }

  const handleApply = () => {
    trackEvent('artist_apply', {
      'event_category': 'cashflow'
    })
    setApplying(true)
    normalizeScroll(false)
  }

  return (
    <FullPageLayout headerSize="large" ctaText="Join Waitlist" ctaDisabled={!artist} ctaOnClick={handleApply}>
      <div className={classes.content}>
        {!artist &&
          <>
            <div className={classes.instructions}>
              <Typography variant="h4" align="center">
                Search your artist name on Spotify to see what you&apos;re earning today:
              </Typography>
            </div>
            <div className={classes.search}>
              <SearchInput
                autoFocus={autoFocus}
                onFocus={() => {
                  normalizeScroll(false)
                }}
                onBlur={() => {
                  normalizeScroll(true)
                }}
                label="Enter your artist name"
                typography="h4" />
            </div>
          </>
        }
        {artist && (
          <>
            <Artist artist={artist} size="large" />
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              className={classes.selected}>
              <Grid item className={classes.selectedName}>
                <Typography noWrap variant="body1" color="secondary">
                  {artist.name}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton className={classes.editButton} aria-label="edit" onClick={handleEdit}>
                  <EditIcon color="secondary" fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </FullPageLayout>
  )
}
