import { MuiTelInput } from 'mui-tel-input'
import { makeStyles } from '@mui/styles'

import { colors } from '../../global/colors'

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: colors.white,
    borderRadius: 8,
    '& .MuiInputAdornment-root': {
      '& .MuiButtonBase-root': {
        display: 'none',
      },
      '& .MuiTelInput-Flag': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      paddingRight: 54
    },
    '& .MuiInputAdornment-root .MuiTypography-root': {
      ...theme.typography.h6,
    },
    '& .MuiOutlinedInput-root': {
      ...theme.typography.h6,
      paddingTop: 8,
      paddingBottom: 8,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-error fieldset': {
        borderColor: 'error.main'
      }
    }
  }
}))

export const PhoneNumberInput = (
  { value, error, onChange }:
    { value: string, onChange: Function, error: string | undefined }
) => {
  const classes = useStyles()

  return (
    <MuiTelInput
      autoComplete="tel"
      fullWidth
      value={value}
      placeholder="Enter your mobile number"
      className={classes.input}
      onChange={(event) => onChange(event)}
      defaultCountry="US"
      onlyCountries={['US']}
      forceCallingCode
      disableDropdown
      error={!!error} />
  )
}
