import { CountUp } from 'use-count-up'
import { MutableRefObject } from 'react'

const style = {
  fontSize: '45vw',
  fontWeight: 'bold',
  margin: 0
}

type LargeTickerProps = {
  tickerRef: MutableRefObject<any>
  ticking: boolean
  start: number
  end: number
  className: string
}

export const LargeTicker = ({
  tickerRef,
  ticking,
  start,
  end,
  className
}: LargeTickerProps) =>
  <CountUp
    isCounting={ticking}
    start={start}
    end={end}
    duration={30}
    easing={'linear'}
    onComplete={() => ({ shouldRepeat: true })}
    formatter={(value: number) =>
      <h1
        ref={tickerRef}
        style={style}
        className={className}>
        {value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })}
      </h1>
    } />
