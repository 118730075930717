import { VendorSlug } from '@rcrdclub/common-front'

export const SUPPORTED_DISTRIBUTORS = [
  VendorSlug.Awal,
  VendorSlug.CdBaby,
  VendorSlug.Distrokid,
  VendorSlug.Stem,
  VendorSlug.Tunecore,
  VendorSlug.UnitedMasters
]
