import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useQuery } from '@apollo/client'
import { useCashflowApplication } from '@rcrdclub/common-front'

import { ApplicantType } from './shared/types'
import { IndividualForm } from './individual/IndividualForm'
import { BusinessForm } from './business/BusinessForm'

import { Routes } from '../routes'
import { CashflowKycFormQuery, TermsQuery } from '../../domain/types'
import { QUERY_CASHFLOW_KYC_FORM } from '../../domain/application/queries'
import { QUERY_TERMS } from '../../domain/terms/queries'

import './shared/styles.css'

export const IdentityPage = () => {
  const navigate = useNavigate()
  const [applicantType, setApplicantType] = useState<ApplicantType>(ApplicantType.Individual)

  // check identity check progress
  const { data: dataForm } = useQuery<CashflowKycFormQuery>(QUERY_CASHFLOW_KYC_FORM)
  const { data: dataApplication } = useCashflowApplication({})
  const { data: dataTerms } = useQuery<TermsQuery>(QUERY_TERMS)

  const status = dataForm?.cashflowKycForm.status ?? 'Incomplete'
  const access = dataApplication?.application?.confirmedHifi ?? true
  const terms = dataTerms?.terms ?? []

  useEffect(() => {
    // redirect to application if access not granted or already applied
    if (!access || status !== 'Incomplete') {
      navigate(Routes.apply)
    }
  }, [status, access])

  return (
    <>
      {applicantType === ApplicantType.Individual &&
        <IndividualForm
          applicantType={applicantType}
          setApplicantType={setApplicantType}
          terms={terms} />}

      {applicantType === ApplicantType.Business &&
        <BusinessForm
          applicantType={applicantType}
          setApplicantType={setApplicantType}
          terms={terms} />}
    </>
  )
}
