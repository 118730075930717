import { gql } from '@apollo/client'

export const QUERY_TERMS = gql`
  query Terms {
    terms {
      type,
      version,
      url
    }
  }
`

export const QUERY_CASHFLOW_AGREEMENT_PREVIEW = gql`
  query CashflowAgreementPreview($values: CashflowAgreementPreviewValues!) {
    cashflowAgreementPreview(values: $values)
  }
`
