import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { useEffect } from 'react'

import { ApplicantType } from './types'

import { colors } from '../../../global/colors'
import { AccordionBubble } from '../../components/forms/AccordionBubble'

const style = {
  backgroundColor: colors.white,
  borderRadius: 1,
  padding: 0,
  marginTop: '2svh',
  '& .MuiListItemButton-root': {
    paddingTop: '.65em',
    paddingBottom: '.65em'
  },
  '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
    '&:hover': {
      backgroundColor: colors.pink500,
    }
  }
}

type ApplicantTypePageProps = {
  stepCount: number
  applicantType: ApplicantType
  setApplicantType: (applicantType: ApplicantType) => void
  setPageFields: (fields: []) => void
}

export const ApplicantTypePage = ({
  applicantType, setApplicantType, stepCount, setPageFields
}: ApplicantTypePageProps) => {

  useEffect(() => {
    // no fields that need to be validated
    setPageFields([])
  }, [])

  return (
    <AccordionBubble
      stepIndex={0}
      stepCount={stepCount}
      expanded
      collapsible={false}
      question="Which type of applicant are you?"
      summary="Which type of applicant are you?"
      hide={false}
      error={false}
      onChange={() => undefined}>
      <List sx={style}>
        <ListItemButton
          divider
          selected={applicantType === ApplicantType.Individual}
          onClick={() => setApplicantType(ApplicantType.Individual)}>
          <ListItemText
            primary="Individual"
            primaryTypographyProps={{ variant: 'h6' }} />
        </ListItemButton>
        <ListItemButton
          divider
          selected={applicantType === ApplicantType.Business}
          onClick={() => setApplicantType(ApplicantType.Business)}>
          <ListItemText
            primary="Business Entity"
            primaryTypographyProps={{ variant: 'h6' }} />
        </ListItemButton>
      </List>
    </AccordionBubble>
  )
}
