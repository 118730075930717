import ReactCodeInput from 'react-code-input'
import { Box } from '@mui/material'
import { forwardRef, useEffect, useRef } from 'react'
import { usePrevious } from '@rcrdclub/common-front'

import { colors } from '../../../global/colors'

type Props = {
  value: string
  error?: boolean
  disabled?: boolean
  onChange: (masked: string, raw?: string) => void
  style: any
  other?: any
}

function PinInputFunctional({
  value,
  error = false,
  style,
  disabled,
  onChange,
}: Props) {
  const pinRef = useRef(null)
  const previous = usePrevious(value)

  // clear the input if new value is empty, but previously wasnt
  useEffect(() => {
    if (!pinRef?.current || !previous) return

    const input: any = pinRef?.current

    if (previous.length && !value.length) {
      for (let i = 0; i < input.textInput.length; i += 1) {
        input.state.input[i] = ''
        input.textInput[i].value = ''
      }
    }
  }, [value])

  return (
    <Box style={{ ...style }}>
      <ReactCodeInput
        name="pin"
        ref={pinRef}
        fields={6}
        inputMode="numeric"
        disabled={disabled}
        value={value}
        inputStyle={{
          fontFamily: 'IBM Plex Sans',
          marginLeft: 6,
          MozAppearance: 'textfield',
          width: 30,
          height: 48,
          borderRadius: 6,
          fontSize: 24,
          textAlign: 'center',
          backgroundColor: colors.white,
          color: error ? colors.pink500 : colors.black,
          borderWidth: 0,
          borderColor: error ? colors.pink500 : colors.greyDark,
          borderStyle: 'solid',
          padding: '1px 2px'
        }}
        onChange={onChange} />
    </Box>
  )
}

// necessary to get a ref handle available for the above "controller" class that consumed this
// TODO: ideally we remove any idea of using a controller
const PinInput = forwardRef(PinInputFunctional)

export { PinInput }
