import { useNavigate } from 'react-router'
import Typography from '@mui/material/Typography'

import { ButtonRounded } from '../components/buttons/ButtonRounded'
import { Routes } from '../routes'

export const KycStep = () => {
  const navigate = useNavigate()

  const onOpen = async () => {
    navigate(Routes.identity)
  }

  return (
    <div>
      <ButtonRounded
        text={'Identity Check'}
        onClick={onOpen} />

      <Typography fontSize=".75rem" fontWeight={600} marginTop="2.5svh">
        Information About Procedures For Opening A New Account
      </Typography>
      <Typography fontSize=".75rem" marginTop=".5svh" fontStyle="italic">
        To help the government fight the funding of terrorism and money laundering activities,
        federal law requires all financial institutions to obtain, verify, and record information
        that identifies each person who opens an account. When you open an account, we
        will ask for your name, address, date of birth, and other information that will allow us to identify you.
        We may also ask to see your driver’s license or other identifying documents.
      </Typography>
    </div>
  )
}
