/* eslint-disable import/order */
import Modal from '@mui/material/Modal'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'
import CloseIcon from '@mui/icons-material/Close'

import packageJson from '../../../../package.json'
import { colors } from '../../../global/colors'
import { Routes } from '../../routes'
import { useHref } from 'react-router'

type HelpModalProps = {
  open: boolean
  onClose: () => void
}

const content = [
  {
    title: 'What is Cash Flow?',
    body: (
      <>
        <p>
          HIFI&apos;s Cash Flow service accelerates royalty payments to artists in real time.
        </p>
        <p>
          Your cash balance continuously grows as fans stream your music. You can save your money,
          cash it out or spend it with your choice of HIFI Visa&reg; Debit Card.
        </p>
      </>
    ),
  },
  {
    title: 'What are the dollar amounts I\'m seeing?',
    body: (
      <>
        <p>
          Amounts shown are estimates of what Cash Flow could pay you today. Your estimated payments
          are based on streaming before any splits or fees are applied.
        </p>
        <p>
          Actual deposits require a full onboarding and linking to your music services. Final
          deposit amounts may differ from estimates.
        </p>
      </>
    ),
  },
  {
    title: 'Do I qualify for Cash Flow?',
    body: (
      <>
        <p>
          Cash Flow is currently tailored to recording artists who have distributed at least some of
          their music independently.
          {' '}
          <Link
            color="secondary"
            underline="hover"
            target="_blank"
            href="https://hifi.typeform.com/to/MvH27tOe">
            Contact us
          </Link>
          {' '}
          if you&apos;re a songwriter, producer, record label, or still have questions about whether you
          qualify.
        </p>
        <p>
          To apply, choose the artist profile that corresponds to the royalties you would like to
          accelerate with Cash Flow and select your primary music distributor. You need to be an admin
          of your artist profile on DSPs and own the music you release through the distributor.
        </p>
        <p>
          HIFI&apos;s Cash Flow is limited to U.S. residents at launch.
        </p>
      </>
    ),
  },
  {
    title: 'Does Cash Flow require a long-term commitment?',
    body: (
      <p>
        Cash Flow users can pause the service or opt-out anytime, and artists retain all their rights.
      </p>
    ),
  },
  {
    title: 'Do I need to switch distributors?',
    body: (
      <>
        <p>
          No. HIFI&apos;s Cash Flow is designed to work with all distributors. You choose your own partners.
        </p>
      </>
    ),
  },
  {
    title: 'What happens after I join the Cash Flow waitlist?',
    body: (
      <>
        <p>
          We review applications on a rolling basis. When accepted, you&apos;ll receive a text with next steps.
        </p>
        <p>
          Joining the waitlist is an indication of interest. If your application is accepted, you are not
          obligated to register for the Cash Flow service.
        </p>
        <p>
          Cash Flow service activation is subject to an identity check with our banking partner.
        </p>
      </>
    ),
  },
  {
    title: 'How does HIFI make money with Cash Flow?',
    body: (
      <p>
        HIFI collects 5% of the royalties that we accelerate and we deduct the amount at the time of deposit.
      </p>
    ),
  },
  {
    title: 'What is HIFI?',
    body: (
      <>
        <p>
          HIFI is the financial rights organization for the music industry.
          A community advocating for artist-centric innovation and transparency.
        </p>
        <p>
          If you&apos;re on an artist&apos;s team, you can learn more about membership at {' '}
          <Link
            color="secondary"
            underline="hover"
            href="https://hi.fi"
            target="_blank"
            rel="noreferrer">
            hi.fi
          </Link>.
        </p>
      </>
    ),
  },
  {
    title: 'Can you answer more questions?',
    body: (
      <p>
        Of course. Whether you have questions about the service, technical issues or would simply like to
        keep up with us, we&apos;re here for you.
        {' '}
        <Link
          color="secondary"
          underline="hover"
          target="_blank"
          href="https://hifi.typeform.com/to/MvH27tOe">
          Learn more
        </Link>
        .
      </p>
    )
  }]

export const HelpModal = ({ open, onClose }: HelpModalProps) => {
  const licensesHref = useHref(Routes.licenses)

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Grid
        container
        style={{
          height: '100dvh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>

        <Grid
          xs={11}
          sm={8}
          lg={6}
          sx={{
            position: 'relative',
            backgroundColor: colors.grey200,
            padding: 3,
            overflowY: 'auto',
            margin: 2,
            overscrollBehavior: 'none'
          }}>

          {content.map((c) => (
            <div key={c.title} style={{ paddingBottom: 25 }}>
              <h2 style={{ margin: 0, fontSize: 32 }}>{c.title}</h2>
              {c.body}
            </div>
          ))}

          <div style={{ marginTop: '0' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{
                paddingTop: 12, color: colors.grey700, fontSize: 14, fontStyle: 'italic'
              }}>
                HIFI is a financial technology company and is not a bank. Banking services provided
                by Piermont Bank; Member FDIC. The HIFI Visa&reg; Debit Card is issued by Piermont Bank
                pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit
                cards are accepted.
              </span>
            </div>

            <div style={{ textAlign: 'center', color: colors.grey500, marginTop: '2rem' }}>
              <span style={{ paddingTop: 12, color: colors.greyLink }}>
                &copy; {new Date().getFullYear()} HIFI. All rights reserved.
              </span>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a id="faq_hifi_terms" href="https://hi.fi/terms" target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Terms of Service
              </a>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a id="faq_hifi_privacy" href="https://hi.fi/privacy" target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Privacy Policy
              </a>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a href={licensesHref} target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Open Source Software
              </a>
            </div>

            <div style={{
              textAlign: 'center', color: colors.grey400, marginTop: '1rem',
            }}>
              <span>
                {packageJson.version}
              </span>
            </div>
          </div>

        </Grid>

        <div
          role="button"
          tabIndex={0}
          onClick={onClose}
          style={{
            top: 16,
            right: 16,
            paddingTop: 6,
            cursor: 'pointer',
            float: 'right',
            position: 'absolute',
          }}>
          <CloseIcon style={{ fontSize: 32, pointerEvents: 'none' }} />
        </div>
      </Grid>
    </Modal>
  )
}
