import { UseFormRegister } from 'react-hook-form'
import TextField from '@mui/material/TextField'

type ZipCodeFieldProps = {
  register: UseFormRegister<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

export const ZipCodeField = ({
  register, name, error, size
}: ZipCodeFieldProps) => {
  return (
    <TextField
      {...register(name, { required: true, pattern: /^[0-9]{5}(?:-[0-9]{4})?$/ })}
      size={size}
      autoComplete="postal-code"
      label="ZIP Code"
      inputProps={{ inputMode: 'numeric' }}
      error={error}
      fullWidth />
  )
}
