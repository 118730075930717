import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { Controller } from 'react-hook-form'
import { FormControlLabel, Checkbox } from '@mui/material'
import { useEffect } from 'react'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'

export const OfficerAddressFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const { control, formState: { errors } } = form

  useEffect(() => {
    setPageFields(['confirmedBusinessContact'])
  }, [])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.ContactDeclaration)}
        stepCount={steps.length}
        expanded
        collapsible={false}
        error={!!errors.confirmedBusinessContact}
        question="Primary Contact"
        summary="Primary Contact"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="confirmedBusinessContact"
                  rules={{ validate: (value) => value }}
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={field.value} />} />
              }
              label="I confirm that I am the business's primary point of contact." />
          </Grid>
        </Grid>
      </AccordionBubble>
    </>
  )
}
