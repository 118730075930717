import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { Controller, Control } from 'react-hook-form'

import { STATES, STATE_NAME } from './types'

type StateFieldFieldProps = {
  control: Control<any>
  name: string
  error: boolean
  label?: string
  size: 'small' | undefined
}

export const StateField = ({
  control,
  name,
  error,
  label = 'State',
  size
}: StateFieldFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) =>
        <FormControl fullWidth>
          <InputLabel id="state-label" size={size}>{label}</InputLabel>
          <Select {...field} label={label} error={error} fullWidth labelId="state-label" size={size}>
            {STATES.map((state) => <MenuItem key={state} value={state}>{STATE_NAME[state]}</MenuItem>)}
          </Select>
        </FormControl>
      } />
  )
}
