import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useState, useEffect } from 'react'
import { InputAdornment } from '@mui/material'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { useMuiInputSize } from '../../../global/dimensions'
import { SocialSecurityField } from '../shared/SocialSecurityField'
import { OccupationField } from '../shared/OccupationField'
import { PhoneNumberField } from '../shared/PhoneNumberField'
import { DateOfBirthField } from '../shared/DateOfBirthField'
import { EmailField } from '../shared/EmailField'
import { AddressFields } from '../shared/AddressFields'

enum Section {
  Owner,
  Contact,
  Address
}

type OwnerFormPageProps = BusinessFormPageProps & {
  ownerIndex: number
}

export const OwnerFormPage = ({
  ownerIndex, steps, form, setPageFields
}: OwnerFormPageProps) => {
  const {
    control,
    register,
    watch,
    formState: { errors }
  } = form

  const [expanded, setExpanded] = useState<Section>(Section.Owner)
  const inputSize = useMuiInputSize()

  const isOfficerBeneficialOwner = watch('isOfficerBeneficialOwner')

  const otherOwnerNumber = ownerIndex + 1
  const overallOwnerNumber = isOfficerBeneficialOwner ? otherOwnerNumber + 1 : otherOwnerNumber
  const ownerErrors = errors.otherOwners?.[ownerIndex]

  useEffect(() => {
    setPageFields([`otherOwners.${ownerIndex}`])
  }, [])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(`Owner${otherOwnerNumber}` as IdentityStepBusiness)}
        stepCount={steps.length}
        expanded={expanded === Section.Owner}
        collapsible
        error={
          !!ownerErrors?.firstName || !!ownerErrors?.lastName || !!ownerErrors?.dob ||
          !!ownerErrors?.ssn || !!ownerErrors?.occupation || !!ownerErrors?.share
        }
        question={`Beneficial Owner ${overallOwnerNumber}`}
        summary={`Beneficial Owner ${overallOwnerNumber}`}
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setExpanded(Section.Owner)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register(`otherOwners.${ownerIndex}.firstName`, { required: true })}
              size={inputSize}
              autoComplete="given-name"
              label="First Name"
              error={!!ownerErrors?.firstName}
              fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register(`otherOwners.${ownerIndex}.lastName`, { required: true })}
              size={inputSize}
              autoComplete="family-name"
              label="Last Name"
              error={!!ownerErrors?.lastName}
              fullWidth />
          </Grid>

          <Grid item xs={6}>
            <DateOfBirthField
              control={control}
              name={`otherOwners.${ownerIndex}.dob`}
              error={!!ownerErrors?.dob}
              size={inputSize} />
          </Grid>

          <Grid item xs={6}>
            <SocialSecurityField
              register={register}
              name={`otherOwners.${ownerIndex}.ssn`}
              error={!!ownerErrors?.ssn}
              size={inputSize} />
          </Grid>

          <Grid item xs={6}>
            <OccupationField
              name={`otherOwners.${ownerIndex}.occupation`}
              error={!!ownerErrors?.occupation}
              size={inputSize}
              control={control} />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register(`otherOwners.${ownerIndex}.share`, {
                required: true,
                min: 25,
                max: 100,
                valueAsNumber: true
              })}
              size={inputSize}
              label="Ownership"
              type="number"
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              error={!!ownerErrors?.share}
              fullWidth />
          </Grid>

        </Grid>
      </AccordionBubble>

      <AccordionBubble
        stepIndex={steps.indexOf(`OwnerContact${otherOwnerNumber}` as IdentityStepBusiness)}
        stepCount={steps.length}
        expanded={expanded === Section.Contact}
        collapsible
        error={!!ownerErrors?.email || !!ownerErrors?.phoneNumber}
        question={`Beneficial Owner ${overallOwnerNumber} Contact`}
        summary={`Beneficial Owner ${overallOwnerNumber} Contact`}
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setExpanded(Section.Contact)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmailField
              register={register}
              name={`otherOwners.${ownerIndex}.email`}
              error={!!ownerErrors?.email}
              size={inputSize} />
          </Grid>

          <Grid item xs={12}>
            <PhoneNumberField
              control={control}
              name={`otherOwners.${ownerIndex}.phoneNumber`}
              error={!!ownerErrors?.phoneNumber}
              size={inputSize} />
          </Grid>
        </Grid>
      </AccordionBubble>

      <AccordionBubble
        stepIndex={steps.indexOf(`OwnerAddress${otherOwnerNumber}` as IdentityStepBusiness)}
        stepCount={steps.length}
        expanded={expanded === Section.Address}
        collapsible
        error={!!ownerErrors?.address}
        question={`Beneficial Owner ${overallOwnerNumber} Address`}
        summary={`Beneficial Owner ${overallOwnerNumber} Address`}
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setExpanded(Section.Address)}>
        <Grid container spacing={2}>
          <AddressFields
            basePath={`otherOwners.${ownerIndex}.address`}
            errors={ownerErrors?.address}
            form={form}
            inputSize={inputSize} />
        </Grid>
      </AccordionBubble>
    </>
  )
}
