import { useState, createContext, Dispatch } from 'react'
import { SpotifyArtist } from '@rcrdclub/common-front'

type ArtistStore = {
  artist: [SpotifyArtist | undefined, Dispatch<SpotifyArtist | undefined>]
}

export const ArtistContext = createContext<ArtistStore>({
  artist: [undefined, () => undefined],
})

export const ArtistProvider = ({ children }: any) => {
  const [artist, setArtist] = useState<SpotifyArtist | undefined>(undefined)

  const store: ArtistStore = {
    artist: [artist, setArtist],
  }

  return (
    <ArtistContext.Provider value={store}>
      {children}
    </ArtistContext.Provider>
  )
}
