import { useMutation, useQuery } from '@apollo/client'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import { MUTATION_VERIFY_IDENTITY_INDIVIDUAL, MUTATION_VERIFY_IDENTITY_BUSINESS } from './queries'
import { transformApplicant, transformOfficer, transformOwner } from './transformers'

import {
  VerifyIdentityIndividualMutation,
  VerifyIdentityIndividualMutationVariables,
  AddressInput,
  VerifyIdentityBusinessMutation,
  VerifyIdentityBusinessMutationVariables,
  IdentityStatus,
  Result,
  UserQuery,
  CashflowAgreementPreviewQuery,
  CashflowAgreementPreviewQueryVariables
} from '../types'
import { IdentityFormIndividual } from '../../ui/Identity/individual/types'
import { IdentityFormBusiness } from '../../ui/Identity/business/types'
import { Routes } from '../../ui/routes'
import { QUERY_USER } from '../user/queries'
import { QUERY_CASHFLOW_AGREEMENT_PREVIEW } from '../terms/queries'
import { ApplicantType } from '../../ui/Identity/shared/types'

export const useVerifyIdentityIndividual = () => {
  const [verifyIdentity] = useMutation<
    VerifyIdentityIndividualMutation,
    VerifyIdentityIndividualMutationVariables
  >(MUTATION_VERIFY_IDENTITY_INDIVIDUAL)

  return async (identity: IdentityFormIndividual) => {
    const { data: result } = await verifyIdentity({
      variables: {
        identity: {
          ...transformApplicant(identity),
          industry: identity.industry,
        }
      }
    })

    return result?.verifyIdentityIndividual as Result | undefined
  }
}

export const useVerifyIdentityBusiness = () => {
  const [verifyIdentity] = useMutation<
    VerifyIdentityBusinessMutation,
    VerifyIdentityBusinessMutationVariables
  >(MUTATION_VERIFY_IDENTITY_BUSINESS)

  const { data } = useQuery<UserQuery>(QUERY_USER)
  const phoneNumber = data?.user?.phoneNumber ?? ''

  return async (identity: IdentityFormBusiness) => {
    const officer = transformOfficer(identity.officer)
    const owners = identity.otherOwners.map(transformOwner)

    if (identity.isOfficerBeneficialOwner) {
      owners.push({
        ...officer,
        phoneNumber,
        share: identity.officerShare
      })
    }

    const { data: result } = await verifyIdentity({
      variables: {
        identity: {
          name: identity.name,
          ein: identity.ein.replaceAll('-', ''),
          industry: identity.industry,
          phoneNumber: parsePhoneNumber(identity.phoneNumber).number,
          incorporationState: identity.incorporationState,
          incorporationYear: identity.incorporationYear,
          entityType: identity.entityType,
          address: (identity.address as AddressInput),
          officer,
          owners
        }
      }
    })

    return result?.verifyIdentityBusiness as Result | undefined
  }
}

export const useVerifyHandler = <T>(verify: (identity: T) => Promise<Result | undefined>) => {
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const verifyHandler = async (identity: T) => {
    setError('')

    try {
      const result = await verify(identity)

      if (result?.status !== 'success') {
        setError(result?.message ?? 'Something went wrong. Please try again.')
        return
      }

      navigate(`${Routes.apply}?identity=${IdentityStatus.Pending}`)
    } catch {
      setError('Something went wrong. Please try again.')
    }
  }

  const verifyErrorHandler = () => {
    setError('Form invalid - please review your answers.')
  }

  return {
    verifyHandler,
    verifyErrorHandler,
    error
  }
}

export const useCashflowAgreement = (getValues: UseFormGetValues<any>, applicantType: ApplicantType) => {
  if (applicantType === ApplicantType.Individual) {
    return useQuery<CashflowAgreementPreviewQuery, CashflowAgreementPreviewQueryVariables>(
      QUERY_CASHFLOW_AGREEMENT_PREVIEW,
      {
        variables: {
          values: {
            name: `${getValues('firstName')} ${getValues('lastName')}`,
            email: getValues('email'),
            address: getValues('address'),
            date: new Date().toISOString().slice(0, 10)
          }
        }
      }
    )
  }

  return useQuery<CashflowAgreementPreviewQuery, CashflowAgreementPreviewQueryVariables>(
    QUERY_CASHFLOW_AGREEMENT_PREVIEW,
    {
      variables: {
        values: {
          name: getValues('name'),
          email: getValues('officer.email'),
          address: getValues('address'),
          incorporationState: getValues('incorporationState'),
          entityType: getValues('entityType'),
          date: new Date().toISOString().slice(0, 10)
        }
      }
    }
  )
}
