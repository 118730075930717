/* eslint-disable max-len */
import Grid from '@mui/material/Grid'
// import { makeStyles } from '@mui/styles'

import { colors } from '../global/colors'
import { Assets } from '../assets'

// TODO: issues with next + MUI makeStyles only
// const useStyles = makeStyles(() => ({
//   logoSmall: {
//     pointerEvents: 'none',
//     height: '2.8em'
//   },
//   intro: {
//     maxWidth: 650
//   },
//   link: {
//     color: colors.pink500,
//     fontWeight: 600
//   }
// }))

const styles = {
  logoSmall: {
    height: '2.5em',
  },
  intro: {
    maxWidth: 650
  },
  link: {
    color: colors.pink500,
    fontWeight: 600
  }
}

export const AnnouncementPage = () => {
  const classes = styles

  return (
    <Grid
      container
      justifyContent={{ lg: 'center', sm: 'flex-start' }}
      style={{
        height: '100dvh',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.grey200,
      }}>
      <Grid
        xs={11}
        sm={10}
        md={8}
        lg={5}
        xl={4}
        sx={{
          position: 'relative',
          marginX: 2,
          paddingX: 3,
          paddingTop: 4,
          backgroundColor: colors.grey200,
          overscrollBehavior: 'none'
        }}>

        <p style={{ paddingBottom: 20 }}>
          <img src={Assets.hifiLogo} alt="logo" style={classes.logoSmall} />
        </p>

        <div id="lipsum">
          <h3>
            To our members,
          </h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt pretium dui, quis ultricies dolor ultrices tristique. Sed sed leo non magna ultricies interdum. Ut eget purus lacus. Nunc tempor mattis nulla, eget sodales sapien bibendum sed. Sed rutrum volutpat suscipit. Nam dapibus, mi non egestas consequat, nisi lectus bibendum metus, sed ultricies felis arcu eget nunc. Praesent eget magna pellentesque, iaculis mauris vitae, dapibus sapien. Proin dignissim, neque nec pellentesque commodo, tortor felis lacinia metus, eu pharetra est arcu nec dolor. Maecenas non nunc arcu.
          </p>
          <p>
            Suspendisse ornare dignissim eros, in euismod tortor tempus vel. Maecenas a ipsum elementum neque rhoncus ultricies. Nullam aliquet dui sit amet lacus aliquam, et gravida metus volutpat. Aliquam eget urna erat. Phasellus sem nisi, rutrum vitae maximus congue, sollicitudin nec dolor. Sed laoreet nisl ante, eu pulvinar ante suscipit vel. Nulla non justo ligula. Phasellus in arcu vel ligula condimentum dignissim. Aliquam ac turpis nec nibh tempor lobortis sed eu dui. Praesent lobortis elit sit amet turpis aliquam eleifend. Duis sagittis tellus vel egestas lacinia.
          </p>
          <p>
            Nunc nec arcu iaculis, viverra felis vitae, aliquet ante. Duis dignissim auctor sem, sed ultrices est porttitor vitae. Proin ultricies ante non est finibus, ut tincidunt nisi ultricies. Nunc sagittis mi at lorem ornare, non dapibus purus viverra. Suspendisse pulvinar odio mauris, ac gravida tortor sodales lacinia. Quisque neque dui, pulvinar eget ornare et, congue non eros.
          </p>
          <h2>
            Love,
            <br />
            The HIFI Team
          </h2>
        </div>

        <div style={{ marginTop: '0' }}>
          <div style={{ textAlign: 'left' }}>
            <span style={{
              paddingTop: 12, paddingBottom: 32, color: colors.grey700, fontSize: 14, fontStyle: 'italic'
            }}>
              HIFI is a financial technology company and is not a bank. Banking services provided
              by Piermont Bank; Member FDIC. The HIFI Visa&reg; Debit Card is issued by Piermont Bank
              pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit
              cards are accepted.
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default AnnouncementPage
