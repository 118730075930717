import { VendorAccount, VendorAccountStatusQuery, VendorJob } from '@rcrdclub/common-front/lib/types'

export const selectVendorSpecificAccount = (
  vendor: string,
  vendorAccountDetails: VendorAccountStatusQuery | undefined
): VendorAccount => {
  const { vendorAccounts = [] } = vendorAccountDetails ?? {}

  const linkedDistributor = vendorAccounts.find((account) => account.vendorSlug === vendor)

  return linkedDistributor ?? {} as VendorAccount
}

export const selectVendorSpecificJobSuccessful = (
  vendor: string,
  vendorAccountDetails: VendorAccountStatusQuery | undefined
): VendorJob => {
  const vendorAccount = selectVendorSpecificAccount(vendor, vendorAccountDetails)

  const { vendorJobs = [] } = vendorAccountDetails ?? {}

  const { latestSuccessfulJob: jobId } = vendorAccount ?? {}

  const latestJob = vendorJobs?.find((job) => job.id === jobId)

  return latestJob ?? {} as VendorJob
}

export const selectVendorSpecificJobs = (
  vendor: string,
  vendorAccountDetails: VendorAccountStatusQuery | undefined
): VendorJob => {
  const vendorAccount = selectVendorSpecificAccount(vendor, vendorAccountDetails)

  const { vendorJobs = [] } = vendorAccountDetails ?? {}

  const { latestJob: latestJobId } = vendorAccount ?? {}

  const latestJob = vendorJobs?.find((job) => job.id === latestJobId)

  return latestJob ?? {} as VendorJob
}

