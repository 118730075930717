import { useRef, useLayoutEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { gsap } from 'gsap'

import { Assets } from '../../../../assets'
import { TextLarge } from '../TextLarge'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '90vw',
    aspectRatio: 'auto 500 / 314'
  },
  cardRight: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  intro: {
    paddingLeft: '12vw',
    paddingTop: '10vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '27vh',
      paddingLeft: '15vw'
    }
  },
  logo: {
    width: '100%',
    aspectRatio: 'auto 105 / 38',
    [theme.breakpoints.up('sm')]: {
      width: '68%'
    }
  }
}))

export const Intro = () => {
  const classes = useStyles()
  const intro = useRef<any>()
  const card = useRef<any>()

  useLayoutEffect(() => {
    gsap.timeline()
      .fromTo(card.current, {
        yPercent: -50,
        xPercent: 70
      }, {
        xPercent: 50,
        duration: 1
      })
  }, [])

  return (
    <section>
      <div ref={card} className={classes.cardRight}>
        <img
          src={Assets.cardShadow}
          alt="card shadow"
          className={classes.card}
          style={{
            position: 'absolute', top: '5vw', right: '-5vw', zIndex: -1
          }} />
        <img
          src={Assets.cardBlack}
          alt="card"
          className={classes.card} />
      </div>

      <div ref={intro} className={classes.intro}>
        <div style={{ display: 'inline-block' }}>
          <img src={Assets.hifiLogoBlack} alt="logo" className={classes.logo} />
          <TextLarge text="accelerates" />
        </div>

        <TextLarge text="your Cash Flow" />
      </div>
    </section>
  )
}
