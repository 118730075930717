import Typography from '@mui/material/Typography'

import { ButtonRounded } from '../components/buttons/ButtonRounded'

type DocumentationStepProps = {
  uploadUrl: string | undefined
}

export const DocumentationStep = ({ uploadUrl = '' }: DocumentationStepProps) => {
  const onOpen = async () => {
    window.location.replace(uploadUrl)
  }

  return (
    <div>
      <ButtonRounded
        text={'Upload Documents'}
        onClick={onOpen} />

      <Typography variant="body2" marginTop="2svh" fontWeight={600}>
        Additional documentation required to verify your identity.
      </Typography>
    </div>
  )
}
