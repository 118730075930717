import {
  UsAddress, BusinessVertical, State, EntityType
} from '@unit-finance/unit-node-sdk'
import { UseFormReturn, FieldPath } from 'react-hook-form'

import { IdentityFormShared } from '../shared/types'
import { Officer, Owner } from '../../../domain/identity/types'

export enum IdentityStepBusiness {
  ApplicantType = 'ApplicantType',
  Company = 'Company',
  Incorporation = 'Incorporation',
  Address = 'Address',
  Officer = 'Officer',
  OfficerContact = 'OfficerContact',
  ContactDeclaration = 'ContactDeclaration',
  SoleBeneficialOwner = 'SoleBeneficialOwner',
  Owners = 'Owners',
  Owner1 = 'Owner1',
  OwnerContact1 = 'OwnerContact1',
  OwnerAddress1 = 'OwnerAddress1',
  Owner2 = 'Owner2',
  OwnerContact2 = 'OwnerContact2',
  OwnerAddress2 = 'OwnerAddress2',
  Owner3 = 'Owner3',
  OwnerContact3 = 'OwnerContact3',
  OwnerAddress3 = 'OwnerAddress3',
  Owner4 = 'Owner4',
  OwnerContact4 = 'OwnerContact4',
  OwnerAddress4 = 'OwnerAddress4',
  OwnersDeclaration = 'OwnersDeclaration',
  ElectronicDisclosure = 'ElectronicDisclosure',
  Terms = 'Terms',
  CashflowAgreement = 'CashflowAgreement'
}

export const getSteps = (otherOwnersCount: number) => {
  const steps = [
    IdentityStepBusiness.ApplicantType,
    IdentityStepBusiness.Company,
    IdentityStepBusiness.Incorporation,
    IdentityStepBusiness.Address,
    IdentityStepBusiness.Officer,
    IdentityStepBusiness.OfficerContact,
    IdentityStepBusiness.ContactDeclaration,
    IdentityStepBusiness.SoleBeneficialOwner,
    IdentityStepBusiness.Owners
  ]

  if (otherOwnersCount >= 1) {
    steps.push(IdentityStepBusiness.Owner1)
    steps.push(IdentityStepBusiness.OwnerContact1)
    steps.push(IdentityStepBusiness.OwnerAddress1)
  }

  if (otherOwnersCount >= 2) {
    steps.push(IdentityStepBusiness.Owner2)
    steps.push(IdentityStepBusiness.OwnerContact2)
    steps.push(IdentityStepBusiness.OwnerAddress2)
  }

  if (otherOwnersCount >= 3) {
    steps.push(IdentityStepBusiness.Owner3)
    steps.push(IdentityStepBusiness.OwnerContact3)
    steps.push(IdentityStepBusiness.OwnerAddress3)
  }

  if (otherOwnersCount >= 4) {
    steps.push(IdentityStepBusiness.Owner4)
    steps.push(IdentityStepBusiness.OwnerContact4)
    steps.push(IdentityStepBusiness.OwnerAddress4)
  }

  steps.push(IdentityStepBusiness.OwnersDeclaration)
  steps.push(IdentityStepBusiness.ElectronicDisclosure)
  steps.push(IdentityStepBusiness.Terms)
  steps.push(IdentityStepBusiness.CashflowAgreement)

  return steps
}

export type IdentityFormBusiness = {
  name: string
  ein: string
  industry: BusinessVertical
  phoneNumber: string
  incorporationState: State
  incorporationYear: string
  entityType: EntityType
  address: UsAddress
  officer: Officer
  confirmedBusinessContact: boolean
  isOfficerBeneficialOwner: boolean
  officerShare: number
  otherOwnersCount: number
  otherOwners: Owner[]
  confirmedOwners: boolean
} & IdentityFormShared

export const DEFAULT_OWNER_VALUES: Owner = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  dob: null,
  ssn: '',
  occupation: 'EntertainmentSportsArtsOrMedia',
  share: 0,
  address: {
    street: '',
    street2: '',
    city: '',
    state: '' as State,
    postalCode: '',
    country: 'US'
  }
}

export const DEFAULT_FORM_VALUES_BUSINESS: IdentityFormBusiness = {
  name: '',
  ein: '',
  industry: 'ArtsEntertainmentAndRecreation',
  phoneNumber: '',
  incorporationState: '' as State,
  incorporationYear: '',
  entityType: '' as EntityType,
  address: {
    street: '',
    street2: '',
    city: '',
    state: '' as State,
    postalCode: '',
    country: 'US'
  },
  officer: {
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    ssn: '',
    occupation: 'EntertainmentSportsArtsOrMedia',
    address: {
      street: '',
      street2: '',
      city: '',
      state: '' as State,
      postalCode: '',
      country: 'US'
    }
  },
  confirmedBusinessContact: false,
  isOfficerBeneficialOwner: true,
  officerShare: 100,
  otherOwnersCount: 0,
  otherOwners: [],
  confirmedOwners: false,
  confirmedCashflowAgreement: false,
  confirmedElectronicDisclosure: false,
  confirmedTerms: false
}

export type BusinessFormPageProps = {
  steps: Array<IdentityStepBusiness>
  form: UseFormReturn<IdentityFormBusiness>
  setPageFields: (fields: FieldPath<IdentityFormBusiness>[]) => void
}
