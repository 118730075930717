import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react'
import {
  SpotifyArtist,
  useCashflowApplication,
  useVendorAccounts,
  useAuth,
  convertToDisplayName,
  VendorSlug,
  isCompleted,
  isErrored,
  isMfaRequired,
  POLL_INTERVAL,
  useVerify,
  useEarningsPrediction,
  POLL_INTERVAL_LONG
} from '@rcrdclub/common-front'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { CashflowApplication, VendorJob } from '@rcrdclub/common-front/lib/types'
import { Container } from '@mui/material'
import Link from '@mui/material/Link'

import { ApplicationProgress, KYC_PENDING_STATES } from './types'
import { DspStep } from './DspStep'
import { DistributorStep } from './DistributorStep'
import { KycStep } from './KycStep'
import { DocumentationStep } from './DocumentationStep'

import { AccordionBubble } from '../components/forms/AccordionBubble'
import { useArtist } from '../../domain/artist/hooks'
import { colors } from '../../global/colors'
import { QUERY_HIFI_ARTIST, QUERY_SPOTIFY_ARTIST, QUERY_TRACK_METRICS } from '../../domain/streaming/queries'
import {
  ArtistQuery,
  ArtistQueryVariables,
  CashflowKycFormQuery,
  ConfirmApplicationMutation,
  ConfirmApplicationMutationVariables,
  SpotifyArtistQuery,
  SpotifyArtistQueryVariables,
  TrackMetricsQuery,
  TrackMetricsQueryVariables,
  IdentityStatus
} from '../../domain/types'
import { selectVendorSpecificJobs } from '../../global/selectors'
import { formatDspProgress, formatProgress } from '../../domain/vendors/formatters'
import { MUTATION_CONFIRM_APPLICATION, QUERY_CASHFLOW_KYC_FORM } from '../../domain/application/queries'
import { formatStatus } from '../../domain/application/formatters'
import { ArtistStatic } from '../components/artist/ArtistStatic'
import { ApplicationLayout } from '../components/layouts/ApplicationLayout'
import { CONTACT_EMAIL } from '../../global/constants'

const styles = {
  form: {
    color: colors.pink500
  },
  info: {
    margin: 'auto'
  },
  thanks: {
    marginBottom: '.5em'
  }
}

enum Step {
  distributor = 'distributor',
  dsp = 'dsp',
  kyc = 'kyc'
}

const defaultProgress: ApplicationProgress = {
  distributor: false,
  dsp: false,
  estimate: false,
  confirmUser: false,
  confirmHifi: false,
  kyc: false,
}

export const ApplicationPage = () => {
  const [searchParams] = useSearchParams()

  const [{
    jwt, initialized, claims: { isApplicant }
  }] = useAuth()
  const [artist, setArtist] = useArtist()
  const [, setVerify] = useVerify()
  const [unlinkable, setUnlinkable] = useState<boolean>(false)

  const [requested, setRequested] = useState<boolean>(false)
  const [confirming, setConfirming] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<Step | undefined>()
  const [progress, setProgress] = useState<ApplicationProgress>(defaultProgress)

  const {
    data: vendorAccountDetails,
    loading: loadingAccounts,
    startPolling: startPollingAccounts,
    stopPolling: stopPollingAccounts
  } = useVendorAccounts({ watch: !!jwt })

  const {
    data: dataEarningsToday,
    startPolling: startPollingEarnings,
    stopPolling: stopPollingEarnings
  } = useEarningsPrediction({
    watch: !!jwt,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const { realtime: prediction, total, model } = dataEarningsToday?.earningsPrediction ?? {}

  const {
    data: { application } = {},
    loading: loadingApplication,
    startPolling: startPollingApplication,
    stopPolling: stopPollingApplication,
  } = useCashflowApplication({ watch: !!jwt })

  const [queryKycForm, {
    data: dataKycForm,
    loading: loadingKycForm,
    startPolling,
    stopPolling,
  }] = useLazyQuery<
    CashflowKycFormQuery
  >(QUERY_CASHFLOW_KYC_FORM)

  const [confirmApplication, { loading: loadingConfirm }] = useMutation<
    ConfirmApplicationMutation,
    ConfirmApplicationMutationVariables
  >(MUTATION_CONFIRM_APPLICATION)

  const [querySearchArtists, { loading: loadingArtistSpotify }] = useLazyQuery<
    SpotifyArtistQuery,
    SpotifyArtistQueryVariables
  >(QUERY_SPOTIFY_ARTIST)

  const [queryArtist, { data: dataArtist }] = useLazyQuery<
    ArtistQuery,
    ArtistQueryVariables
  >(QUERY_HIFI_ARTIST)

  const [queryTrackMetrics, { data: dataTrackMetrics }] = useLazyQuery<
    TrackMetricsQuery,
    TrackMetricsQueryVariables
  >(QUERY_TRACK_METRICS)

  const {
    distributor: distributorSlug = '',
    cancelled: isCancelledUser,
    confirmed: isConfirmedUser,
    confirmedHifi: isKycApproved,
    spotifyArtistId
  } = application ?? {}

  const {
    dsp: isDspCompleted,
    distributor: isDistributorCompleted,
    kyc: isKycCompleted
  } = progress

  const dspJob = selectVendorSpecificJobs(VendorSlug.Spotify, vendorAccountDetails)
  const distributorJob = selectVendorSpecificJobs(distributorSlug ?? '', vendorAccountDetails)

  const loading = [
    loadingApplication,
    loadingArtistSpotify,
    loadingAccounts,
    loadingConfirm,
    loadingKycForm
  ].includes(true)

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (dspJob?.error?.includes('invites for artist are disabled')) {
      setUnlinkable(true)
    }
  }, [dspJob])

  const { status: kycStatus, url: uploadUrl } = dataKycForm?.cashflowKycForm ?? {}

  useEffect(() => {
    if (jwt) {
      startPollingAccounts(POLL_INTERVAL)
      startPollingEarnings(POLL_INTERVAL_LONG)
      startPollingApplication(POLL_INTERVAL)
    } else if (!jwt && initialized) {
      stopPollingAccounts()
      stopPollingEarnings()
      stopPollingApplication()
    }
  }, [jwt, initialized])

  // load artist ID from query params
  useEffect(() => {
    const artistId = searchParams.get('artistId')

    if (!artistId) return

    const onLoadArtist = async () => {
      const { data } = await querySearchArtists({ variables: { id: artistId } })

      if (data) {
        setArtist({ ...data.spotifyArtist } as SpotifyArtist)
      }
    }

    onLoadArtist()
  }, [searchParams])

  // clear progress on logout
  useEffect(() => {
    if (!jwt && progress !== defaultProgress) {
      setVerify({ phoneNumber: '', verificationId: '' })
      setProgress(defaultProgress)
    }
  }, [jwt])

  // load artist ID from authenticated account application
  useEffect(() => {
    if (!jwt) return

    const onLoadArtist = async () => {
      if (spotifyArtistId && !artist) {
        const { data: spotifyArtistData } = await querySearchArtists({ variables: { id: spotifyArtistId } })

        if (spotifyArtistData) {
          setArtist({ ...spotifyArtistData.spotifyArtist } as SpotifyArtist)
        }
      }
    }

    onLoadArtist()
  }, [jwt, spotifyArtistId])

  useEffect(() => {
    if (confirming && (isConfirmedUser || isCancelledUser)) {
      setConfirming(false)
    }

  }, [isConfirmedUser])

  useEffect(() => {
    if (isKycApproved) {
      queryKycForm()
      startPolling(POLL_INTERVAL)
    }

    return () => {
      stopPolling()
    }
  }, [isKycApproved])

  // toggle the form step progress states where data has been accumulated
  useEffect(() => {
    if (loading) return

    let progressNew = progress

    const targets = JSON.parse(dspJob?.targets ?? '{}')

    if (isCompleted(dspJob) && !isErrored(dspJob) && !isDspCompleted && !targets.connect) {
      progressNew = { ...progressNew, dsp: true }
    }

    if (isCompleted(distributorJob) && !isErrored(distributorJob) && !isDistributorCompleted) {
      progressNew = { ...progressNew, distributor: true }
    }

    if (kycStatus === 'Approved' && !isKycCompleted) {
      progressNew = { ...progressNew, kyc: true }
    }

    setProgress(progressNew)

  }, [loading, jwt, progress, application, dspJob, distributorJob, isApplicant, kycStatus])

  useEffect(() => {
    if (isDspCompleted) {
      queryArtist({
        variables: {
          vendorAccountId: dspJob.vendorAccountId
        }
      })
    }

    if (isDistributorCompleted) {
      queryTrackMetrics({
        variables: {
          dspId: dspJob.vendorAccountId,
          distributorId: distributorJob.vendorAccountId
        }
      })
    }
  }, [isDspCompleted, isDistributorCompleted])

  const onLaunchApp = async () => {
    const windowNew = window.open('https://hi.fi/atlas', '_blank')
    windowNew?.focus()
  }

  const onClickConfirmApplication = async () => {
    try {
      setConfirming(true)

      const { errors } = await confirmApplication({
        variables: {
          confirm: true
        }
      })

      if (errors?.length) {
        throw Error('Failed to confirm application')
      }
    } catch (error) {
      console.error(error)
      setConfirming(false)
    }
  }

  const onClickCancelApplication = async () => {
    try {
      setConfirming(true)

      await confirmApplication({
        variables: {
          confirm: false
        }
      })

    } catch (error) {
      console.error(error)
      setConfirming(false)
    }
  }

  const isJobValid = (job: VendorJob) => {
    return job && job.status && job.step
  }

  const isDspProcessing = isJobValid(dspJob)
    && !isCompleted(dspJob)
    && !isErrored(dspJob)

  const isDistributorProcessing = isJobValid(distributorJob)
    && !isCompleted(distributorJob)
    && !isErrored(distributorJob)
    && !isMfaRequired(distributorJob)

  // Indicates the status we expect KYC to update to shortly.
  // Allows us to update the UI ahead of the status being updated on the backend.
  const expectedStatus = searchParams.get('identity') as IdentityStatus
  const isKycStatusUnknown = expectedStatus === 'Pending' && (!kycStatus || kycStatus === 'Incomplete')
  const isKycStatusReviewing = [kycStatus, expectedStatus].includes(IdentityStatus.PendingReview)

  const isKycStatusPending = !!kycStatus && KYC_PENDING_STATES.includes(kycStatus)
  const isKycProcessing = !isKycCompleted && (isKycStatusUnknown || isKycStatusPending || isKycStatusReviewing)

  const totalStepCount = isConfirmedUser ? 3 : 2
  const distributorName = distributorSlug ? convertToDisplayName(distributorSlug as VendorSlug) : 'distributor'

  const isFullyConnected = isDspCompleted && isDistributorCompleted

  const showStepEstimate = isFullyConnected && model !== 'streams' && !isKycApproved
  const showStepConfirmUser = isFullyConnected && !showStepEstimate && (!isConfirmedUser && !isCancelledUser)

  const showStepKycHolding = isFullyConnected && isConfirmedUser && !isKycApproved
  const showStepCancelledHolding = isFullyConnected && isCancelledUser && !isKycApproved
  const isApplicationCompleted = isFullyConnected && isConfirmedUser && isKycApproved && isKycCompleted

  const showMetrics = showStepConfirmUser || showStepKycHolding || isKycApproved
  const showHeaderLarge = showStepConfirmUser || confirming || showStepKycHolding || isApplicationCompleted
  const { artist: { listenersWeekly = 0 } = {} } = dataArtist ?? {}
  const { trackMetrics: { trackCount = 0 } = {} } = dataTrackMetrics ?? {}

  // use the toggled form steps to expand the next step needed to complete
  useEffect(() => {
    if (loading) return
    if (!initialized) return

    if (isMfaRequired(distributorJob)) {
      setExpanded(Step.distributor)
      return
    }

    if (!isDspCompleted && !isDspProcessing) {
      setExpanded(Step.dsp)
      return
    }

    if (!isDistributorCompleted && !isDistributorProcessing) {
      setExpanded(Step.distributor)
      return
    }

    // only attempt expanding DSP without progress if distributor is already linked prior
    if (!isDspCompleted && isDistributorCompleted && isDspProcessing) {
      setExpanded(Step.dsp)
      return
    }

    // Default to KYC step if everything is completed
    setExpanded(Step.kyc)
  }, [progress, initialized, loading, isDspProcessing, isDistributorProcessing])

  const renderButtonText = () => {
    if (isApplicationCompleted) {
      return 'Download App'
    }

    if (showStepKycHolding || isKycApproved) {
      return ''
    }

    return 'Submit Application'
  }

  const onContactUs = (eventName: string) => {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${eventName}`
  }

  const renderDspText = () => {
    if (unlinkable) {
      return (<Typography>
        {'Looks like your Spotify Team invites are turned off. '}
        <Link
          color="secondary"
          underline="hover"
          target="_blank"
          onClick={() => onContactUs('SfA invites off - Contact Us')}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {'Please reach out to us.'}
        </Link >
      </Typography>)
    }
    return 'We’ve requested access to your Spotify for artists. Confirm below once approved.'
  }

  return (
    <Container disableGutters maxWidth={false}>
      {expanded &&
        <ApplicationLayout
          step={isKycApproved ? 'kyc' : 'application'}
          headerSize={showHeaderLarge ? 'large' : 'small'}
          spotifyArtistId={application?.spotifyArtistId}
          onClick={() => undefined}
          ctaText={renderButtonText()}
          ctaOnClick={() => showStepConfirmUser ? onClickConfirmApplication() : onLaunchApp()}
          ctaDisabled={loading || (!showStepConfirmUser && !isApplicationCompleted) || confirming}
          ctaHidden={!renderButtonText()}>
          <ArtistStatic
            model={model}
            artist={artist}
            loading={loadingArtistSpotify}
            indeterminate={showStepEstimate}
            total={total}
            realtime={isFullyConnected ? prediction : undefined}
            size={showHeaderLarge ? 'large' : 'small'} />
          <div style={styles.form}>
            <AccordionBubble
              stepIndex={0}
              stepCount={totalStepCount}
              hide={false}
              status={listenersWeekly && showMetrics
                ? `${listenersWeekly} weekly listeners`
                : formatDspProgress(dspJob)}
              loading={isDspProcessing}
              summary={'Confirm access to Spotify for Artists'}
              question={renderDspText()}
              error={isErrored(dspJob)}
              completed={isDspCompleted}
              disabled={isDspCompleted}
              collapsible={false}
              expanded={expanded === Step.dsp && !isDspProcessing && !isDspCompleted}
              onChange={() => !isDspProcessing ? setExpanded(Step.dsp) : undefined}>
              <DspStep
                unlinkable={unlinkable}
                application={application as CashflowApplication}
                requestedState={[requested, setRequested]} />
            </AccordionBubble>
            <AccordionBubble
              stepIndex={1}
              stepCount={totalStepCount}
              hide={expanded === Step.dsp && !isDspProcessing}
              status={trackCount && showMetrics ?
                `${trackCount} Tracks Matched`
                : formatProgress(distributorJob)}
              loading={isDistributorProcessing}
              completed={isDistributorCompleted}
              error={isErrored(distributorJob)}
              disabled={isDistributorCompleted}
              collapsible={false}
              question={`Enter your ${distributorName} credentials to link us to your account.`}
              summary={`Connect to ${distributorName}`}
              expanded={expanded === Step.distributor && !isDistributorProcessing && !isDistributorCompleted}
              onChange={() => setExpanded(Step.distributor)}>
              <DistributorStep application={application as CashflowApplication} />
            </AccordionBubble>
            <AccordionBubble
              stepIndex={2}
              stepCount={totalStepCount}
              hide={!isConfirmedUser || !isKycApproved}
              loading={isKycProcessing}
              status={formatStatus(kycStatus, expectedStatus)}
              question={'Confirm your identity to open your account.'}
              summary={'Complete Identity Check'}
              completed={isKycCompleted}
              error={false}
              disabled={isKycCompleted}
              collapsible={false}
              expanded={expanded === Step.kyc && !isKycProcessing && !isKycCompleted}
              onChange={() => setExpanded(Step.kyc)}>
              {kycStatus !== 'AwaitingDocuments' && <KycStep />}
              {kycStatus === 'AwaitingDocuments' && <DocumentationStep uploadUrl={uploadUrl} />}
            </AccordionBubble>
          </div>
          {showStepEstimate &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                We are calculating your Cash Flow estimate, this can take some time
              </Typography>
            </div>
          }
          {showStepConfirmUser &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Do you want to proceed with your Cash Flow application?
              </Typography>
              <div onClick={() => onClickCancelApplication()}>
                <Typography
                  variant="body2" align="center" style={{
                    paddingTop: '0.25em', color: colors.greyLink, textDecorationLine: 'underline', cursor: 'pointer'
                  }}>
                  Cancel Application
                </Typography>
              </div>
            </div>
          }
          {isDistributorProcessing && isDspProcessing &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Hang tight.
                <Typography variant="body2" align="center" style={{ paddingTop: 12 }}>
                  This may take several minutes.
                </Typography>
              </Typography>
            </div>
          }
          {showStepCancelledHolding &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Cash Flow application cancelled.
              </Typography>
            </div>
          }
          {showStepKycHolding &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Congratulations!
                <Typography variant="body2" align="center" style={{ paddingTop: 12 }}>
                  Your Cash Flow application has been submitted. We&apos;ll reach out with next steps.
                </Typography>
              </Typography>
            </div>
          }
          {isKycProcessing &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Verifying...
              </Typography>

              {isKycStatusReviewing &&
                <>
                  <Typography variant="body2" align="center" style={{ paddingTop: '0.25em' }}>
                    We have received your documents and are reviewing them, this can take some time.
                  </Typography>
                  <Typography variant="body2" align="center" style={{ paddingTop: '1em' }}>
                    We&apos;ll be in touch once it&apos;s complete.
                  </Typography>
                </>
              }

              {!isKycStatusReviewing &&
                <Typography variant="body2" align="center" style={{ paddingTop: '0.25em' }}>
                  Sit tight while we verify your identity.
                </Typography>
              }
            </div>
          }
          {isApplicationCompleted &&
            <div style={styles.info}>
              <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
                Congratulations!
                <Typography variant="body2" align="center" style={{ paddingTop: '0.25em' }}>
                  Launch the HIFI App to activate Cash Flow.
                </Typography>
              </Typography>
            </div>
          }
        </ApplicationLayout>
      }
    </Container>
  )
}
