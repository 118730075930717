export const Assets = {
  confirmRequest: './assets/confirm-request@3x.png',
  initialRequest: './assets/initial-request@3x.png',
  buttonArrow: './assets/Button-arrow.png',
  hifiLogo: './assets/hifi_logo.png',
  hifiLogoBlack: './assets/hifi_logo_black.svg',
  cardBlack: './assets/hifi_card_black.png',
  cardPink: './assets/hifi_card_pink.png',
  cardGold: './assets/hifi_card_gold.png',
  cardShadow: './assets/hifi_card_shadow.png',
  cardBack: './assets/hifi_card_back.png',
  cardAngle1: './assets/hifi_card_angle1.png',
  cardAngle2: './assets/hifi_card_angle2.png',
  goldTexture: './assets/gold_texture.png',
  sparkline: './assets/sparkline.png',
  spectrumAnalyzer: './assets/spectrum_analyzer.gif',
}
