import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { SyntheticEvent } from 'react'
import { useTheme } from '@mui/styles'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'

type AccordionStepProps = {
  stepIndex: number
  stepCount: number
  hide: boolean
  question: string
  expanded: boolean
  collapsible: boolean
  onChange: (event: SyntheticEvent, expanded: boolean) => void
  summary: string
  submitted: boolean
  error: boolean
  children: any
}

export const AccordionStep = ({
  stepIndex,
  stepCount,
  hide,
  question,
  expanded,
  collapsible,
  onChange,
  summary,
  submitted,
  error,
  children
}: AccordionStepProps) => {
  const theme = useTheme()
  const palette = submitted ? 'primary' : 'secondary'

  return (
    <Accordion
      expanded={expanded}
      onChange={submitted ? () => undefined : onChange}
      disableGutters
      sx={{
        display: hide ? 'none' : undefined,
        border: 1,
        borderColor: `${palette}.main`,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&.Mui-expanded': {
          border: 'none'
        },
        '&:not(.Mui-expanded) + .MuiAccordion-root': {
          borderTop: 0
        },
        '&.MuiAccordion-root:has(+ .Mui-expanded)': {
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius
        },
        '&.Mui-expanded + .MuiAccordion-root': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius
        }
      }}>
      <AccordionSummary
        expandIcon={submitted ? null : <EditIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />}
        sx={{
          display: expanded ? 'none' : undefined,
          minHeight: '2rem',
          '& .MuiAccordionSummary-content': {
            margin: 0,
            minWidth: 0
          }
        }}>
        {error && <Typography variant="body1" noWrap color={palette} fontWeight="bold">&#x2022; {summary}</Typography>}
        {!error && <Typography variant="body1" noWrap color={palette}>{summary}</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="caption">Step {stepIndex + 1} of {stepCount}</Typography>
          </Grid>
          <Grid item>
            {collapsible &&
              <IconButton disableRipple sx={{ padding: '0 2px 0 0' }} onClick={(e) => onChange(e, false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          </Grid>
        </Grid>
        <Typography variant="body1">{question}</Typography>
        <div style={{ marginTop: '1rem' }}>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
