import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useState, useEffect } from 'react'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { useMuiInputSize } from '../../../global/dimensions'
import { SocialSecurityField } from '../shared/SocialSecurityField'
import { OccupationField } from '../shared/OccupationField'
import { DateOfBirthField } from '../shared/DateOfBirthField'
import { EmailField } from '../shared/EmailField'
import { AddressFields } from '../shared/AddressFields'

export const OfficerFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const { control, register, formState: { errors } } = form

  const [step, setStep] = useState(IdentityStepBusiness.Officer)
  const inputSize = useMuiInputSize()

  useEffect(() => {
    setPageFields([
      'officer.firstName', 'officer.lastName', 'officer.dob',
      'officer.ssn', 'officer.occupation', 'officer.email', 'officer.address'
    ])
  }, [])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.Officer)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.Officer}
        collapsible
        error={
          !!errors.officer?.firstName || !!errors.officer?.lastName || !!errors.officer?.dob ||
          !!errors.officer?.ssn || !!errors.officer?.occupation
        }
        question="Provide your personal details"
        summary="Officer"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.Officer)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('officer.firstName', { required: true })}
              size={inputSize}
              autoComplete="given-name"
              label="First Name"
              error={!!errors.officer?.firstName}
              fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register('officer.lastName', { required: true })}
              size={inputSize}
              autoComplete="family-name"
              label="Last Name"
              error={!!errors.officer?.lastName}
              fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateOfBirthField
              control={control}
              name="officer.dob"
              error={!!errors.officer?.dob}
              size={inputSize} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SocialSecurityField
              register={register}
              name="officer.ssn"
              error={!!errors.officer?.ssn}
              size={inputSize} />
          </Grid>

          <Grid item xs={12}>
            <OccupationField
              name="officer.occupation"
              error={!!errors.officer?.occupation}
              size={inputSize}
              control={control} />
          </Grid>

        </Grid>
      </AccordionBubble>

      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.OfficerContact)}
        stepCount={steps.length}
        expanded={step === IdentityStepBusiness.OfficerContact}
        collapsible
        error={!!errors.officer?.email || !!errors.officer?.address}
        question="Provide your personal contact details"
        summary="Officer Contact"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => setStep(IdentityStepBusiness.OfficerContact)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmailField
              register={register}
              name="officer.email"
              error={!!errors.officer?.email}
              size={inputSize} />
          </Grid>
          <AddressFields
            basePath="officer.address"
            form={form}
            errors={errors.officer?.address}
            inputSize={inputSize} />
        </Grid>
      </AccordionBubble>
    </>
  )
}
