import { Assets } from '../../../assets'
import { ApacheLicense } from '../../../pages/License/ApacheLicense'
import { MitLicense } from '../../../pages/License/MitLicense'
import { Bsd2License } from '../../../pages/License/Bsd2License'
import { Bsd3License } from '../../../pages/License/Bsd3License'
import { IscLicense } from '../../../pages/License/IscLicense'
import { colors } from '../../../global/colors'
import { getLicenseUrl } from '../../../global/license/generators'
import { ProductLicenseConfig } from '../../../global/license/types'

const styles = {
  logoSmall: {
    pointerEvents: 'none',
    height: '2.8em',
  } as any,
  title: {
    letterSpacing: -0.5,
    color: colors.pink500,
    display: 'inline',
    fontSize: '3.75em',
    fontWeight: 900,
  },
  link: {
    color: colors.pink500,
    fontWeight: 600
  }
}

export const ProductLicenses = ({ product }: { product: ProductLicenseConfig }) => {
  const licenses = new Set(product.dependencies.map((d: any) => d.licenses))

  return (
    <div>
      <p>
        <img src={Assets.hifiLogo} alt="logo" style={styles.logoSmall} />
        <h2 style={styles.title}>
          &nbsp;{product.title}
        </h2>
      </p>

      <h1>
        HIFI Open Source Notice
      </h1>

      <p>
        THE FOLLOWING SETS FORTH ATTRIBUTION NOTICES FOR THIRD PARTY
        SOFTWARE THAT MAY BE CONTAINED IN PORTIONS OF THE {product.name}.
      </p>

      <ul>
        {product.dependencies.map((license: any) => {
          const {
            name,
            version,
            repository,
            licenses: licenseType
          } = license

          return (
            <li key={name}>
              <strong>Dependency</strong>: <a
                style={styles.link}
                href={repository}
                target="_blank"
                rel="noreferrer">{name}</a>
              <ul>
                <li><strong>Version </strong>{version}</li>
                <li><strong>License </strong> <a
                  style={styles.link}
                  href={getLicenseUrl(licenseType)}
                  target="_blank"
                  rel="noreferrer">{licenseType}</a></li>
              </ul>
            </li>
          )
        })}
      </ul>

      {licenses.has('Apache-2.0') && <ApacheLicense />}
      {(licenses.has('MIT') || licenses.has('MIT*')) && <MitLicense />}
      {licenses.has('BSD-2-Clause') && <Bsd2License />}
      {licenses.has('BSD-3-Clause') && <Bsd3License />}
      {licenses.has('ISC') && <IscLicense />}

    </div>
  )
}
