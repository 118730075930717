// TODO: move into common

export const colors = {
  blue500: '#006ae3',
  background: '#e6e6e6',
  darkModalBackground: 'rgba(0,0,0,0.7)',
  lightModalBackground: 'rgba(255,255,255,0.7)',
  teal: '#10c192',
  greyLink: '#151517',
  grey200: '#F2F2F2',
  grey300: '#e6e6e6',
  grey400: '#c7c7c7',
  grey450: '#a8a8a8',
  grey500: '#9e9e9e',
  grey600: '#828282',
  grey700: '#6d6c6b',
  greyDark: '#A0A0A0',
  greyAlmost: '#e6e6e6',
  lightBlueGrey: '#b1b3bd',
  offBlack: '#151715',
  altBlack: '#00000099',
  black: '#000000',
  pink100: '#ffe8f5',
  pink200: '#fe98d0',
  pink500: '#ff008c',
  pinkShadow: '#EC358A',
  pinkTransparency: '#FF008C22',
  white: '#ffffff',
  offWhite: '#F8F8F8',
  whiteTransparency: '#ffffffAA',
  transparent: '#00000000'
}
