import { format } from 'date-fns'
import { parsePhoneNumber } from 'libphonenumber-js'

import { Applicant, Officer, Owner } from './types'

import { AddressInput } from '../types'

export const transformApplicant = (person: Applicant) => {
  return {
    firstName: person.firstName,
    lastName: person.lastName,
    dob: format(person.dob ?? 0, 'yyyy-MM-dd'),
    email: person.email,
    address: (person.address as AddressInput),
    ssn: person.ssn.replaceAll('-', ''),
  }
}

export const transformOfficer = (officer: Officer) => {
  return {
    ...transformApplicant(officer),
    occupation: officer.occupation
  }
}

export const transformOwner = (owner: Owner) => {
  return {
    ...transformOfficer(owner),
    phoneNumber: parsePhoneNumber(owner.phoneNumber).number,
    share: owner.share
  }
}
