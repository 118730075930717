import { ScrollTrigger } from 'gsap/ScrollTrigger'

export function normalizeScroll(activate: boolean): void {
  if (activate) {
    ScrollTrigger.normalizeScroll({
      momentum: 1.3 // control the duration of the momentum when flick-scrolling
    })
  } else {
    ScrollTrigger.normalizeScroll(false)
  }
}
