import { gql } from '@apollo/client'

export const SUBMIT_CASHFLOW_WAITLIST_MUTATION = gql`
  mutation CashflowWaitlist($application: CashflowWaitlistApplication) {
    cashflowWaitlist(application: $application) {
      status,
      message
    }
  }
`

