import { useContext } from 'react'

import { ApplicationContext } from './provider'

export const useApplying = () => {
  const { applying } = useContext(ApplicationContext)

  return applying
}

