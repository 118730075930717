import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { UseFormReturn, FieldPath } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { useState, useEffect } from 'react'

import { IdentityStepIndividual, IdentityFormIndividual, STEP_COUNT_INDIVIDUAL } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'
import { useMuiInputSize } from '../../../global/dimensions'
import { SocialSecurityField } from '../shared/SocialSecurityField'
import { IndustryField } from '../shared/IndustryField'
import { DateOfBirthField } from '../shared/DateOfBirthField'
import { EmailField } from '../shared/EmailField'
import { AddressFields } from '../shared/AddressFields'

type DetailsFormPageProps = {
  form: UseFormReturn<IdentityFormIndividual>
  setPageFields: (fields: FieldPath<IdentityFormIndividual>[]) => void
}

export const DetailsFormPage = ({ form, setPageFields }: DetailsFormPageProps) => {
  const {
    control, register, formState: { errors }
  } = form

  const [step, setStep] = useState(IdentityStepIndividual.Personal)
  const inputSize = useMuiInputSize()

  useEffect(() => {
    setPageFields(['firstName', 'lastName', 'dob', 'ssn', 'industry', 'email', 'address'])
  }, [])

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        <AccordionBubble
          stepIndex={IdentityStepIndividual.Personal}
          stepCount={STEP_COUNT_INDIVIDUAL}
          expanded={step === IdentityStepIndividual.Personal}
          collapsible
          error={!!errors.firstName || !!errors.lastName || !!errors.dob || !!errors.ssn || !!errors.industry}
          question="Personal Details"
          summary="Personal Details"
          hide={false}
          ErrorIcon={Warning}
          onChange={() => setStep(IdentityStepIndividual.Personal)}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <TextField
                {...register('firstName', { required: true })}
                size={inputSize}
                autoComplete="given-name"
                label="First Name"
                error={!!errors.firstName}
                fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                {...register('lastName', { required: true })}
                size={inputSize}
                autoComplete="family-name"
                label="Last Name"
                error={!!errors.lastName}
                fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DateOfBirthField
                control={control}
                name="dob"
                error={!!errors.dob}
                size={inputSize} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SocialSecurityField
                register={register}
                name="ssn"
                error={!!errors.ssn}
                size={inputSize} />
            </Grid>

            <Grid item xs={12}>
              <IndustryField
                name="industry"
                error={!!errors.industry}
                size={inputSize}
                control={control} />
            </Grid>

          </Grid>
        </AccordionBubble>

        <AccordionBubble
          stepIndex={IdentityStepIndividual.Contact}
          stepCount={STEP_COUNT_INDIVIDUAL}
          expanded={step === IdentityStepIndividual.Contact}
          collapsible
          error={!!errors.address || !!errors.email}
          question="Contact Information"
          summary="Contact Information"
          hide={false}
          ErrorIcon={Warning}
          onChange={() => setStep(IdentityStepIndividual.Contact)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EmailField
                register={register}
                name={'email'}
                error={!!errors.email}
                size={inputSize} />
            </Grid>
            <AddressFields basePath="address" form={form} errors={errors.address} inputSize={inputSize} />
          </Grid>
        </AccordionBubble>

      </div>
    </>
  )
}
