import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { RouterProvider } from 'react-router'

import { Routes, LocalRoutes } from './routes'
import { Home } from './Home/Home'
import { ApplicationPage } from './Application/ApplicationPage'
import { TermsPage } from './TermsPage'
import { IdentityPage } from './Identity/IdentityPage'
import { ProtectedRoute } from './ProtectedRoute'
import { LicensePage } from './LicensePage'

import { AnnouncementPage } from '../pages/AnnouncementPage'
import { LicensesPage } from '../pages/LicensesPage'

const { PUBLIC_URL, REACT_APP_ENV = '' } = process.env

export const Router = () => {
  const routes: RouteObject[] = [
    { path: Routes.root, element: <Home /> },
    { path: Routes.terms, element: <TermsPage /> },
    { path: Routes.apply, element: <ProtectedRoute><ApplicationPage /></ProtectedRoute> },
    { path: Routes.identity, element: <ProtectedRoute><IdentityPage /></ProtectedRoute> },
    { path: Routes.license, element: <LicensePage /> },
    { path: Routes.announce, element: <AnnouncementPage /> },
    { path: Routes.licenses, element: <LicensePage /> },
  ]

  // routes to aid with SSR page development
  if (['local', 'dev'].includes(REACT_APP_ENV)) {
    routes.push({ path: LocalRoutes.licenses, element: <LicensesPage /> })
  }

  const router = createBrowserRouter(routes, { basename: PUBLIC_URL })

  return <RouterProvider router={router} />
}
