import { SyntheticEvent } from 'react'
import { Controller } from 'react-hook-form'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { convertToDisplayName, VendorSlug } from '@rcrdclub/common-front'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'

import { WaitlistStepProps } from './types'

import { SUPPORTED_DISTRIBUTORS } from '../../../../domain/vendors/constants'
import { InlineAutocomplete } from '../../../components/inputs/InlineAutocomplete'
import { MatchedText } from '../../../components/text/MatchedText'

const styles = {
  option: {
    height: '100%',
  }
}

interface PredefinedOption {
  id: VendorSlug
  label: string
}

const options = SUPPORTED_DISTRIBUTORS.map((d) => ({
  id: d,
  label: convertToDisplayName(d)
})) as PredefinedOption[]

type Option = PredefinedOption | string | null

const filter = createFilterOptions<PredefinedOption>()

export const DistributorStep = ({ control, error }: WaitlistStepProps) => {
  const getAutocompleteValue = (value: VendorSlug | string | null) => {
    if (!value) return null

    return options.find((o) => o.id === value) ?? value
  }

  const handleAutocompleteChange = (onChange: Function, data: Option) => {
    if (typeof data === 'string') {
      onChange(data)
    } else {
      onChange(data?.id ?? null)
    }
  }

  const renderOption = (
    props: any,
    option: PredefinedOption,
    { inputValue, selected }: AutocompleteRenderOptionState
  ) => (
    <ListItemButton selected={selected} sx={styles.option}>
      <ListItemText primary={<MatchedText text={option.label} search={inputValue} />} />
    </ListItemButton>
  )

  return (
    <>
      <Controller
        name="distributor"
        rules={{ required: true }}
        control={control}
        render={({
          field: {
            onChange, value, ref, ...rest
          }
        }) => (
          <InlineAutocomplete<PredefinedOption, false, true>
            {...rest}
            innerRef={ref}
            typography="h6"
            autoFocus={false}
            value={getAutocompleteValue(value)}
            onChange={(e: SyntheticEvent, o: Option) => handleAutocompleteChange(onChange, o)}
            fullWidth
            freeSolo
            resultCount={{ xs: 2, lg: 3 }}
            placeholder="Enter or select your distributor"
            error={!!error}
            options={options}
            optionKey={(o: PredefinedOption) => o.id}
            filterOptions={(options: PredefinedOption[], params: any) => {
              const filtered = filter(options, params)

              const { inputValue } = params

              // suggest the creation of a new value
              const lowerCaseMatch = (a: string, b: string) => a?.toLowerCase() === b?.toLowerCase()
              const isExisting = options.some((option: PredefinedOption) => lowerCaseMatch(option.label, inputValue))

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  id: inputValue,
                  label: `Add "${inputValue}"`,
                })
              }

              return filtered
            }}
            renderOption={renderOption} />
        )} />
    </>
  )
}
