import dependenciesCashflow from './licenses-cashflow.json'
import dependenciesMobile from './licenses-mobile.json'
import dependenciesEnterprise from './licenses-enterprise.json'

type Dependency = {
  name: string
  version: string
  licenses: string
  repository?: string
}

export type ProductLicenseConfig = {
  title: string
  name: string
  dependencies: Dependency[]
}

export const PRODUCT_LICENSES: Record<string, ProductLicenseConfig> = {
  cashflow: {
    title: 'Cash Flow',
    name: 'CASH FLOW WEB APPLICATION',
    dependencies: Object.values(dependenciesCashflow).filter((l) => l.licenses !== 'UNKNOWN')
  },
  mobile: {
    title: 'Mobile App',
    name: 'HIFI MOBILE APP',
    dependencies: Object.values(dependenciesMobile).filter((l) => l.licenses !== 'UNKNOWN')
  },
  enterprise: {
    title: 'Enterprise',
    name: 'ENTERPRISE WEB APPLICATION',
    dependencies: Object.values(dependenciesEnterprise).filter((l) => l.licenses !== 'UNKNOWN')
  }
}
