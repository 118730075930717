import { Controller, Control, FieldValues } from 'react-hook-form'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { makeStyles } from '@mui/styles'

import { WaitlistStepProps } from './types'

import { colors } from '../../../../global/colors'

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: colors.white,
    '& .MuiInputAdornment-root .MuiTypography-root': {
      ...theme.typography.h6,
    },
    '& .MuiOutlinedInput-root': {
      ...theme.typography.h6,
      '&.Mui-focused fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-error fieldset': {
        borderColor: 'error.main'
      }
    }
  }
}))

export const PhoneNumberStep = ({ control, error }: WaitlistStepProps) => {
  const classes = useStyles()

  return (
    <Controller
      name="phoneNumber"
      rules={{ required: true, validate: matchIsValidTel }}
      control={(control as unknown) as Control<FieldValues>}
      render={({ field }) =>
        <MuiTelInput
          {...field}
          autoComplete="tel"
          fullWidth
          className={classes.input}
          defaultCountry="US"
          onlyCountries={['US']}
          forceCallingCode
          disableDropdown
          error={!!error} />} />
  )
}
