import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { BusinessVertical } from '@unit-finance/unit-node-sdk'
import words from 'lodash/words'
import { Controller, Control } from 'react-hook-form'

import { INDUSTRIES } from './types'

type IndustryFieldFieldProps = {
  control: Control<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

export const IndustryField = ({
  control, name, error, size
}: IndustryFieldFieldProps) => {
  return (
    <Controller
      name={name}
      rules={{ required: true }}
      control={control}
      render={({ field }) =>
        <FormControl fullWidth>
          <InputLabel id="industry-label" size={size}>Industry</InputLabel>
          <Select<BusinessVertical>
            {...field}
            fullWidth
            error={error}
            labelId="industry-label"
            label="Industry"
            size={size}>
            {INDUSTRIES.map((industry) =>
              <MenuItem key={industry} value={industry}>
                {words(industry).join(' ').replace('Or', 'or').replace('And', 'and')}
              </MenuItem>)}
          </Select>
        </FormControl>
      } />
  )
}
