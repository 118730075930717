import { useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import makeStyles from '@mui/styles/makeStyles'

import { TextSmall } from '../TextSmall'
import { Assets } from '../../../../assets'

const useStyles = makeStyles((theme) => ({
  section: {
    position: 'relative',
    marginTop: '5vh',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10vh'
    }
  },
  card: {
    position: 'relative',
    display: 'inline-block',
    zIndex: 1
  },
  cardImg: {
    width: '85vw',
    aspectRatio: 'auto 500 / 314'
  },
  payCopy: {
    visibility: 'hidden',
    position: 'absolute',
    top: '50%'
  }
}))

export const PaysRoyalties = () => {
  const classes = useStyles()

  const section = useRef<any>()
  const card = useRef<any>()
  const copy = useRef<any>()

  useLayoutEffect(() => {
    gsap.set(card.current, { xPercent: -80 })
    gsap.set(copy.current, { yPercent: -50, x: 0, xPercent: -100 })

    gsap.timeline({
      scrollTrigger: {
        trigger: section.current,
        end: 'center 30%',
        // immediately animate on smaller viewports
        scrub: ScrollTrigger.isInViewport(section.current, 1) ? false : 1
      }
    })
      .to(card.current, { xPercent: -42 })
      .to(copy.current, { x: '53vw', xPercent: 0 })
  }, [])

  // delay text visibility until after image load
  const showText = () => {
    gsap.from(copy.current, { autoAlpha: 0 })
  }

  return (
    <section ref={section} className={classes.section}>
      <div ref={card} className={classes.card}>
        <img
          src={Assets.cardShadow}
          alt="card shadow"
          className={classes.cardImg}
          style={{
            position: 'absolute', top: '5vw', left: '-5vw', zIndex: -1
          }} />
        <img
          onLoad={showText}
          src={Assets.cardPink}
          alt="card"
          className={classes.cardImg} />
      </div>

      <div ref={copy} className={classes.payCopy}>
        <TextSmall text="Pays your" />
        <TextSmall text="music royalties" />
        <TextSmall text="in real time" />
      </div>
    </section>
  )
}
