import type { UseFormSetValue, Control, UseFormRegister } from 'react-hook-form/dist/types/form'
import type { FieldError } from 'react-hook-form/dist/types/errors'

import { IdentityStatus } from '../../domain/types'

export type ApplicationProgress = {
  dsp: boolean
  distributor: boolean
  estimate: boolean
  confirmUser: boolean
  confirmHifi: boolean
  kyc: boolean
}

export type ApplicationProgressProps = {
  value?: any
  control: Control<ApplicationProgress>
  error?: FieldError
  register?: UseFormRegister<ApplicationProgress>
  setValue?: UseFormSetValue<ApplicationProgress>
}

export const KYC_PENDING_STATES: IdentityStatus[] = [
  IdentityStatus.Pending,
  IdentityStatus.PendingReview,
  IdentityStatus.Canceled,
  IdentityStatus.Denied
]
