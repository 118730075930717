// TODO: drop disable rule when re-enabling animations
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import makeStyles from '@mui/styles/makeStyles'

import { Search } from './Search'

import { LargeTicker } from '../LargeTicker'
import { TextMedium } from '../TextMedium'
import { Assets } from '../../../../assets'
import { colors } from '../../../../global/colors'

export const useStyles = makeStyles(() => ({
  section: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100svh'
  },
  ticker: {
    color: colors.grey400,
    textAlign: 'center'
  },
  centralCardCopy: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center'
  },
  centralCard: {
    width: '68vw'
  },
  centralCardContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%'
  },
  search: {
    position: 'absolute',
    width: '100%'
  }
}))

// acts as a ratio for tween durations
const CARD_PINNED_TWEEN_DURATION = 0.1

export const CentralCards = () => {
  const classes = useStyles()

  const [ticking, setTicking] = useState(false)

  const section = useRef<any>()
  const ticker = useRef<any>()

  const copyYourMoney = useRef<any>()
  const copySave = useRef<any>()
  const copySpend = useRef<any>()
  const copyCashOut = useRef<any>()

  const cardPink = useRef<any>()
  const cardGold = useRef<any>()
  const cardBlack = useRef<any>()
  const cardBack = useRef<any>()

  const search = useRef<any>()

  useLayoutEffect(() => {
    const buildAnimation = () => {
      // hide copy
      // gsap.set([copyYourMoney.current, copySave.current, copySpend.current, copyCashOut.current, search.current], {
      gsap.set([search.current], {
        opacity: 0
      })

      // hide cards
      // gsap.set([cardPink.current, cardGold.current, cardBlack.current], {
      //   xPercent: -50,
      //   yPercent: 100,
      //   y: '50vh'
      // })

      gsap.timeline({
        scrollTrigger: {
          trigger: section.current,
          start: 'center center',
          // end: '+=6000',
          end: '+=300',
          // pins on start point
          pin: true,
          onEnter: () => setTicking(true)
        }
      })
        // .to(copyYourMoney.current, { opacity: 1, duration: 0.05 })
        // .fromTo(ticker.current, { x: '100vw' }, { x: '50vw', xPercent: -50 })
        //
        // // pink card
        // .to(cardPink.current, { yPercent: -50, y: 0 })
        // .to(copyYourMoney.current, { opacity: 0 }, '<')
        // .to(ticker.current, { opacity: 0 }, '<')
        // .to({}, { duration: CARD_PINNED_TWEEN_DURATION }) // pauses animation
        // .to(cardPink.current, { yPercent: -100, y: '-50vh' })
        // .to(copySave.current, { opacity: 1 }, '<')
        //
        // // gold card
        // .to(cardGold.current, { yPercent: -50, y: 0 })
        // .to(copySave.current, { opacity: 0 }, '<')
        // .to({}, { duration: CARD_PINNED_TWEEN_DURATION }) // pauses animation
        // .to(cardGold.current, { yPercent: -100, y: '-50vh' })
        //
        // // black card entry
        // .to(copySpend.current, { opacity: 1 }, '<')
        // .to(cardBlack.current, { yPercent: -50, y: 0 })
        // .to(copySpend.current, { opacity: 1 }, '<')
        //
        // // card splitting
        // .to(cardBlack.current, { xPercent: -65 })
        // .to(cardBack.current, { xPercent: 29, yPercent: -10 }, '<')
        // .to({}, { duration: CARD_PINNED_TWEEN_DURATION }) // pauses animation
        //
        // // black card exit
        // .to(copySpend.current, { opacity: 0 }, '<')
        // .to(cardBlack.current, { yPercent: -100, y: '-50vh' })
        // .to(copyCashOut.current, { opacity: 1 }, '<')
        // .to(copyCashOut.current, { opacity: 0, duration: 0 })
        .to(search.current, { opacity: 1 })
    }

    const context = gsap.context(buildAnimation)

    return () => {
      context.revert()
    }
  }, [])

  return (
    <section ref={section} className={classes.section}>
      {/*<LargeTicker*/}
      {/*  className={classes.ticker}*/}
      {/*  tickerRef={ticker}*/}
      {/*  ticking={ticking}*/}
      {/*  start={452}*/}
      {/*  end={989} />*/}

      {/*<div ref={copyYourMoney} className={classes.centralCardCopy}>*/}
      {/*  <TextMedium text="It's your money" />*/}
      {/*</div>*/}

      {/*<div ref={copySave} className={classes.centralCardCopy}>*/}
      {/*  <TextMedium text="Save" />*/}
      {/*</div>*/}

      {/*<div ref={copySpend} className={classes.centralCardCopy}>*/}
      {/*  <TextMedium text="Spend" />*/}
      {/*</div>*/}

      {/*<div ref={copyCashOut} className={classes.centralCardCopy}>*/}
      {/*  <TextMedium text="Cash it out" />*/}
      {/*</div>*/}

      {/*<div ref={cardPink} className={classes.centralCardContainer}>*/}
      {/*  <img*/}
      {/*    src={Assets.cardPink}*/}
      {/*    alt="card"*/}
      {/*    className={classes.centralCard} />*/}
      {/*</div>*/}

      {/*<div ref={cardGold} className={classes.centralCardContainer}>*/}
      {/*  <img*/}
      {/*    src={Assets.cardGold}*/}
      {/*    alt="card"*/}
      {/*    className={classes.centralCard} />*/}
      {/*</div>*/}

      {/*<div ref={cardBlack} className={classes.centralCardContainer}>*/}
      {/*  <img*/}
      {/*    style={{ zIndex: 1 }}*/}
      {/*    src={Assets.cardBlack}*/}
      {/*    alt="card"*/}
      {/*    className={classes.centralCard} />*/}

      {/*  <img*/}
      {/*    ref={cardBack}*/}
      {/*    style={{ position: 'absolute' }}*/}
      {/*    src={Assets.cardBack}*/}
      {/*    alt="card"*/}
      {/*    className={classes.centralCard} />*/}
      {/*</div>*/}

      <div ref={search} className={classes.search}>
        <Search />
      </div>
    </section>
  )
}
