import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { WaitlistStepProps } from './types'

import { ArtistType } from '../../../../domain/types'
import { colors } from '../../../../global/colors'
import { ARTIST_TYPE_DISPLAY_NAMES } from '../../../../domain/waitlist/constants'

const style = {
  backgroundColor: colors.white,
  borderRadius: 1,
  padding: 0,
  '& .MuiListItemButton-root': {
    paddingTop: '.65em',
    paddingBottom: '.65em'
  }
}

export const ArtistTypeStep = ({ value, setValue }: WaitlistStepProps) => {
  const handleClick = (artistType: ArtistType) => setValue('artistType', artistType)

  const renderItem = (artistType: ArtistType) => (
    <ListItemButton
      divider
      selected={artistType === value}
      onClick={() => handleClick(artistType)}>
      <ListItemText
        primary={ARTIST_TYPE_DISPLAY_NAMES[artistType]}
        primaryTypographyProps={{ variant: 'h6' }} />
    </ListItemButton>
  )

  return (
    <List sx={style}>
      {renderItem(ArtistType.SoloArtist)}
      {renderItem(ArtistType.Band)}
    </List>
  )
}
