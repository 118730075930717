import { BusinessVertical, State } from '@unit-finance/unit-node-sdk'

import { IdentityFormShared } from '../shared/types'
import { Applicant } from '../../../domain/identity/types'

export enum IdentityStepIndividual {
  ApplicantType,
  Personal,
  Contact,
  ElectronicDisclosure,
  Terms,
  CashflowAgreement
}

export type IdentityFormIndividual = {
  industry: BusinessVertical
} & Applicant & IdentityFormShared

export const DEFAULT_FORM_VALUES_INDIVIDUAL: IdentityFormIndividual = {
  firstName: '',
  lastName: '',
  email: '',
  dob: null,
  ssn: '',
  industry: 'ArtsEntertainmentAndRecreation',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '' as State,
    postalCode: '',
    country: 'US'
  },
  confirmedCashflowAgreement: false,
  confirmedElectronicDisclosure: false,
  confirmedTerms: false
}

export const STEP_COUNT_INDIVIDUAL = Object.keys(IdentityStepIndividual).length / 2
