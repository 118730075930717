import Grid from '@mui/material/Unstable_Grid2'

import { colors } from '../global/colors'

const content = [
  { title: 'CardHolder Terms & Conditions', body: 'stub' },
  { title: 'Deposit Terms & Conditions', body: 'stub' },
  { title: 'Cash Flow Terms & Conditions', body: 'stub' },
  { title: 'Privacy Policy', body: 'stub' },
  { title: 'Consent to Electronic Disclosures', body: 'stub' },
  { title: 'Client Terms of Service', body: 'stub' },
  { title: 'Debit Card Disclosure', body: 'stub' }
]

export const TermsPage = () => {
  return (
    <Grid
      container
      style={{
        height: '100dvh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Grid
        xs={11}
        sm={8}
        lg={6}
        sx={{
          position: 'relative',
          backgroundColor: colors.grey200,
          padding: 3,
          overflowY: 'auto',
          margin: 2,
          overscrollBehavior: 'none'
        }}>

        {content.map((c) => (
          <div key={c.title} id={c.title.replaceAll(' ', '-')} style={{ paddingBottom: 25 }}>
            <h2 style={{ margin: 0, fontSize: 32 }}>{c.title}</h2>
            {c.body}
          </div>
        ))}

        <div style={{ marginTop: '0' }}>
          <div style={{ textAlign: 'center' }}>
            <span style={{
              paddingTop: 12, color: colors.grey700, fontSize: 14, fontStyle: 'italic'
            }}>
              HIFI is a financial technology company and is not a bank. Banking services provided
              by Piermont Bank; Member FDIC. The HIFI Visa&reg; Debit Card is issued by Piermont Bank
              pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit
              cards are accepted.
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
