import { CountUp } from 'use-count-up'
import {
  useLayoutEffect, useState, useRef
} from 'react'
import { gsap } from 'gsap'
import makeStyles from '@mui/styles/makeStyles'

import { TextSmall } from '../TextSmall'
import { Assets } from '../../../../assets'

const useStyles = makeStyles(() => ({
  section: {
    position: 'relative',
    marginTop: '5vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  sparkline: {
    position: 'absolute',
    top: '20%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '90vw'
  },
  spectrumAnalyzer: {
    position: 'absolute',
    top: '30%',
    left: 0,
    pointerEvents: 'none',
    width: '100vw',
    aspectRatio: 'auto 1920 / 1080'
  },
  mask: {
    backgroundColor: 'white'
  },
  smallTicker: {
    position: 'relative',
    fontSize: '18vw',
    fontWeight: 'bold',
    margin: 0
  },
  balanceCopy: {
    textAlign: 'left'
  }
}))

export const CentralTicker = () => {
  const classes = useStyles()

  const section = useRef<any>()
  const sparkline = useRef<any>()
  const balanceCopy = useRef<any>()

  const [ticking, setTicking] = useState(false)

  useLayoutEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: section.current,
        start: 'top 50%',
        onEnter: () => setTicking(true)
      }
    })
      .fromTo(balanceCopy.current, { yPercent: 40 }, { yPercent: 0 })

    gsap.fromTo(
      sparkline.current,
      {
        clipPath: 'inset(0 100% 0 0)'
      },
      {
        clipPath: 'inset(0 0% 0 0)',
        scrollTrigger: {
          trigger: sparkline.current,
          start: 'top 60%',
          end: 'bottom top'
        },
      }
    )
  }, [])

  return (
    <section ref={section} className={classes.section}>
      <img src={Assets.spectrumAnalyzer} className={classes.spectrumAnalyzer} alt="spectrum analyzer" />
      <img ref={sparkline} src={Assets.sparkline} className={classes.sparkline} alt="sparkline" />

      <div>
        <CountUp
          isCounting={ticking}
          start={142.38}
          end={874.95}
          duration={180}
          easing="linear"
          onComplete={() => ({ shouldRepeat: true })}
          formatter={(value: number) =>
            <h1 className={classes.smallTicker}>
              {value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </h1>
          } />

        <div ref={balanceCopy} className={classes.balanceCopy}>
          <TextSmall text="Your cash balance" />
          <TextSmall text="increases as fans" />
          <TextSmall text="stream your music" />
        </div>
      </div>
    </section>
  )
}
