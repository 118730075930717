import FadeIn from 'react-fade-in'

export const FadeInOptional = ({
  delay = 100, children,
}: { delay: number, children: any }) => {
  return (!delay ? children : <FadeIn delay={delay}>
    {children}
  </FadeIn>
  )
}
