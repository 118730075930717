import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { Occupation } from '@unit-finance/unit-node-sdk'
import words from 'lodash/words'
import { Controller, Control } from 'react-hook-form'

import { OCCUPATIONS } from './types'

type OccupationFieldProps = {
  control: Control<any>
  name: string
  error: boolean
  size: 'small' | undefined
}

export const OccupationField = ({
  control, name, error, size
}: OccupationFieldProps) => {
  return (
    <Controller
      name={name}
      rules={{ required: true }}
      defaultValue="EntertainmentSportsArtsOrMedia"
      control={control}
      render={({ field }) =>
        <FormControl fullWidth>
          <InputLabel id="occupation-label" size={size}>Occupation</InputLabel>
          <Select<Occupation>
            {...field}
            fullWidth
            error={error}
            labelId="occupation-label"
            label="Occupation"
            size={size}>
            {OCCUPATIONS.map((occupation) =>
              <MenuItem key={occupation} value={occupation}>
                {words(occupation).join(' ').replace('Or', 'or').replace('And', 'and')}
              </MenuItem>)}
          </Select>
        </FormControl>
      } />
  )
}
