import { useEffect } from 'react'

import { WaitlistStepProps } from './types'

import { useArtist } from '../../../../domain/artist/hooks'
import { SearchInput } from '../../../components/inputs/SearchInput'

export const ArtistSearchStep = ({ register, setValue, error }: WaitlistStepProps) => {
  const [artist] = useArtist()

  register('spotifyArtistId', { required: true })

  const updateArtistId = () => setValue('spotifyArtistId', artist?.id ?? '')

  useEffect(() => {
    updateArtistId()
  }, [artist?.id])

  return <SearchInput
    label="Artist name"
    typography="h6"
    autoFocus={false}
    // Always force an update of the artistId on input blur.
    // This ensures the `watch` logic in `ApplicationForm.tsx`
    // runs, even when the same artist is re-selected.
    onBlur={updateArtistId}
    error={!!error} />
}
