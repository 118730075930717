import createTheme, { Theme } from '@mui/material/styles/createTheme'
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes'

import { colors } from '../global/colors'

// fixes theme TS type issues
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

export const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: colors.black
    },
    secondary: {
      main: colors.pink500
    },
    background: {
      default: colors.background
    },
    success: {
      main: colors.teal
    },
    error: {
      main: colors.pink500
    },
    action: {
      disabledBackground: colors.pink200,
      selected: colors.pink500,
      hover: colors.pink200,
      focus: colors.pink200
    }
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: colors.white,
            backgroundColor: colors.pink200,
          },
          '&.Mui-selected': {
            backgroundColor: colors.pink500,
            color: colors.white,
            '&:hover': {
              backgroundColor: colors.pink200,
            }
          }
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}), {
  factor: 3
})
