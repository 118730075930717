import { UseFormReturn, FieldPath, FieldValues } from 'react-hook-form'

/**
 * Forces validation of every pageField, regardless of whether it's been touched.
 */
export const validate = async <T extends FieldValues, >(pageFields: FieldPath<T>[], form: UseFormReturn<T>) => {
  const { trigger, getValues, setValue } = form

  // trigger forces validation of all fields (not just touched ones)
  const valid = await trigger(pageFields)

  // Mark all fields as touched so that any subsequent
  // autofilled values will get validated immediately.
  for (const field of pageFields) {
    const value = getValues(field)
    setValue(field, value, { shouldTouch: true })
  }

  return valid
}

/**
 * Checks to see if all *touched* fields are valid.
 */
export const isValid = <T extends FieldValues, >(pageFields: FieldPath<T>[], form: UseFormReturn<T>) => {
  const { getFieldState, formState } = form

  const fieldStates = pageFields.map((field) => ({
    field,
    state: getFieldState(field, formState)
  }))

  return fieldStates.every((f) => !f.state.invalid)
}
