/**
 * Searches `input` for the `search` substring (case-insenstive)
 * and returns an array with any portion of `input` before `search`,
 * `search` (if found) and any remainder of `input` after.
 *
 * @param input - The target string to search.
 * @param search - The string to search for.
 */
export const splitMatch = (input: string, search: string) => {
  const regex = new RegExp(`(?<before>.*)(?<match>${search})(?<after>.*)`, 'i')
  const result = regex.exec(input)

  if (!result?.groups) return [input, '', '']

  const { before, match, after } = result.groups

  return [before, match, after]
}
