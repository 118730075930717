import TextField from '@mui/material/TextField'
import type { UseFormRegister } from 'react-hook-form/dist/types/form'

type SocialSecurityFieldProps = {
  register: UseFormRegister<any>
  name: string
  error: boolean
  size: 'small' | 'medium' | undefined
}

export const SocialSecurityField = ({
  register, name, error, size
}: SocialSecurityFieldProps) => {
  return (
    <TextField
      {...register(name, { required: true, pattern: /^\d{3}-?\d{2}-?\d{4}$/ })}
      size={size}
      label="Social Security Number"
      inputProps={{ inputMode: 'numeric' }}
      error={error}
      fullWidth />
  )
}
