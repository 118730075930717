import { useForm, FieldPath } from 'react-hook-form'
import { useState, useEffect } from 'react'

import { DetailsFormPage } from './DetailsFormPage'
import { IdentityFormIndividual, DEFAULT_FORM_VALUES_INDIVIDUAL, STEP_COUNT_INDIVIDUAL } from './types'

import { TermsFormPage } from '../shared/TermsFormPage'
import { PaginatedForm } from '../../components/forms/PaginatedForm'
import { ApplicantTypePage } from '../shared/ApplicantTypePage'
import { ApplicationLayout } from '../../components/layouts/ApplicationLayout'
import { useVerifyIdentityIndividual, useVerifyHandler } from '../../../domain/identity/hooks'
import { FormProps } from '../shared/types'
import { isValid, validate } from '../../../domain/identity/validators'
import { formatFormCta } from '../../../domain/identity/formatters'

export const IndividualForm = ({ applicantType, setApplicantType, terms }: FormProps) => {
  const verifyIdentity = useVerifyIdentityIndividual()
  const { verifyHandler, verifyErrorHandler, error } = useVerifyHandler(verifyIdentity)

  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [pageValid, setPageValid] = useState(true)
  const [pageFields, setPageFields] = useState<FieldPath<IdentityFormIndividual>[]>([])

  const finalPage = page + 1 === pageCount

  const form = useForm<IdentityFormIndividual>({
    // NOTE: `onTouched` means that fields will first be validated when onBlur()
    // is called on them, then subsequently by any onChange() events.
    mode: 'onTouched',
    defaultValues: {
      ...DEFAULT_FORM_VALUES_INDIVIDUAL
    }
  })

  const { handleSubmit, formState } = form
  const { isSubmitting } = formState
  const fieldsValid = isValid(pageFields, form)

  useEffect(() => {
    setPageValid(true)
  }, [page])

  useEffect(() => {
    setPageValid(fieldsValid)
  }, [fieldsValid])

  const submit = handleSubmit(verifyHandler, verifyErrorHandler)

  const handleCtaClick = async () => {
    const valid = await validate(pageFields, form)
    setPageValid(valid)

    if (!valid) return

    if (finalPage) {
      await submit()
      return
    }

    setPage(page + 1)
  }

  return (
    <ApplicationLayout
      step="kyc"
      headerSize="small"
      ctaText={formatFormCta(finalPage, isSubmitting)}
      ctaDisabled={!pageValid || isSubmitting}
      ctaOnClick={handleCtaClick}>
      <PaginatedForm activePageIndex={page} setPageCount={setPageCount} onBack={() => setPage(page - 1)}>
        <ApplicantTypePage
          stepCount={STEP_COUNT_INDIVIDUAL}
          applicantType={applicantType}
          setApplicantType={setApplicantType}
          setPageFields={setPageFields} />
        <DetailsFormPage form={form} setPageFields={setPageFields} />
        <TermsFormPage
          applicantType={applicantType}
          terms={terms}
          stepCount={STEP_COUNT_INDIVIDUAL}
          form={form}
          setPageFields={setPageFields}
          error={error} />
      </PaginatedForm>
    </ApplicationLayout>
  )
}
