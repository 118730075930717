import {
  useRef,
  useState
} from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { useMuiInputSize } from '../../../global/dimensions'

const styles = {
  input: {
    '&.MuiInputBase-root.MuiOutlinedInput-root': {
      fontSize: 22,
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
      borderColor: 'white',
      borderWidth: 1,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    },
    borderRadius: 2,
    backgroundColor: 'white',
    '& input': {
      textAlign: 'center'
    }
  },
}

export const InputGeneral = ({
  onChange,
  placeholder,
  error,
  style = {},
  disabled = false,
  value,
  type,
}: {
  placeholder: string,
  onChange?: Function,
  error: string | undefined,
  disabled?: boolean,
  value?: string,
  style?: any,
  type?: string,
}) => {
  const input = useRef<any>(null)
  const stack = useRef(null)
  const [containerStyle] = useState({})
  const inputSize = useMuiInputSize()

  const onInputChange = ({ target: { value } }: any) => {
    if (onChange) {
      onChange(value)
    }

    // only run on real user events (not on initialization)
    if (value) {
      input.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const onFocus = () => {
    setTimeout(() => input.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)
  }

  return (
    <Box sx={containerStyle}>
      <Stack spacing={2} ref={stack}>
        <TextField
          ref={input}
          style={style}
          size={inputSize}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={true}
          autoComplete=''
          onChange={onInputChange}
          onFocus={onFocus}
          InputProps={{
            type: type ?? 'text',
            sx: {
              ...styles.input,
            }
          }}
          error={!!error} />
      </Stack>
    </Box>
  )
}
