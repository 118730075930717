import { gql } from '@apollo/client'

export const QUERY_CASHFLOW_KYC_FORM = gql`
  query CashflowKycForm {
    cashflowKycForm {
      status,
      url
    }
  }
`

export const MUTATION_CONFIRM_APPLICATION = gql`
  mutation ConfirmApplication($confirm: Boolean!) {
    confirmApplication(confirm: $confirm) {
      status
      message
    }
  }
`
