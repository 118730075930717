import Container from '@mui/material/Container'

import { WaitlistForm } from './Waitlist/WaitlistForm'
import { ProductOverview } from './ProductOverview/ProductOverview'

import { useApplying } from '../../domain/application/hooks'

export const Home = () => {
  const [applying] = useApplying()

  return (
    <Container disableGutters maxWidth={false}>
      {applying && <WaitlistForm />}
      {!applying && <ProductOverview />}
    </Container>
  )
}
