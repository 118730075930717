import { ProductLicenses } from '../ui/components/license/ProductLicenses'
import { PRODUCT_LICENSES } from '../global/license/types'

export const LicensesPage = () =>
  <div style={{ padding: 48, maxWidth: 650 }}>
    <ProductLicenses product={PRODUCT_LICENSES.cashflow} />
    <ProductLicenses product={PRODUCT_LICENSES.mobile} />
    <ProductLicenses product={PRODUCT_LICENSES.enterprise} />
  </div>

export default LicensesPage
