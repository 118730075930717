/* eslint-disable import/no-named-as-default */
import { install, gtag } from 'ga-gtag'
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel'
import posthog from 'posthog-js'

// TODO: change IDs
export function initAnalytics(): void {
  try {
    install('UA-29517524-2')

    TagManager.initialize({
      gtmId: 'GTM-M3BXV5Q'
    })

    ReactPixel.init('1404843106463596', undefined, {
      autoConfig: true,
      debug: false
    })

    ReactPixel.pageView()
  } catch (error) {
    console.error('[initAnalytics]', error)
  }

  posthog.init(
    'phc_8vG5eSZJMDnSsobmUeHM3CYDUPC1NmqZtmSu9YtQXXy',
    { api_host: 'https://app.posthog.com', autocapture: true }
  )
}

export function trackEvent(event: string, data = {}): void {
  gtag('event', event, data)

  // TagManager.dataLayer({
  //   dataLayer: {
  //     event,
  //     ...data
  //   }
  // })

  ReactPixel.trackCustom(event, data)
}
