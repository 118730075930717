import React from 'react'
import ReactDOM from 'react-dom'

import { SENTRY } from './global/envs'
import { initSentry } from './global/sentry'
import { App } from './ui/App'
import { initAnalytics } from './global/analytics'
import { initReactPdf } from './global/pdf'

import './ui/styles'

const { NODE_ENV } = process.env

initAnalytics()
initReactPdf()

if (SENTRY && NODE_ENV === 'production') {
  initSentry()
}

// always reset scroll position on page reload
window.history.scrollRestoration = 'manual'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
