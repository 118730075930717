import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import { useEffect } from 'react'

type PaginatedFormProps = {
  children: Array<any>
  activePageIndex: number
  setPageCount: (count: number) => void
  onBack: () => void
}

export const PaginatedForm = ({
  children, activePageIndex, onBack, setPageCount
}: PaginatedFormProps) => {
  // filter non-rendered children
  const activeChild = children.filter((c) => !!c)[activePageIndex]

  useEffect(() => {
    const visiblePages = children.filter((c) => !!c)

    setPageCount(visiblePages.length)
  }, [children])

  return (
    <>
      {activePageIndex !== 0 && (
        <Box sx={{ marginBottom: '.5em' }}>
          <Button sx={{ fontSize: 12 }} startIcon={<ArrowBack fontSize="small" />} onClick={onBack}>
            Previous
          </Button>
        </Box>
      )}
      {activeChild}
    </>
  )
}
