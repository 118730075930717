import { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  useVendorAccounts,
  useCreateVendorAccount,
  VendorSlug,
  Status,
  useStartVendorJob,
  isErrored,
} from '@rcrdclub/common-front'
import { makeStyles } from '@mui/styles'

import { CashflowApplication } from '../../domain/types'
import { selectVendorSpecificAccount, selectVendorSpecificJobSuccessful, selectVendorSpecificJobs } from '../../global/selectors'
import { formatDspProgress } from '../../domain/vendors/formatters'
import { ButtonRounded } from '../components/buttons/ButtonRounded'
import { Assets } from '../../assets'

const useStyles = makeStyles((theme) => ({
  info: {
    margin: 'auto'
  },
  requestInstructions: {
    flex: 0,
    aspectRatio: 'auto 232 / 204',
    // desktop (landscape)
    [theme.breakpoints.up('sm')]: {
      height: '25vh',
    },
    // mobile (portrait)
    [theme.breakpoints.down('sm')]: {
      width: '35vw'
    }
  },
  confirmInstructions: {
    flex: 0,
    cursor: 'pointer',
    aspectRatio: 'auto 192 / 194',
    // desktop (landscape)
    [theme.breakpoints.up('sm')]: {
      height: '25vh',
    },
    // mobile (portrait)
    [theme.breakpoints.down('sm')]: {
      width: '35vw'
    }
  }
}))

export const DspStep = (
  { application, requestedState, unlinkable = false }:
    { application: CashflowApplication, requestedState: any, unlinkable: boolean }
) => {
  const classes = useStyles()
  const [startVendorJob] = useStartVendorJob()
  const { data: vendorAccountDetails, loading: loadingVendorAccounts } = useVendorAccounts({})
  const [{ mutation: createVendorAccount, loading: loadingCreateAccount }] = useCreateVendorAccount({ autoRun: false })

  const [linking, setLinking] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const [requested, setRequested] = requestedState

  const loading = loadingCreateAccount || loadingVendorAccounts

  const latestJob = selectVendorSpecificJobs(VendorSlug.Spotify, vendorAccountDetails)
  const latestJobSuccessful = selectVendorSpecificJobSuccessful(VendorSlug.Spotify, vendorAccountDetails)
  const vendorAccount = selectVendorSpecificAccount(VendorSlug.Spotify, vendorAccountDetails)
  const { step, status } = latestJob

  const latestJobProgress = useMemo(() => formatDspProgress(latestJob), [step, status, latestJob])
  const isRequestSuccessful = latestJob.id === latestJobSuccessful.id && latestJobSuccessful.targets

  const onOpenSpotifyForArtists = () => {
    const windowNew = window.open(`https://artists.spotify.com/c/team/artist/${application.spotifyArtistId}`, '_blank')
    windowNew?.focus()
  }

  useEffect(() => {
    if (latestJobSuccessful?.targets) {
      setRequested(true)
    }
  }, [latestJobSuccessful])

  const onRequestSpotify = async () => {
    try {
      setError(undefined)
      setLinking(true)

      let vendorAccountId = vendorAccount?.id

      if (!vendorAccount?.id) {
        // creates the Vendor Account for Spotify - this will attempt a scrape
        // to confirm if we actually have access or not
        const { createVendorAccount: vendorAccount } = await createVendorAccount(
          VendorSlug.Spotify,
          application.spotifyArtistId,
          '',
        )

        if (!vendorAccount) {
          throw Error('Failed to create vendor account')
        }

        vendorAccountId = vendorAccount.id
      }

      await startVendorJob({
        variables: {
          vendorAccountId,
          options: {
            connect: true
          }
        }
      })
    } catch (error: any) {
      setError(error.toString())
    }

    setLinking(false)
  }

  const onConfirmSpotify = async () => {
    try {
      setError(undefined)
      setLinking(true)

      const { data: vendorJobdata } = await startVendorJob({
        variables: {
          vendorAccountId: vendorAccount.id,
        }
      })

      const { startVendorJob: vendorJobResult } = vendorJobdata ?? {}

      if (vendorJobResult?.status !== Status.success) {
        throw Error('Failed to start syncing process, please try again')
      }

    } catch (error: any) {
      setError(error.toString())
    }

    setLinking(false)
  }

  const renderActionText = () => {
    if (!requested) {
      if (linking) {
        return 'Requesting'
      }

      if (isErrored(latestJob)) {
        return 'Retry Request'
      }

      return 'Initiate Request'
    }

    if (isErrored(latestJob)) {
      return 'Retry Confirm'
    }

    if (isRequestSuccessful) {
      return 'Confirm Access'
    }

    if (latestJobProgress.length) {
      return latestJobProgress
    }

    return 'Confirm Access'
  }

  const isActionable = isErrored(latestJob) || isRequestSuccessful
  const isDisabled = linking || ((loading || !!latestJobProgress.length) && !isActionable)

  return (
    <div>
      {!unlinkable && <>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          justifyContent='center'
          alignItems="center">
          <div>
            {!requested ?
              <img
                className={classes.requestInstructions}
                src={Assets.initialRequest}
                alt="card" /> :
              <img
                onClick={onOpenSpotifyForArtists}
                className={classes.confirmInstructions}
                src={Assets.confirmRequest}
                alt="card" />
            }
          </div>
          <Typography
            variant="body2"
            align="center"
            color="primary"
            className={classes.info}
            style={{
              marginTop: 4,
              fontSize: 12,
              opacity: requested ? undefined : 0
            }}>
            {'Go to Spotify for Artists > Your Teams and click '}
            <span style={{ fontWeight: 600 }}>
              {'Approve.'}
            </span>
          </Typography>
        </Grid>
        <ButtonRounded
          style={{ marginTop: 16 }}
          disabled={isDisabled}
          text={renderActionText()}
          onClick={() => !requested ? onRequestSpotify() : onConfirmSpotify()} />
      </>}

      {(error) &&
        <Typography variant="body2" align="center" color="secondary" className={classes.info}>
          {error}
        </Typography>
      }
    </div>
  )
}
