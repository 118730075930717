import Typography from '@mui/material/Typography'

import { splitMatch } from '../../../global/string'

type TextPartProps = {
  text: string
  fontWeight: number
}

const TextPart = ({ text, fontWeight }: TextPartProps) =>
  <Typography variant="h6" component="span" sx={{ whiteSpace: 'prewrap', fontWeight }}>
    {text}
  </Typography>

type MatchedTextProps = {
  text: string
  search: string
}

export const MatchedText = ({ text, search }: MatchedTextProps) => {
  const [before, match, after] = splitMatch(text, search)

  return (
    <>
      <TextPart text={before} fontWeight={400} />
      <TextPart text={match} fontWeight={600} />
      <TextPart text={after} fontWeight={400} />
    </>
  )
}
