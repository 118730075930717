import Warning from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { Controller } from 'react-hook-form'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useEffect } from 'react'

import { IdentityStepBusiness, BusinessFormPageProps } from './types'

import { AccordionBubble } from '../../components/forms/AccordionBubble'

export const OwnersDeclarationFormPage = ({ steps, form, setPageFields }: BusinessFormPageProps) => {
  const { control, formState: { errors } } = form

  useEffect(() => {
    setPageFields(['confirmedOwners'])
  }, [])

  return (
    <>
      <AccordionBubble
        stepIndex={steps.indexOf(IdentityStepBusiness.OwnersDeclaration)}
        stepCount={steps.length}
        expanded
        collapsible={false}
        error={!!errors.confirmedOwners}
        question="Owners Declaration"
        summary="Owners Declaration"
        hide={false}
        ErrorIcon={Warning}
        onChange={() => undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="confirmedOwners"
                  rules={{ validate: (value) => value }}
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={field.value} />} />
              }
              label="By providing the information above, I hereby certify, to the best of my knowledge,
              that the information provided above is complete and correct." />
          </Grid>
        </Grid>
      </AccordionBubble>
    </>
  )
}
