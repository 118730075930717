
/* eslint-disable import/order */
import Modal from '@mui/material/Modal'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'
import CloseIcon from '@mui/icons-material/Close'

// eslint-disable-next-line import/namespace
import { useAuth, useLogout } from '@rcrdclub/common-front'

import packageJson from '../../../../package.json'
import { colors } from '../../../global/colors'
import { Routes } from '../../routes'
import { useHref } from 'react-router'
import { CONTACT_EMAIL } from '../../../global/constants'

type HelpModalProps = {
  open: boolean
  step: 'application' | 'kyc'
  spotifyArtistId?: string
  onClose: () => void
}

export const HelpModalApplication = ({
  open, onClose, step = 'application', spotifyArtistId
}: HelpModalProps) => {
  const [{ userId }] = useAuth()
  const logout = useLogout()
  const licensesHref = useHref(Routes.licenses)

  const onOpenSpotifyForArtists = () => {
    const windowNew = window.open(`https://artists.spotify.com/c/team/artist/${spotifyArtistId ? spotifyArtistId : ''}`, '_blank')
    windowNew?.focus()
  }

  const onLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.warn('onLogout', error)
    }
    onClose()
  }

  const onContactUs = (onFinished: any, eventName: string) => {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${eventName}`
    if (onFinished) {
      onFinished()
    }
  }

  const renderLink = (text = 'Link', onClick?: Function) => <Link
    color="secondary"
    underline="hover"
    target="_blank"
    onClick={() => onClick?.()}
    style={{ cursor: 'pointer' }}>
    {' '}{text}
  </Link >

  const generateContentApplication = ({ onClose }: { onClose: Function }) => [
    {
      title: 'How do I start accelerating my royalty payments in real time?',
      body: (
        <>
          <p>
            First, complete the steps on this page. Connect the artist profile on your DSP and your primary
            music distributor. Once connected, you will see a more accurate Cash Flow estimate and can
            submit your application.
          </p>
          <p>
            We review applications on a rolling basis. When accepted, you&apos;ll receive a text with next steps.
          </p>
          <p>
            To activate Cash Flow, you must verify your identity with our banking partner.
          </p>
          <p>
            Applying to Cash Flow is an indication of interest. If your application is accepted, you are not
            obligated to register for the Cash Flow service.
          </p>
        </>
      ),
    },
    {
      title: 'I’m having trouble linking. Can you help?',
      body: (
        <>
          <p>
            You must be an admin of your artist profile on DSPs. Double-check the {renderLink('Team section', () => onOpenSpotifyForArtists())} for our invitation
          </p>
          <p>
            You must own the music you release through the distributor, and the releases must match the DSP data.
          </p>
          <p>
            If you’re still having trouble with linking, {renderLink('Contact Us', () => onContactUs(onClose, 'Application - Vendor linking'))}, we’re here for you.
          </p>
        </>
      ),
    },
    {
      title: 'What are the new dollar amounts I’m seeing?',
      body: (
        <>
          <p>
            Your initial Cash Flow waitlist estimate was based on broad public information. Once you’ve
            connected your distributor and artist profile on DSPs, the estimate is updated based on your
            distributed tracks, actual royalty history, and real-time daily plays.
          </p>
          <p>
            If you use multiple distributors, the estimates are limited to the distributor you link. We will be
            adding Cash Flow support for multiple distributor accounts in the near future.
          </p>
        </>
      ),
    },
    {
      title: 'How does HIFI make money with Cash Flow?',
      body: (
        <>
          <p>
            HIFI collects 5% of the royalties that we accelerate, and we deduct the amount at the time of deposit.
          </p>
        </>
      ),
    },
    {
      title: 'Can you answer more questions?',
      body: (
        <>
          <p>
            Of course. Whether you have questions about the service, technical issues, or want to keep up
            with us, we&apos;re here for you. {renderLink('Learn more', () => onContactUs(onClose, 'Application - Learn More'))}.
          </p>
        </>
      ),
    }]

  const generateContentKyc = ({ onClose }: { onClose: Function }) => [
    {
      title: 'Why do I need to verify my identity?',
      body: (
        <>
          <p>
            You must complete all the application steps to accelerate your Cash Flow payments, including
            the identity check process with our banking partner.
          </p>
          <p>
            This is required to authenticate the artist&apos;s name, birthdate, and address, which helps us
            protect you from any risk, such as fraud and suspicious activity.
          </p>
        </>
      ),
    },
    {
      title: 'What happens after I complete the identity check?',
      body: (
        <>
          <p>
            This page will provide an update as your identity check is processed. When you are approved,
            we will let you know the next steps, which you complete in the HIFI mobile app.
          </p>
          <p>
            We will let you know if you need to provide any additional information or documents.
          </p>
          <p>
            If your application is accepted, you are not obligated to start accelerating your payments with
            Cash Flow before you are ready. Once initiated, you can pause Cash Flow at any time.
          </p>
        </>
      ),
    },
    {
      title: 'Can you answer more questions?',
      body: (
        <>
          <p>
            Of course. Whether you have questions about the service, technical issues or would simply like
            to keep up with us, we&apos;sre here for you. {renderLink('Learn more', () => onContactUs(onClose, 'Identity Check - Learn More'))}
          </p>
        </>
      ),
    }
  ]

  const content = step === 'kyc'
    ? generateContentKyc({ onClose })
    : generateContentApplication({ onClose })

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Grid
        container
        style={{
          height: '100dvh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Grid
          xs={11}
          sm={8}
          lg={6}
          sx={{
            padding: 3,
            backgroundColor: colors.grey200,
            overflowY: 'auto',
            overscrollBehavior: 'none'
          }}>
          <div style={{
            flex: 1,
            width: 'inherit',
            display: 'flex',
            position: 'fixed',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginLeft: -32,
            marginTop: -12
          }}>
            <div
              role="button"
              tabIndex={0}
              onClick={onClose}
              style={{
                cursor: 'pointer',
              }}>
              <CloseIcon style={{ fontSize: 32, pointerEvents: 'none' }} />
            </div>
          </div>
          {content.map((c) => (
            <div key={c.title} style={{ paddingBottom: 25 }}>
              <h2 style={{ margin: 0, fontSize: 32 }}>{c.title}</h2>
              {c.body}
            </div>
          ))}

          <div style={{ marginTop: '0' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{
                paddingTop: 12, color: colors.grey700, fontSize: 14, fontStyle: 'italic'
              }}>
                HIFI is a financial technology company and is not a bank. Banking services provided
                by Piermont Bank; Member FDIC. The HIFI Visa&reg; Debit Card is issued by Piermont Bank
                pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit
                cards are accepted.
              </span>
            </div>

            <div style={{ textAlign: 'center', color: colors.grey500, marginTop: '2rem' }}>
              <span style={{ paddingTop: 12, color: colors.greyLink }}>
                &copy; {new Date().getFullYear()} HIFI. All rights reserved.
              </span>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a id="faq_hifi_terms" href="https://hi.fi/terms" target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Terms of Service
              </a>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a id="faq_hifi_privacy" href="https://hi.fi/privacy" target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Privacy Policy
              </a>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <a href={licensesHref} target="_blank" rel="noreferrer" style={{ paddingTop: 12, color: colors.greyLink }}>
                Open Source Software
              </a>
            </div>

            {userId && <div style={{ textAlign: 'center' }}>
              <div
                id="faq_log_out" style={{
                  paddingTop: 12, color: colors.greyLink, textDecorationLine: 'underline', cursor: 'pointer'
                }} onClick={() => onLogout()}>
                Log out
              </div>
            </div>}

            <div style={{
              textAlign: 'center', color: colors.grey400, marginTop: '1rem',
            }}>
              <span>
                {packageJson.version}
              </span>
            </div>
          </div>

        </Grid>

      </Grid>
    </Modal>
  )
}
