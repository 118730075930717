import {
  useEffect,
  useState,
  useCallback,
  SyntheticEvent,
  useRef
} from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  InputAdornment
} from '@mui/material'
import debounce from '@mui/utils/debounce'
import { SpotifyArtist } from '@rcrdclub/common-front'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

import { InlineAutocomplete } from './InlineAutocomplete'

import { SearchSpotifyArtistsQuery, SearchSpotifyArtistsQueryVariables } from '../../../domain/types'
import { QUERY_SEARCH_SPOTIFY_ARTISTS } from '../../../domain/streaming/queries'
import { useArtist } from '../../../domain/artist/hooks'
import { MatchedText } from '../text/MatchedText'

type SearchProps = {
  label: string
  typography: 'h4' | 'h6'
  autoFocus: boolean
  error?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
}

const styles = {
  button: {
    height: '100%'
  },
  adornment: {
    paddingRight: '.5em'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}

const DEBOUNCE_WAIT_MS = 500
const PAGE_SIZE = 10

const LOADING_ROW = {
  name: 'loader',
  id: 'loader'
} as SpotifyArtist

export const SearchInput = ({
  label,
  typography,
  autoFocus,
  error = false,
  onFocus = () => undefined,
  onBlur = () => undefined
}: SearchProps) => {
  const [query, setQuery] = useState('')
  const [artist, setArtist] = useArtist()
  const [offset, setOffset] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const loader = useRef<any>()

  const [searchArtists, { data, loading, fetchMore }] = useLazyQuery<
    SearchSpotifyArtistsQuery,
    SearchSpotifyArtistsQueryVariables
  >(QUERY_SEARCH_SPOTIFY_ARTISTS)

  const debouncedSearchArtists = useCallback(debounce(searchArtists, DEBOUNCE_WAIT_MS), [])

  const results = query ? data?.spotifySearchArtists as SpotifyArtist[] ?? [] : []

  const artists = [...results]

  if (loadingMore && results.length <= offset + PAGE_SIZE) {
    artists.push(LOADING_ROW)
  }

  useEffect(() => {
    if (!query) return

    setOffset(0)
    debouncedSearchArtists({ variables: { request: { query, offset: 0, limit: PAGE_SIZE } } })
  }, [query])

  const handleChange = (_: SyntheticEvent, a: SpotifyArtist | undefined) => setArtist(a)

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setQuery(value)

    // only run on real user events (not on initialization)
    if (event) {
      setArtist(undefined)
    }
  }

  useEffect(() => {
    if (!loadingMore) return

    const next = offset + PAGE_SIZE
    setOffset(next)

    const loadMore = async () => {
      try {
        await fetchMore({
          variables: {
            request: {
              query,
              offset: next,
              limit: PAGE_SIZE,
            }
          }
        })
      } finally {
        setLoadingMore(false)
      }
    }

    loadMore()
  }, [loadingMore])

  const handleScroll = async (event: SyntheticEvent) => {
    const list = event.currentTarget
    const position = list.scrollTop + list.clientHeight

    // one list height away from the end of scrolling
    const atEndOfList = list.scrollHeight - position <= list.clientHeight

    const loadedPreviousBatch = results.length === offset + PAGE_SIZE

    if (!loadingMore && loadedPreviousBatch && atEndOfList) {
      setLoadingMore(true)
    }
  }

  const renderOption = (props: any, option: SpotifyArtist, { inputValue }: AutocompleteRenderOptionState) => {
    if (option === LOADING_ROW) {
      return (
        <div style={styles.loader}>
          <CircularProgress color="secondary" />
        </div>
      )
    }

    return (
      <ListItemButton selected={option.id === artist?.id} sx={styles.button}>
        <ListItemAvatar>
          <Avatar alt={option.name} src={option.images.length ? option.images[option.images.length - 1].url : ''} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary={<MatchedText text={option.name} search={inputValue} />} />
      </ListItemButton>
    )
  }

  return (
    <InlineAutocomplete<SpotifyArtist, true, false>
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={label}
      typography={typography}
      autoFocus={autoFocus}
      disablePortal
      error={!!error}
      disableClearable
      options={artists}
      value={artist}
      onChange={handleChange}
      inputValue={query}
      onInputChange={handleInputChange}
      resultCount={{ xs: 2, xl: 3 }}
      getOptionLabel={(a: SpotifyArtist) => a.name}
      optionKey={(a: SpotifyArtist) => a.id}
      isOptionEqualToValue={(o: SpotifyArtist, v: SpotifyArtist) => o.id === v.id}
      getOptionDisabled={(o) => o === LOADING_ROW}
      filterOptions={(o) => o}
      forcePopupIcon={false}
      ListboxProps={{ onScroll: handleScroll }}
      sx={{
        '& .MuiAutocomplete-input': {
          marginLeft: `${loader?.current?.getBoundingClientRect().width}px`
        }
      }}
      endAdornment={
        <InputAdornment ref={loader} position="end" sx={styles.adornment}>
          <CircularProgress
            color="secondary"
            size="1em"
            sx={{ visibility: loading ? 'visible' : 'hidden' }} />
        </InputAdornment>
      }
      renderOption={renderOption} />
  )
}
