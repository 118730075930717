
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAuthenticating, isSyncing, VendorJob, isMfaRequired, isCompleted, JobStep, isErrored
} from '@rcrdclub/common-front'

export const formatProgress = (job: VendorJob) => {
  // show no status update if job is not running or known
  if (!job || (!job.status && !job.step)) {
    return ''
  }

  if (isErrored(job)) {
    return 'Retry'
  }

  if (isCompleted(job)) {
    return 'Success!'
  }

  if (isSyncing(job)) {
    if (job.step === JobStep.Scraping) {
      return 'Fetching Data...'
    }

    if (job.step === JobStep.Downloading) {
      return 'Downloading...'
    }

    if (job.step === JobStep.Normalizing) {
      return 'Processing...'
    }

    return 'Syncing...'
  }

  if (isMfaRequired(job) && job.step === JobStep.Waiting) {
    return 'MFA Needed'
  }

  if (job.step === JobStep.Starting) {
    return 'Starting...'
  }

  if (isAuthenticating(job)) {
    if (job.step as any === JobStep.Starting) {
      return 'Starting...'
    }

    return 'Authenticating...'
  }

  return 'Linking...'
}

export const formatDspProgress = (job: VendorJob, connecting = false, checking = false) => {
  if (checking) {
    return 'Checking...'
  }

  // show no status update if job is not running or known
  if (!job || (!job.status && !job.step)) {
    return ''
  }

  if (isErrored(job)) {
    return 'Retry'
  }

  if (isCompleted(job)) {
    return 'Success!'
  }

  if (isSyncing(job)) {
    return connecting ? 'Requesting...' : 'Accepting...'
  }

  if (isAuthenticating(job)) {
    return 'Connecting...'
  }

  return 'Initializing...'
}
