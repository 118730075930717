import { useState, createContext, Dispatch } from 'react'

type ApplicationStore = {
  applying: [boolean, Dispatch<boolean>]
}

export const ApplicationContext = createContext<ApplicationStore>({
  applying: [false, () => undefined],
})

export const ApplicationProvider = ({ children }: any) => {
  const [applying, setApplying] = useState<boolean>(false)

  const store: ApplicationStore = {
    applying: [applying, setApplying]
  }

  return (
    <ApplicationContext.Provider value={store}>
      {children}
    </ApplicationContext.Provider>
  )
}
