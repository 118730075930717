import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import EditIcon from '@mui/icons-material/Edit'
import { Done, Redo, SvgIconComponent } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { SyntheticEvent } from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { CircularProgress } from '@mui/material'

import { colors } from '../../../global/colors'

type AccordionBubbleProps = {
  stepIndex: number
  stepCount: number
  hide: boolean
  question: string | JSX.Element
  expanded: boolean
  collapsible: boolean
  onChange: (event: SyntheticEvent, expanded: boolean) => void
  summary: string
  status?: string
  loading?: boolean
  disabled?: boolean
  submitted?: boolean
  completed?: boolean
  fullHeight?: boolean
  error: boolean
  ErrorIcon?: SvgIconComponent
  children: any
}

export const AccordionBubble = ({
  stepIndex,
  stepCount,
  hide,
  question,
  expanded,
  onChange,
  summary,
  status,
  submitted = false,
  disabled = false,
  loading = false,
  completed = false,
  fullHeight = false,
  error,
  ErrorIcon = Redo,
  children
}: AccordionBubbleProps) => {
  const active = !submitted && !disabled
  const palette = !active ? 'primary' : 'secondary'

  const renderIcon = () => {
    if (error) {
      return <IconButton disableRipple sx={{ padding: '0 2px 0 0' }} onClick={(e) => onChange(e, false)}>
        <ErrorIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />
      </IconButton>
    }

    if (completed) {
      return <IconButton disableRipple sx={{ padding: '0 2px 0 6px' }} onClick={(e) => onChange(e, false)}>
        <Done fontSize="small" color={'success'} />
      </IconButton>
    }

    if (loading) {
      return <IconButton disableRipple sx={{ padding: '0 2px 2px 6px' }} onClick={(e) => onChange(e, false)}>
        <CircularProgress color="secondary" size="0.75em" />
      </IconButton>
    }

    if (active) {
      return <EditIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />
    }

    return
  }
  return (
    <Accordion
      expanded={expanded}
      onChange={!active ? () => undefined : onChange}
      disableGutters
      sx={{
        display: hide ? 'none' : undefined,
        border: 1,
        borderColor: `${palette}.main`,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        marginBottom: '1svh',
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 2,
        '&.MuiAccordion-root': {
          borderRadius: 2,
        },
        '&.MuiAccordion-root:before': {
          height: 0,
        },
        '&.MuiAccordion-root:not(.Mui-expanded)': {
          paddingTop: '2svh',
          paddingBottom: '2svh',
          borderColor: palette === 'primary' ? colors.grey450 : colors.pink200
        },
        '.MuiAccordionDetails-root': {
          paddingTop: '1svh',
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        },
        '&.Mui-expanded': {
          border: 'none',
          paddingTop: 0,
          paddingBottom: 0
        },
        '&:not(.Mui-expanded)': {
          paddingTop: 2,
          paddingBottom: 2,
        },
        '.MuiAccordionDetails-root, &.Mui-expanded, .MuiCollapse-root, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region': {
          display: hide ? 'none' : 'flex',
          flexDirection: 'column',
          flexGrow: fullHeight && expanded ? 1 : 'unset'
        },
      }}>
      <AccordionSummary
        expandIcon={renderIcon()}
        sx={{
          display: expanded ? 'none' : undefined,
          minHeight: '2rem',
          '& .MuiAccordionSummary-content': {
            margin: 0,
            minWidth: 0
          }
        }}>
        <Grid container justifyContent="space-between" flexDirection={'column'} alignItems="flex-start">
          {error && <Typography noWrap color={palette} fontWeight="bold" style={{ fontSize: 12 }}>{summary}</Typography>}
          {!error && <Typography noWrap color={palette} style={{ fontSize: 12 }}>{summary}</Typography>}
          {status && <Typography
            noWrap
            style={{ color: completed ? colors.teal : undefined, fontSize: 12 }}
            variant="body1"
            textTransform={'uppercase'}
            color={palette}>
            {status}
          </Typography>}
        </Grid>
      </AccordionSummary>
      <AccordionDetails >
        <Grid container flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" style={{ padding: '0px 16px' }}>
          <Grid item style={{ paddingBottom: 8 }}>
            <Typography
              variant="caption" color={palette}
              textTransform={'uppercase'}>Step {stepIndex + 1} of {stepCount}</Typography>
          </Grid>
          <Typography variant="body1">{question}</Typography>
        </Grid>
        <div style={{
          marginTop: '1rem', display: 'flex', flexDirection: 'column', flexGrow: fullHeight && expanded ? 1 : 'unset'
        }}>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
