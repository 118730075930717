import Typography from '@mui/material/Typography'
import { useState, useEffect, SyntheticEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import { WaitlistFormData } from './steps/types'
import { steps } from './steps/steps'

import { AccordionStep } from '../../components/forms/AccordionStep'
import { Artist } from '../../components/artist/Artist'
import { useArtist } from '../../../domain/artist/hooks'
import { FullPageLayout } from '../../components/layouts/FullPageLayout'
import { CashflowWaitlistMutation, CashflowWaitlistMutationVariables } from '../../../domain/types'
import { SUBMIT_CASHFLOW_WAITLIST_MUTATION } from '../../../domain/waitlist/queries'
import { trackEvent } from '../../../global/analytics'
import { colors } from '../../../global/colors'

const styles = {
  form: {
    marginBottom: '1rem',
    color: colors.pink500
  },
  info: {
    margin: 'auto'
  },
  thanks: {
    marginBottom: '.5em'
  }
}

export const WaitlistForm = () => {
  const [artist] = useArtist()
  const [expanded, setExpanded] = useState<number | null>(1)
  const [furthest, setFurthest] = useState<number>(1)
  const [reachedEnd, setReachedEnd] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const [submit] = useMutation<
    CashflowWaitlistMutation,
    CashflowWaitlistMutationVariables
  >(SUBMIT_CASHFLOW_WAITLIST_MUTATION)

  const {
    register,
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
    getValues,
    setValue,
    getFieldState
  } = useForm<WaitlistFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      spotifyArtistId: artist?.id
    }
  })

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (!name) return

      // force validation check
      await trigger(name)
      const fieldState = getFieldState(name)

      if (fieldState.error) return

      const completedStepIndex = steps.findIndex((s) => s.name === name)
      const nextStepIndex = completedStepIndex + 1
      const noMoreSteps = furthest === steps.length - 1

      trackEvent('waitlist_step', {
        'event_category': 'cashflow',
        'event_label': name
        // index: completedStepIndex,
        // step: name
      })

      if (nextStepIndex < steps.length && !noMoreSteps) {
        setExpanded(nextStepIndex)
        setFurthest(Math.max(nextStepIndex, furthest))
      } else {
        setExpanded(null)
        setReachedEnd(true)

        const activeInput = document.activeElement as HTMLElement
        activeInput.blur()
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, furthest])

  // close form when tapping out of it
  const handlePageClick = (e: SyntheticEvent) => {
    if (!(e.target instanceof Element) || !reachedEnd) return

    const container = document.getElementById('application-form')
    const isFormElement = container?.contains(e.target) ?? true

    if (!isFormElement) {
      setExpanded(null)
    }
  }

  const saveApplication = async (waitlist: WaitlistFormData) => {
    try {
      setError(false)

      const response = await submit({ variables: { application: waitlist } })

      if (response.errors?.length || response.data?.cashflowWaitlist?.status !== 'success') {
        if (response.data?.cashflowWaitlist?.message?.includes('error: duplicate key value violates unique constraint')) {
          // show success for a better user experience in this specific case
          return setSubmitted(true)
        }
        // server error
        setError(true)
      } else {
        trackEvent('waitlist_submit', {
          'event_category': 'cashflow'
        })
        setSubmitted(true)
      }
    } catch (e) {
      // network error
      setError(true)
    }
  }

  const handleExpanded = (i: number) => (event: any, exp: boolean) => setExpanded(exp ? i : null)

  return (
    <FullPageLayout
      headerSize="small"
      onClick={handlePageClick}
      ctaText={submitted ? 'Submitted' : 'Submit Application'}
      ctaOnClick={handleSubmit(saveApplication)}
      ctaDisabled={!isValid || submitted}
      ctaHidden={submitted}>
      <Artist artist={artist} size={expanded === null ? 'large' : 'small'} />
      <div id="application-form" style={styles.form}>
        {steps.map(({
          name, question, summary, Component
        }, index) => (
          <AccordionStep
            key={name}
            stepIndex={index}
            stepCount={steps.length}
            hide={index > furthest}
            question={question}
            summary={summary(getValues(name), artist)}
            submitted={submitted}
            expanded={expanded === index && !submitted}
            collapsible={reachedEnd}
            error={!!errors[name]}
            onChange={handleExpanded(index)}>
            <Component
              value={getValues(name)}
              register={register}
              setValue={setValue}
              control={control}
              error={errors[name]} />
          </AccordionStep>
        ))}
      </div>
      {expanded === null && isValid && !submitted && !error &&
        <Typography variant="body2" align="center" sx={styles.info}>
          By submitting this application you&apos;ll be added to a waitlist and will receive
          a text message confirmation.
        </Typography>
      }
      {expanded === null && !isValid && !submitted && !error &&
        <Typography variant="body2" align="center" color="secondary" sx={styles.info}>
          There is an issue with your application, please review and try again.
        </Typography>
      }
      {expanded === null && error &&
        <Typography variant="body2" align="center" color="secondary" sx={styles.info}>
          There was an issue submitting your application. Please wait a moment and try again.
        </Typography>
      }
      {submitted &&
        <div style={styles.info}>
          <Typography variant="h4" fontWeight={600} align="center" sx={styles.thanks}>
            It&apos;s official, you&apos;re on the waitlist!
          </Typography>
          <Typography variant="body2" align="center">
            We&apos;re reviewing Cash Flow applications on a rolling basis and will be in touch.
          </Typography>
        </div>
      }
    </FullPageLayout>
  )
}
